<div class="user-wrapper">
  <div>
    <div class="table-title">
      {{terminalManagement ? ('TERMINAL_MANAGEMENT' | translate) : terminalManagement}}
    </div>
  </div>
  <div *ngIf="isLoading || isSendingForm" class="loading-spinner">
    <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg" />
  </div>
  <div *ngIf="!isLoading" class="sephora-container" [class.opaque]="isSendingForm">
    <form class="form-horizontal">

      <div class="form-group">
        <div class="row">
          <label for="terminalId" class="control-label col-md-2" [hidden]=true>{{rowId ? ('ROW_ID' | translate) : rowId}}</label>
          <div class="col-md-6">
            <input type="text" [disabled]=true [hidden]=true id="terminalId" name="terminalId" class="form-control" [(ngModel)]="terminalForm.terminalId">
            <div class="text-error" for="terminalId"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="terminalCode" class="control-label col-md-2">{{terminalIdText ? ('TERMINAL_ID' | translate) : terminalIdText}}</label>
          <div class="col-md-6">
            <input type="text" [disabled]="isViewMode" id="terminalCode" name="terminalCode" class="form-control" [(ngModel)]="terminalForm.terminalCode" (focusout)="clearErrorIfValidRequire($event)">
            <div class="text-error" for="terminalCode"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="model" class="control-label col-md-2">{{deviceModel ? ('DEVICE_MODEL' | translate) : deviceModel}}</label>
          <div class="col-md-6">
            <select id="model" name="model" class="form-control" [disabled]="isViewMode" [(ngModel)]="terminalForm.model" required="required" (focusout)="clearErrorIfValidRequire($event)">
              <option class="default-option" value="">{{defaultSelectOption ? ('SELECT_OPTION' | translate) : defaultSelectOption}}</option>
              <option *ngFor="let model of models" [value]="model.id">{{model.modelName}}</option>
            </select>
            <div class="text-error" for="model"></div>
          </div>
          <!--<div class="col-md-2">
            <button *ngIf="!isModelOnValid && !isViewMode" [disabled] = "!authService.isSystemAdmin" type="button" class="btn btn-danger" (click)="addNewDeviceModel()">Add new</button>
            <button *ngIf="isModelOnValid && !isViewMode" [disabled] = "!authService.isSystemAdmin" type="button" class="btn btn-danger" (click)="updateDeviceModel()">Update</button>
            <button *ngIf="isModelOnValid && !isViewMode" [disabled] = "!authService.isSystemAdmin" type="button" class="btn btn-danger" (click)="deleteDeviceModel()">Delete</button>
          </div>-->
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="countryCode" class="control-label col-md-2">{{location ? ('LOCATION' | translate) : location}}</label>
          <div class="col-md-6">
            <select id="countryCode" name="countryCode" [disabled]="isViewMode || !authService.isSystemAdmin" class="form-control" [(ngModel)]="terminalForm.storeCountry" required="required" (change)="onSelectCountry($event)" (focusout)="clearErrorIfValidRequire($event)">
              <option class="default-option" value="">{{defaultSelectOption ? ('SELECT_OPTION' | translate) : defaultSelectOption}}</option>
              <option *ngFor="let country of countries" [value]="country">{{country}}</option>
            </select>
            <div class="text-error" for="countryCode"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="storeCode" class="control-label col-md-2">{{storeName ? ('STORE_NAME' | translate) : storeName}}</label>
          <div class="col-md-6">
            <select id="storeCode" name="storeCode" [disabled]="!terminalForm.storeCountry" class="form-control" [disabled]="isViewMode" [(ngModel)]="terminalForm.storeCode"  required="required" (focusout)="clearErrorIfValidRequire($event)">
              <option class="default-option" value="">{{defaultSelectOption ? ('SELECT_OPTION' | translate) : defaultSelectOption}}</option>
              <option *ngFor="let store of stores" [value]="store.storeCode">{{store.storeName}}</option>
            </select>
            <div class="text-error" for="storeCode"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="state" class="control-label col-md-2">{{stateText ? ('STATE' | translate) : stateText}}</label>
          <div class="col-md-6">
            <form name="terminalForm">
              <input type="radio" class="col-md-1" [disabled]="isViewMode" id="state" name="state"  (click)="radioTerminalSelection(1)" [checked]=state value="1">{{activeText ? ('ACTIVE' | translate) : activeText}}<br>
              <input type="radio" class="col-md-1" [disabled]="isViewMode" name="state"  (click)="radioTerminalSelection(0)" [checked]=!state value="2">{{inactiveText ? ('INACTIVE' | translate) : inactiveText}}<br>
            </form>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <div class="pull-right">
            <button *ngIf="!isEditMode && !isViewMode" type="submit" class="btn btn-danger" (click)="submitTerminalForm()">{{create ? ('CREATE' | translate) : create}}</button>
            <button *ngIf="isEditMode" type="submit" class="btn btn-danger" (click)="submitTerminalForm()">{{save ? ('SAVE' | translate) : save}}</button>
            <button *ngIf="!isViewMode" type="button" class="btn btn-dark" (click)="cancelForm()">{{cancel ? ('CANCEL' | translate) : cancel}}</button>
            <button *ngIf="isEditMode" type="submit" class="btn btn-dark" (click)="showDeleteTerminalConfirmDialog()">{{delete ? ('DELETE' | translate) : delete}}</button>
            <button *ngIf="isViewMode"type="button" class="btn btn-dark" (click)="cancelForm()">{{close ? ('CLOSE' | translate) : close}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<message-modal></message-modal>
