<div class="sephora-wrapper">
  <router-outlet></router-outlet>
</div>

<simple-notifications></simple-notifications>

<!-- Footer -->
<footer class="footer">
  <div *ngIf="!isWebView" class="container">
    <p class="text-muted text-center">
      <small>© {{currentYear}} {{sephoraRights}}</small>
    </p>
  </div>
</footer>