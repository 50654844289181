export class EcommCountries {
    development = { 
        SG: "https://development-sg.sephora-asia.dev",
        TH: "https://development-th.sephora-asia.dev",
        MY: "https://development-my.sephora-asia.dev",
        HK: "https://development-hk.sephora-asia.dev",
        KR: "https://development-kr.sephora-asia.dev"
    };
    production = {
        SG: "https://www.sephora.sg",
        TH: "https://www.sephora.co.th",
        MY: "https://www.sephora.my",
        HK: "https://www.sephora.hk",
        KR: "https://www.sephora.kr"
    };
}