import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';

import {MessageModalComponent} from 'src/app/components/modal/message-modal/message-modal.component';
import {Order} from 'src/app/models/order';
import {CONSTANTS} from 'src/app/common/constants';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'order-component',
  templateUrl: './order.component.html'
})
export class OrderComponent implements OnInit {
  @ViewChild(MessageModalComponent)
  messageModal!: MessageModalComponent;

  isLoading: boolean = true;

  baId: number = 0;
  orderId : String = '';
  oobaOrders : string =  CONSTANTS.OOBA_ORDERS;
  ba_id : string = CONSTANTS.BA_ID;
  baName : string = CONSTANTS.BA_NAME;
  items : string = CONSTANTS.ITEMS;
  subtotal : string = CONSTANTS.SUBTOTAL;
  priceBeforeGST : string = CONSTANTS.PRICE_BEFORE_GST;
  gstIncluded : string = CONSTANTS.GST_INCLUDED;
  shipping: string = CONSTANTS.SHIPPING;
  total: string = CONSTANTS.TOTAL;
  pointsEarnedText: string = CONSTANTS.POINTS_EARNED;
  estimatedDeliveryDate: string = CONSTANTS.ESTIMATED_DELIVERY_DATE;
  gstRegistrationNo: string = CONSTANTS.GST_REGISTRATION_NO;
  orderNumberText: string = CONSTANTS.ORDER_NUMBER_TEXT;
  dateOfOrder: string = CONSTANTS.DOO;
  paymentMethod: string = CONSTANTS.PAYMENT_METHOD;
  shipAddress: string = CONSTANTS.SHIP_ADDRESS;
  back: string = CONSTANTS.BACK;

  order : Order =  {
    id: 0,
    baId: 0,
    baLoginId: '',
    baName: '',
    items: [],
    orderNo : '',
    totalAmount: '',
    status : '',
    subtotal : '',
    preGst: '',
    gstPercent: '',
    shippingCost : '',
    pointsEarned: '',
    deliveryDate : '',
    gstRegNo : '',
    createdAt: '',
    paymentMethod: '',
    address : ''
 };

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
    ) {
      this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
      // Set default language
      this.translate.setDefaultLang('en-SG');
    }

  ngOnInit() {
    this.getOrder();
 }

  getOrder(){
    this.route.params.subscribe(params => {
      let orderId = params['orderId'];
      // Not edit mode
      if (!orderId) {
        this.isLoading = false;
        return;
     }
      if (orderId) {
        this.orderId = orderId;
        this.updateValues(JSON.parse(localStorage.getItem('oobaOrders') as any).find((it: any) => it.id == orderId));
     }
   });
 }

  updateValues(o: any) {
    // Use setTimeout to get around angular model update bug
    setTimeout(() => {
      // Assign attributes
      this.order = Object.assign({}, o);
      this.isLoading = false;
   }, 0);
 }

  setUpdateTitle() {
    this.title.setTitle('View order detail');
 }

  cancelForm() {
    this.backToOOBAOrders();
 }

  backToOOBAOrders() {
    this.router.navigate(['/management'], {queryParams: {t: 'oobaorder'}});
 }
}
