import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpBackend} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';

@Injectable()
export class CountryService {
  constructor( 
    private http: HttpClient,
    private handler: HttpBackend, 
    private authService: AuthService,
    private utilService: UtilService
    ) {}

  getCountries(): Observable<any> {
    return this.http
      .get(ENDPOINTS.COUNTRY, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  getCountryInfo(countryId: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.COUNTRY + "/" + countryId, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  updateCountry(countryId: number, data: any): Observable<any> {
    return this.http
      .post(ENDPOINTS.COUNTRY + "/" + countryId, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  uploadFileCMToS3Bucket(fileuploadurl: string, file: File): Observable<HttpEvent<{}>> {
    this.http = new HttpClient(this.handler);
    const headers = new HttpHeaders({'Content-Type': file.type});
    const req = new HttpRequest('PUT', fileuploadurl, file, {headers: headers, reportProgress: false})
    return this.http.request(req);
 }

}
