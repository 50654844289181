<div class="recover-password-wrapper" (keydown)="handleKeyDown($event)">
    <div class="recover-panel">
      <div *ngIf="isLoading" class="loader"></div>
      <div class="recover-group">
        <div class="reset-title-text">{{changeWebPassTitle ? ('CHNAGE_WEB_PASSWORD_TITLE'| translate) : changeWebPassTitle}}</div>
        <div style="position: relative" style="margin-top: 50px">
          <label style="color: white">{{employeeId ? ('EMPLOYEE_ID'| translate) : employeeId}}:&nbsp;</label>
          <label style="color: white">{{baLoginId}}</label>
        </div>
        <div style="position: relative" [class.push-bottom]="$('.text-error-pw').is(':visible')">
          <input id="password" class="recover-input" name="password" type="password" maxlength="20" spellcheck="false" [(ngModel)]="password"
            required (focusout)="clearErrorIfValid($event)" (ngModelChange)="ecomValidatePassword()">
          <span class="floating-label">{{newPassword ? ('NEW_PASSWORD'| translate) : newPassword}} *</span>
        </div>
        <div [hidden]="!showMessages_newPassword" style="margin-top:10px">
          <p *ngFor="let message of passwordValidationMessages" [ngClass]="{'errorMessage': message.is_error == true, 'successMessage' : message.is_error == false }">
              <span [hidden]="message.is_error">&#10003;</span>
              <span [hidden]="!message.is_error">&#8226;</span>
              {{message.message}}
          </p>
      </div>
        <div style="position: relative;" [class.push-bottom]="$('.text-error-pw').is(':visible')">
          <input id="password-confirm" class="recover-input" name="password-confirm" type="password" maxlength="20" spellcheck="false"
            required (focusout)="clearErrorIfValid($event)" [(ngModel)]="passwordConfirm">
          <span class="floating-label">{{passwordConfirmation ? ('PASSWORD_CONFIRMATION'| translate) : passwordConfirmation}} *</span>
        </div>
        <div class="error" *ngIf="((password.length > 0) && (passwordConfirm.length > 0) && (password != passwordConfirm))" class="errorMessage messagePadding"><span>{{passwordValidation ? ('PASSWORD_NOT_MATCH'| translate) : passwordValidation}}</span></div>
      </div>
      <div style="text-align:center;">
        <button class="reset-button" type="button" (click)="handleResetPassword()">{{changePassword ? ('CHANGE_PASSWORD'| translate) : changePassword}}</button>
      </div>
    </div>
  </div>
  