<div class="user-wrapper">
  <div>
    <div class="table-title">
      {{userManagement ?  ('USER_MANAGEMENT' | translate) : userManagement}}
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-spinner">
    <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg" />
  </div>
  <div *ngIf="!isLoading" class="sephora-container" [class.opaque]="isLoading">
    <div class="form-horizontal">

      <div class="form-group">
        <div class="row">
          <label for="roleId" class="control-label col-md-2">{{role ? ('ROLE' | translate) : role}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <select [disabled] = "(isViewMode || ((currentUserRoleId == userForm.roleId) && !authService.isSystemAdmin) || authService.isSMT || (authService.isBM && isEditMode))" id="roleId" name="roleId" class="form-control" [(ngModel)]="userForm.roleId" (focusout)="clearErrorIfValidRequire($event.target)"
              required="required" (change)="onSelectRole($event)">
              <option class="default-option" value="">{{defaultSelectOption ? ('SELECT_OPTION' | translate) : defaultSelectOption}}</option>
              <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
            </select>
            <div class="text-error" for="roleId"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="fullName" class="control-label col-md-2">{{fullName ? ('FULL_NAME' | translate) : fullName}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <input type="text" id="fullName" name="fullName" [disabled] = "isViewMode || !userForm.roleId" class="form-control" maxlength="50" [(ngModel)]="userForm.fullName" (focusout)="clearErrorIfValidRequire($event.target)">
            <div class="text-error" for="fullName"></div>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="!areTargetBrandRoles">
        <div class="row">
          <label for="email" class="control-label col-md-2">{{email ? ('OKTA_ID' | translate) : email}}{{(isTargetRetailOpsOrSA && !authService.isOAuth2Authenticate)? ' (E-mail)' : ''}}<sup *ngIf="authService.isOAuth2Authenticate || (!authService.isOAuth2Authenticate && isTargetRetailOpsOrSA)" class="supertext">*</sup></label>
          <div class="col-md-6">
            <input type="text" [disabled]="isViewMode || !userForm.roleId || (authService.userId == userForm.email)" id="email" name="email" class="form-control" maxlength="255" [(ngModel)]="userForm.email" (focusout)="clearErrorIfValidRequire($event.target)">
            <div class="text-error" for="email"></div>
          </div>
        </div>
      </div>


      <div class="form-group">
        <div class="row">
          <label for="baLoginId" class="control-label col-md-2">{{employeeId ? ('EMPLOYEE_ID'| translate) : employeeId}}{{areTargetBrandRoles? ' (E-mail ID)' : ''}}<sup *ngIf="!authService.isOAuth2Authenticate || areTargetBrandRoles" class="supertext">*</sup></label>
          <div class="col-md-6">
            <input [disabled]="(isEditMode && !employeeIdEditable) || isViewMode || !userForm.roleId" type="text" id="baLoginId" name="baLoginId" class="form-control" maxlength="255" [(ngModel)]="userForm.baLoginId" (focusout)="clearErrorIfValidRequire($event.target)">
            <div class="text-error" for="baLoginId"></div>
          </div>
        </div>
      </div>

      <div class="form-group" [hidden]="!authService.isSystemAdmin || !userForm.oldLoginId || isViewMode">
        <div class="row">
          <label for="oldLoginId" class="control-label col-md-2">{{oldEmployeeId ? ('OLD_EMPLOYEE_ID' | translate) : oldEmployeeId}}</label>
          <div class="col-md-6">
            <input type="text" id="oldLoginId" name="oldLoginId" class="form-control" [disabled]="true" maxlength="255" [(ngModel)]="userForm.oldLoginId">
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="(!authService.isOAuth2Authenticate && !isViewMode && !areTargetBrandRoles)">
        <div class="row">
          <label for="baAppPassword" class="control-label col-md-2">{{baAppPassword ? ('BA_APP_PASSWORD' | translate) : baAppPassword}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <input [disabled]="!userForm.hasBaAppPassword || !userForm.roleId" type="password" id="baAppPassword" name="baAppPassword" class="form-control half-alpha" maxlength="20"
            [(ngModel)]="userForm.baAppPassword" (focusout)="clearErrorIfValidRequire($event.target)" autocomplete="baAppPassword" (ngModelChange)="ecomValidatePassword('baAppPassword')">
            <div class="text-error" for="baAppPassword"></div>
            <div [hidden]="!showMessages_baAppPassword" style="margin-top:10px">
              <p *ngFor="let message of appPasswordValidationMessages" [ngClass]="{'errorMessage': message.is_error == true, 'successMessage' : message.is_error == false }">
                <span [hidden]="message.is_error">&#10003;</span>
                <span [hidden]="!message.is_error">&#8226;</span>
                {{message.message}}
              </p>
            </div>
          </div>
          <div class="col-md-2">
            <button *ngIf="isEditMode" type="button" class="btn btn-danger" (click)="changeBaAppPassword()">{{change ? ('CHANGE' | translate) : change}}</button>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="(!authService.isOAuth2Authenticate && !isEditMode && !isViewMode && !areTargetBrandRoles)">
        <div class="row">
          <label for="baAppPasswordConfirm" class="control-label col-md-2">{{baAppPasswordConfirmText ? ('BA_APP_PASSWORD_CONFIRM' | translate) : baAppPasswordConfirmText }}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <input [disabled]="!userForm.hasBaAppPassword || !userForm.roleId" type="password" id="baAppPasswordConfirm" name="baAppPasswordConfirm" class="form-control"
              maxlength="20" [(ngModel)]="baAppPasswordConfirm" (focusout)="clearErrorIfValidRequire($event.target)" autocomplete="baAppPasswordConfirm">
              <div class="error" *ngIf="(baAppPasswordConfirm != userForm.baAppPassword && userForm.baAppPassword.length >0) && baAppPasswordConfirm.length >0" class="errorMessage messagePadding"><span>Password does not match.</span></div>
              <div class="text-error" for="baAppPasswordConfirm"></div>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="(!authService.isOAuth2Authenticate && !isViewMode && !areTargetBrandRoles && ((authService.userId === userForm.baLoginId) || !authService.isSMT) && !isTargetBA)">
        <div class="row">
          <label for="webPortalPassword" class="control-label col-md-2">{{webPortalPassword ? ('WEB_PORTAL_PASSWORD' | translate) : webPortalPassword}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <input [disabled]="isEditMode && !isTargetBA && oldRole!==BA_ROLE || !userForm.roleId" type="password" id="webPortalPassword" name="webPortalPassword" class="form-control"
            maxlength="20" (focusout)="clearErrorIfValidRequire($event.target)" autocomplete="webPortalPassword" [(ngModel)]="userForm.webPortalPassword" (ngModelChange)="ecomValidatePassword('webPortalPassword')">
            <div class="text-error" for="webPortalPassword"></div>
            <div [hidden]="!showMessages_webPortalPassword" style="margin-top:10px">
              <p *ngFor="let message of webPasswordValidationMessages" [ngClass]="{'errorMessage': message.is_error == true, 'successMessage' : message.is_error == false }">
                <span [hidden]="message.is_error">&#10003;</span>
                <span [hidden]="!message.is_error">&#8226;</span>
                {{message.message}}
              </p>
            </div>
          </div>
          <div class="col-md-2">
            <button *ngIf="isEditMode && oldRole!==BA_ROLE" type="button" class="btn btn-danger" (click)="changeWebPortalPassword()">{{change ? ('CHANGE' | translate) : change}}</button>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="(!authService.isOAuth2Authenticate && !isViewMode && !areTargetBrandRoles && !authService.isSMT && ((!isEditMode && !isTargetBA) || (isEditMode && oldRole === BA_ROLE)))">
        <div class="row">
          <label for="webPortalPasswordConfirm" class="control-label col-md-2">{{webPortalPasswordConfirmText  ? ('WEB_PORTAL_PASSWORD_CONFIRM' | translate) : webPortalPasswordConfirmText}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <input [disabled]="!userForm.hasWebPortalPassword || isTargetBA || !userForm.roleId" type="password" id="webPortalPasswordConfirm" name="webPortalPasswordConfirm" class="form-control"
              maxlength="20" [(ngModel)]="webPortalPasswordConfirm" (focusout)="clearErrorIfValidRequire($event.target)" autocomplete="webPortalPasswordConfirm">
              <div class="error" *ngIf="((webPortalPasswordConfirm != userForm.webPortalPassword) && (userForm.webPortalPassword.length > 0) && (webPortalPasswordConfirm.length > 0))" class="errorMessage messagePadding"><span>{{passwordNotMatch ? ('PASSWORD_NOT_MATCH' | translate) : passwordNotMatch}}</span></div>
              <div class="text-error" for="webPortalPasswordConfirm"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
          <div class="row">
            <label class="control-label col-md-2">{{locationText ? ('LOCATION' | translate) : locationText}}<sup class="supertext">*</sup></label>
            <div class="col-md-6">
              <input *ngIf="authService.isSM || authService.isSMT || authService.isBM || authService.isBR" disabled="true" type="text" name="storeCountry" class="form-control" maxlength="30" [(ngModel)]="userForm.storeCountry">
              <select *ngIf="authService.isSystemAdmin || authService.isRetailOps" name="location" [disabled]="(isViewMode || !authService.isSystemAdmin || !userForm.roleId)" class="form-control" [(ngModel)]="userForm.location" (focusout)="clearErrorIfValidRequire($event.target)"
                required="required" (change)="onSystemAdminSelectLocation($event)">
                <option class="default-option" value="">{{defaultSelectOption ? ('SELECT_OPTION' | translate) : defaultSelectOption}}</option>
                <option *ngFor="let location of locations" [value]="location">{{location}}</option>
              </select>
              <div class="text-error" for="storeCountry"></div>
              <div class="text-error" for="location"></div>
            </div>
          </div>
        </div>

      <div class="form-group" *ngIf="(authService.isSystemAdmin || authService.isRetailOps || authService.isBM || authService.isBR) && areTargetBrandRoles">
        <div class="row">
          <label class="control-label col-md-2">{{activityGroupLabel ? ('ACTIVITY_GROUP_LABEL' | translate) : activityGroupLabel}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <ng-multiselect-dropdown id="activityGroupMultiSelect" (focusout)="validateSelectedActivityGroups($event)" [disabled]="(isViewMode || !userForm.roleId || !(authService.isSystemAdmin || authService.isRetailOps))"
            [placeholder]="multiselectPlaceHolder"
            [settings]="activityGroupDropdownSettings"
            [data]="activityGroupDropdownList"
            [(ngModel)]="selectedActivityGroups"
            (onSelect)="onActivityGroupSelect($event)"
            (onDeSelect)="onActivityGroupDeSelect($event)"
            (onSelectAll)="onActivityGroupSelectAll($event)"
            >
            </ng-multiselect-dropdown>
            <div class="text-error" for="activityGroupMultiSelect"></div>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="!areTargetBrandRoles" >
        <div class="row">
          <label for="storeCode" class="control-label col-md-2">{{storeName ? ('STORE_NAME' | translate) : storeName}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <select [disabled] = "isViewMode || authService.isSM || authService.isSMT || (authService.isSystemAdmin && !userForm.storeCountry && !userForm.location) || !userForm.roleId" id="storeCode" name="storeCode" class="form-control" [(ngModel)]="userForm.storeCode" (focusout)="clearErrorIfValidRequire($event.target)"
              required="required" (change)="onSelectStore($event)">
              <option class="default-option" value="">{{defaultSelectOption? ('SELECT_OPTION' | translate) : defaultSelectOption}}</option>
              <option *ngFor="let store of stores" [value]="store.storeCode">{{store.storeName}}</option>
            </select>
            <div class="text-error" for="storeCode"></div>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="!areTargetBrandRoles">
        <div class="row">
          <label for="storeEmail" class="control-label col-md-2">{{storeEmail ? ('STORE_EMAIL' | translate) : storeEmail}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <input disabled="disabled" type="text" id="storeEmail" name="storeEmail" class="form-control" maxlength="50" [(ngModel)]="userForm.storeEmail">
            <div class="text-error" for="storeEmail"></div>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="isEditMode || isViewMode">
        <div class="row">
          <label class="control-label col-md-2">{{editHistory ? ('EDIT_HISTORY' | translate) : editHistory}}</label>
          <div class="col-md-6">
            <textarea rows="4" readonly type="text" name="editHistory" class="form-control" [(ngModel)]="editHistoriesText"></textarea>
          </div>
        </div>
      </div>
      
      <div class="form-group">
        <div class="col-md-10">
          <div class="pull-right">
            <button *ngIf="!isEditMode && !isViewMode" type="submit" class="btn btn-danger" [disabled]="isLoading || isConflictUser" (click)="submitUserForm()">{{createUserText ? ('CREATE_USER' | translate) : createUserText}}</button>
            <button *ngIf="isEditMode" type="submit" class="btn btn-danger" [disabled]="isLoading || isConflictUser" (click)="submitUserForm()">{{save ? ('SAVE' | translate) : save}}</button>
            <button *ngIf="!isViewMode" type="button" class="btn btn-dark" (click)="cancelForm()">{{cancel ? ('CANCEL' | translate) : cancel}}</button>
            <button *ngIf="isViewMode" type="button" class="btn btn-dark" (click)="cancelForm()">{{close ? ('CLOSE' | translate) : close}}</button>
            <button *ngIf="isEditMode && !authService.isSMT && !authService.isBM && userId != authService.id" type="submit" class="btn btn-dark" (click)="showDeleteUserConfirmDialog()">{{deleteUserText ? ('DELETE_USER' | translate) : deleteUserText}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<message-modal></message-modal>
<change-password-modal></change-password-modal>