import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {forkJoin} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import Bugsnag from '@bugsnag/js';

import {ICONS} from 'src/app/common/icons';
import {StoreService} from 'src/app/services/store.service';
import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {MessageModalComponent} from 'src/app/components/modal/message-modal/message-modal.component';
import {StoreForm} from 'src/app/models/store-form';
import {EditHistory} from 'src/app/models/edit-history';
import {CONSTANTS} from 'src/app/common/constants';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'store-component',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {
  @ViewChild(MessageModalComponent)
  messageModal!: MessageModalComponent;

  // Model
  storeForm: StoreForm = {
    storeName: '',
    storeCode: '',
    storeEmail: '',
    host: '',
    storeCountry: '',
    salesTargetMessage: '',
    salesTarget: 0,
    currency: '',
    averageBasketTarget: 0,
    collectSalesTarget: 0,
    oobaSalesTarget: 0,
    redeemable: 0,
    stockVisible: 0,
    serviceVisible: 0,
    endlessAisle: 0,
    numberOfUsers: 0,
    numberOfTerminals: 0,
    active: 0,
    editHistory: [],
 };
  origStoreForm: StoreForm = {editHistory: [], storeName: '', storeCode: '', storeEmail: '', storeCountry: '', salesTargetMessage: '', host: '', salesTarget: 0, currency: '', averageBasketTarget: 0, collectSalesTarget: 0, oobaSalesTarget: 0, redeemable: 0, stockVisible: 0, serviceVisible: 0, endlessAisle: 0, numberOfUsers: 0, numberOfTerminals: 0, active: 0};
  storeCode: string = '';
  baLoginId: string = '';

  countries: string[] = [];
  editHistoriesText: string = '';
  //
  defaultSelectOption: string = CONSTANTS.SELECT_OPTION;
  isFormValid: boolean = false;
  isEditMode: boolean = false;
  isViewMode: boolean = false;
  isLoading: boolean = true;
  isSendingForm: boolean = false;
  showAllUserAndStoreToggle: boolean = true;
  currentLocation: string;
  countrySelected: string = '';
  storeCountry: string = '';
  isRedeemable: boolean = false;
  isStockVisible: boolean = false;
  isServiceVisible: boolean = false;
  isEndlessAisle: boolean = false;
  numberOfTerminals: number = 0;
  numberOfUsers: number = 0;
  active: boolean = false;
  isSystemAdmin: boolean = false;
  isRetailOps: boolean = false;
  storeManagement: string = CONSTANTS.STORE_MANAGEMENT;
  storeCodeText: string = CONSTANTS.STORE_CODE;
  storeNameText: string = CONSTANTS.STORE_NAME;
  storeEmailText: string = CONSTANTS.STORE_EMAIL;
  host: string = CONSTANTS.HOST;
  location: string = CONSTANTS.LOCATION;
  salesTargetMessage: string = CONSTANTS.SALES_TARGET_MESSAGE;
  salesTarget: string = CONSTANTS.SALES_TARGET;
  limit26: string = CONSTANTS.LIMIT_26;
  charactersLeft: string = CONSTANTS.CHARACTERS_LEFT;
  avergeBasketTarget: string = CONSTANTS.AVERAGE_BASKET_TARGET;
  sephoraCollectSalesTarget: string = CONSTANTS.SEPHORA_COLL_SALES_TARGET;
  oobaSalesTarget: string = CONSTANTS.OOBA_SALES_TARGET;
  redeemable: string = CONSTANTS.REDEEMABLE;
  stockVisible: string = CONSTANTS.STOCK_VISIBLE;
  serviceVisible: string = CONSTANTS.SERVICE_VISIBLE;
  endlessAisleTrackOrder: string = CONSTANTS.ENDLESS_AISLE_TRACK_ORDER;
  state: string = CONSTANTS.STATE;
  editHistory: string = CONSTANTS.EDIT_HISTORY;
  createStoreText: string = CONSTANTS.CREATE_STORE;
  save: string = CONSTANTS.SAVE;
  cancel: string = CONSTANTS.CANCEL
  deleteStoreText: string = CONSTANTS.DELETE_STORE;
  close: string = CONSTANTS.CLOSE;
  enable: string = CONSTANTS.ENABLE;
  disable: string = CONSTANTS.DISABLE;

  maxlength=26;
  characterleft=this.maxlength;

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private notificationsService: NotificationsService,
    protected authService: AuthService,
    private utilService: UtilService,
    public translate: TranslateService
    ) {
    this.translate.addLangs(['en-SG', 'th-TH', 'ms-MY', 'zh-TW', 'ko-KR']);
    // Set default language
    this.translate.setDefaultLang('en-SG');
    this.currentLocation = this.authService.location;
 }

  ngOnInit() {
    this.checkIfEditMode();
    this.countries = this.authService.countries;
    this.isSystemAdmin = this.authService.isSystemAdmin;
    this.isRetailOps = this.authService.isRetailOps;
    if (this.authService.isRetailOps) {
      this.countries = [this.authService.location];
      this.storeForm.storeCountry = this.authService.storeCountry;
   }
 }

  checkIfEditMode() {
    // Get path variables
    this.route.params.subscribe(params => {
      let storeCode = params['storeId'];
      // Not edit mode
      if (!storeCode) {
        this.title.setTitle('New Store');
        this.isLoading = false;
        return;
     }
      // Edit/View mode
      // If there's an ID provided, fill forms with corresponding user data
      if (storeCode) {
        this.storeCode = storeCode;
        if(this.router.url.includes('edit')) {
          this.title.setTitle(CONSTANTS.EDIT_STORE);
          this.isEditMode = true;
       } else if (this.router.url.includes('view')) {
          this.title.setTitle(CONSTANTS.VIEW_STORE);
          this.isViewMode = true;
       }
        this.storeService.getStoreByStoreCode(storeCode).subscribe(
          res => {
            this.isLoading = false;
            this.setUpStoreInfo(res);
            this.setEditHistories(res);
         },
          err => {
            Bugsnag.notify(err);
            this.isLoading = false;
         }
        );
     }
   });
 }

  setUpStoreInfo(info: any) {
    // Use setTimeout to get around angular model update bug
    setTimeout(() => {
      // Assign attributes
      this.storeForm = Object.assign({}, info);
      this.origStoreForm = Object.assign({}, this.storeForm);
      this.numberOfUsers = this.storeForm.numberOfUsers;
      this.numberOfTerminals = this.storeForm.numberOfTerminals;
      this.isRedeemable = this.storeForm.redeemable == 1;
      this.isStockVisible = this.storeForm.stockVisible == 1;
      this.isServiceVisible = this.storeForm.serviceVisible == 1;
      this.isEndlessAisle = this.storeForm.endlessAisle == 1;
      this.active = this.storeForm.active == 1;
      this.characterleft=(this.maxlength)-(this.storeForm.salesTargetMessage.length);
   }, 0);
 }

  count(msg: string){
    if(this.maxlength>msg.length){
      this.characterleft=(this.maxlength)-(msg.length);
   }
    else{
      this._showError('salesTargetMessage', CONSTANTS.ERROR_SALES_TARGET_MESSAGE);
      this.storeForm.salesTargetMessage = msg.substr(0, msg.length - 1);
   }
 }

  /** Set up audit trail content */
  setEditHistories(res: any) {
    this.editHistoriesText = ''
    let editHistories: EditHistory[];
    editHistories = res['editHistory'];
    let size = editHistories.length;
    for (let i = size - 1; i >= 0; i--) {
      let history = editHistories[i];
      let time = this.utilService.formatDateTime(history.updateTimeStamp);
      //let time = history.updateTimeStamp;
      switch (history.type) {
        case 5:
          if (i + 1 === size) {
            this.editHistoriesText += 'Edited on <' + time + '> by ' + history.updateUserFullName + ' (' + history.updateUserBAId + '):\n';
         } else if (editHistories[i].updateTimeStamp !== editHistories[i + 1].updateTimeStamp) {
            this.editHistoriesText += '-------\n';
            this.editHistoriesText += 'Edited on <' + time + '> by ' + history.updateUserFullName + ' (' + history.updateUserBAId + '):\n';
         }

          if (!history.oldValue) {
            history.oldValue = '""';
         }
          if (!history.newValue) {
            history.newValue = '""';
         }
          this.editHistoriesText += (history.fieldName === 'Endless Aisle' ? 'Endless Aisle + Track online orders' : history.fieldName) + ': ' + history.oldValue + ' changed to ' + history.newValue + '.\n';
          break;
        case 6:
            if (i + 1 !== size) {
              this.editHistoriesText += '-------\n';
           }
            this.editHistoriesText += 'Edited on <' + time + '> by ' + history.updateUserFullName + ' (' + history.updateUserBAId + '):\n';
            if (!history.oldValue) {
              history.oldValue = '""';
           }
            if (!history.newValue) {
              history.newValue = '""';
           }
            this.editHistoriesText += (history.fieldName === 'Endless Aisle' ? 'Endless Aisle + Track online orders' : history.fieldName) + ': ' + history.oldValue + ' changed to ' + history.newValue + '.\n';
            break;
        case 8:
          if (i + 1 !== size) {
            this.editHistoriesText += '-------\n';
         }
          this.editHistoriesText += 'Created on ' + time + ' by ' + history.updateUserFullName + ' (' + history.updateUserBAId + ').\n';
          break;
        default:
          break;
     }
   }
 }

  radioSelection(status: number) {
    this.storeForm.redeemable = status;
 }

  radioStockSelection(status: number) {
    this.storeForm.stockVisible = status;
 }
  
  radioServiceSelection(status: number) {
    this.storeForm.serviceVisible = status;
 }

  radioEndlessAisleSelection(status: number) {
    this.storeForm.endlessAisle = status;
 }

  radioIsActiveSelection(status: number) {
    this.storeForm.active = status;
 }

  submitStoreForm() {
    // No error default state, form's valid
    this.isFormValid = true;
    let storeEmailChanged = !this.isEditMode || ((this.origStoreForm.storeEmail ? this.origStoreForm.storeEmail.trim() : this.origStoreForm.storeEmail) != (this.storeForm.storeEmail ? this.storeForm.storeEmail.trim() : this.storeForm.storeEmail));
    let storeNameChanged = !this.isEditMode || (this.origStoreForm.storeName.trim() != this.storeForm.storeName.trim());
    let hostChanged = !this.isEditMode || ((this.origStoreForm.host ? this.origStoreForm.host.trim() : this.origStoreForm.host) != (this.storeForm.host ? this.storeForm.host.trim() : this.storeForm.host));
    this._validateRequire('storeCode', CONSTANTS.ERROR_REQUIRE_TEXT);
    this._validateStoreCode();
    this._validateRequire('storeName', CONSTANTS.ERROR_REQUIRE_TEXT);
    this._validateRequire('storeEmail', CONSTANTS.ERROR_REQUIRE_TEXT);
    this._validateRequire('countryCode', CONSTANTS.ERROR_REQUIRE_TEXT);
    this._validateSalesTargetMessageLimitCharacters('salesTargetMessage', CONSTANTS.ERROR_SALES_TARGET_MESSAGE);
    this._validateNumber('salesTarget', CONSTANTS.ERROR_NUMBER_INVALID);
    this._validateNumber('averageBasketTarget', CONSTANTS.ERROR_NUMBER_INVALID);
    this._validateNumber('collectSalesTarget', CONSTANTS.ERROR_NUMBER_INVALID);
    this._validateNumber('oobaSalesTarget', CONSTANTS.ERROR_NUMBER_INVALID);
    this._validateEmail();
    forkJoin(
      // res[0]
      this.storeService.getStoreByUniqueKey(this.storeForm.storeCode || 'null'),
      // res[1]
      this.storeService.getStoreByUniqueKey(this.storeForm.storeEmail.trim() || 'null'),
      // res[2]
      this.storeService.getStoreByUniqueKey(this.storeForm.storeName.trim() || 'null'),
      // res[3]
      this.storeService.getStoreByUniqueKey(this.storeForm.host ? this.storeForm.host.trim() : 'null')
    ).subscribe(res => {
      if (!this.isEditMode && res[0]) {
        this.isFormValid = false;
        this._showError('storeCode', CONSTANTS.STORE_CODE_EXIST);
     }
      if (storeEmailChanged && res[1]) {
        this.isFormValid = false;
        this._showError('storeEmail', CONSTANTS.STORE_EMAIL_EXIST);
     }
      if (storeNameChanged && res[2]) {
        this.isFormValid = false;
        this._showError('storeName', CONSTANTS.STORE_NAME_EXIST);
     }
      if (hostChanged && res[3]) {
        this.isFormValid = false;
        this._showError('host', CONSTANTS.STORE_HOST_EXIST);
     }
      if (!this.isFormValid) return;

      if(!this.storeForm.salesTargetMessage) {
        this.storeForm.salesTargetMessage = CONSTANTS.TODAYS_GOAL;
     }
      this.trimAll();
      if (this.isEditMode) {
        this.checkHasChange();
        this.updateStore();
     } else {
        this.saveCreateForm();
        this.createStore();
     }
   });
 }

  private checkHasChange() {
    let histories: EditHistory[] = [];
    if (this.storeForm.storeName !== this.origStoreForm.storeName) {
      this.saveChangeForm(histories, 'Store Name', this.origStoreForm.storeName, this.storeForm.storeName, 5);
   }
    if (this.storeForm.storeEmail !== this.origStoreForm.storeEmail) {
      this.saveChangeForm(histories, 'Store Email', this.origStoreForm.storeEmail, this.storeForm.storeEmail, 5);
   }
    if ((this.origStoreForm.host ? this.origStoreForm.host.trim() : this.origStoreForm.host) != (this.storeForm.host ? this.storeForm.host.trim() : this.storeForm.host)) {
      this.saveChangeForm(histories, 'Host', this.origStoreForm.host, this.storeForm.host, 5);
   }
    if (this.storeForm.storeCountry !== this.origStoreForm.storeCountry) {
      this.saveChangeForm(histories, 'Location', this.origStoreForm.storeCountry, this.storeForm.storeCountry, 5);
   }
    if (this.storeForm.salesTargetMessage !== this.origStoreForm.salesTargetMessage) {
      this.saveChangeForm(histories, 'Sales Target Message', String(this.origStoreForm.salesTargetMessage), String(this.storeForm.salesTargetMessage), 5);
   }
    if (this.storeForm.salesTarget !== this.origStoreForm.salesTarget) {
      this.saveChangeForm(histories, 'Sales Target', String(this.origStoreForm.salesTarget), String(this.storeForm.salesTarget), 5);
   }
    if (this.storeForm.averageBasketTarget !== this.origStoreForm.averageBasketTarget) {
      this.saveChangeForm(histories, 'Average Basket Target', String(this.origStoreForm.averageBasketTarget), String(this.storeForm.averageBasketTarget), 5);
   }
    if (this.storeForm.collectSalesTarget !== this.origStoreForm.collectSalesTarget) {
      this.saveChangeForm(histories, 'Sephora Collection Sales Target', String(this.origStoreForm.collectSalesTarget), String(this.storeForm.collectSalesTarget), 5);
   }
    if (this.storeForm.oobaSalesTarget !== this.origStoreForm.oobaSalesTarget) {
      this.saveChangeForm(histories, 'OOBA Sales Target', String(this.origStoreForm.oobaSalesTarget), String(this.storeForm.oobaSalesTarget), 5);
   }
    if (this.storeForm.redeemable !== this.origStoreForm.redeemable) {
      this.saveChangeForm(histories, 'Redeemable', String(this.origStoreForm.redeemable), String(this.storeForm.redeemable), 5);
   }
    if (this.storeForm.stockVisible !== this.origStoreForm.stockVisible) {
      this.saveChangeForm(histories, 'Stock Visible', String(this.origStoreForm.stockVisible), String(this.storeForm.stockVisible), 5);
   }
    if (this.storeForm.serviceVisible !== this.origStoreForm.serviceVisible) {
      this.saveChangeForm(histories, 'Service Visible', String(this.origStoreForm.serviceVisible), String(this.storeForm.serviceVisible), 5);
   }
    if (this.storeForm.endlessAisle !== this.origStoreForm.endlessAisle) {
      this.saveChangeForm(histories, 'Endless Aisle', String(this.origStoreForm.endlessAisle), String(this.storeForm.endlessAisle), 5);
   }
    if (this.storeForm.active !== this.origStoreForm.active) {
      this.saveChangeForm(histories, 'State', String(this.origStoreForm.active), String(this.storeForm.active), 5);
   }
    this.storeForm.editHistory = histories;
 }

  private saveChangeForm(histories: any, field: string, oldVal: string, newVal: string, type: number) {
    var item: EditHistory = {
      fieldName: field,
      oldValue: oldVal,
      newValue: newVal,
      updateTimeStamp: '',
      updateUserBAId: this.authService.userId,
      updateUserFullName: this.authService.fullName,
      userId: this.storeCode,
      type: type
   };
    histories.push(item);
 }

  private saveCreateForm() {
    let histories: EditHistory[] = [];
    this.saveChangeForm(histories, '', '', '', 8);
    this.storeForm.editHistory = histories;
 }

  createStore() {
    this.isSendingForm = true;
    this.storeService.createStore(this.storeForm).subscribe(
      res => {
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.STORE_CREATE_SUCCESS,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.backToStoreList();
        this.isSendingForm = false;
     },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          CONSTANTS.CREATE_STORE_FAIL,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isSendingForm = false;
        Bugsnag.notify(err);
     }
    );
 }

  private trimAll() {
    this.storeForm.storeName = this.storeForm.storeName.trim();
    this.storeForm.storeEmail = this.storeForm.storeEmail.trim();
    this.storeForm.host = this.storeForm.host ? this.storeForm.host.trim() : this.storeForm.host;
 }

  updateStore() {
    this.isSendingForm = true;
    this.storeService.updateStore(this.storeCode, this.storeForm).subscribe(
      res => {
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.SUCCESS_MSG,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.router.navigate(['/store/view', this.storeCode]);
        this.isSendingForm = false;
     },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          CONSTANTS.UPDATE_STORE_FAIL,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isSendingForm = false;
        Bugsnag.notify(err);
     }
    );
 }

  cancelForm() {
    this.backToStoreList();
 }

  backToStoreList() {
    this.router.navigate(['/management'], {queryParams: {t: 'store'}});
 }

  showDeleteStoreConfirmDialog() {
    this.messageModal.show(
      CONSTANTS.CONFIRM_DELETE_STORE,
      CONSTANTS.DELETE_STORE_CONFIRM,
      ICONS.CONFIRM,
      null,
      'Cancel',
      () => {
        this.messageModal.close();
        this.deleteStore();
     },
      'OK'
    );
 }

  deleteStore() {
    this.isSendingForm = true;
    this.storeService.deleteStore(this.storeForm.storeCode).subscribe(
      res => {
        this.showSuccessPopup(this.storeForm.storeName + CONSTANTS.DELETE_SUCCESS);
        this.isSendingForm = false;
        this.backToStoreList();
     },
      err => {
        this.showErrorPopup(CONSTANTS.DELETE_STORE_FAIL);
        this.isSendingForm = false;
        Bugsnag.notify(err);
     }
    );
 }

  showSuccessPopup(message: string) {
    this.notificationsService.success(
      CONSTANTS.SUCCESS,
      message,
      {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
     }
    );
 }

  showErrorPopup(message: string) {
    this.notificationsService.error(
      CONSTANTS.ERROR,
      message,
      {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
     }
    );
 }



  /** Validate handling */

  _validateStoreCode() {
    if (!this.isEditMode) {
      let content = $("[name='storeCode']").val();
      let regex = /^\d{4}$/;
      if (regex.test(content) == false) {
        this.isFormValid = false;
        this._showError('storeCode', CONSTANTS.STORE_CODE_INVALID);
     }
   }
 }

  // Make sure required fields are filled out
  private _validateRequire(inputName: string, message: string) {
    this._clearError(inputName);
    let content = $("[name='" + inputName + "']").val();
    if (!content || !content.trim()) {
      this.isFormValid = false;
      this._showError(inputName, message);
   }
 }

  private _validateSalesTargetMessageLimitCharacters(inputName: string, message: string) {
    this._clearError(inputName);
    let content = $("[name='" + inputName + "']").val();
    if (content && content.trim().length > 26) {
      this.isFormValid = false;
      this._showError(inputName, message);
   }
 }

  private _validateEmail() {
    let content = this.storeForm.storeEmail.trim();
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(content) == false) {
      this.isFormValid = false;
      this._showError('storeEmail', CONSTANTS.INVALID_STORE_EMAIL);
   }
 }

  _validateNumber(inputName: string, message: string) {
    this._clearError(inputName);
    let content = $("[name='" + inputName + "']").val();
    if (content.trim() && isNaN(content)) {
      this.isFormValid = false;
      this._showError(inputName, message);
   }
 }

  clearErrorIfValidRequire(event: any) {
    let content = (event.target as HTMLInputElement).value
    if (content && content.trim()) {
      this._clearError((event.target as HTMLInputElement).name);
   }
 }

  clearErrorIfSalesTargetMessageTargetMessageLimitCharacters(event: any) {
    let content = (event.target as HTMLInputElement).value
    if (!content || (content && content.trim().length <= 26)) {
      this._clearError((event.target as HTMLInputElement).name);
   }
 }

  clearErrorIfValidNumber(event: any) {
    let content = (event.target as HTMLInputElement).value
    if (!content || (content.trim() && isNaN(Number(content)))) {
      this._clearError((event.target as HTMLInputElement).name);
   }
 }

  // Show error message under input
  private _showError(inputName: string, message: string) {
    let inputElement = $("[name ='" + inputName + "']");
    inputElement.addClass('input-error');
    $("div.text-error[for='" + inputName + "']")
      .html(message)
      .show();
 }

  //Clear error message under input
  private _clearError(inputName: string) {
    let inputElement = $("[name ='" + inputName + "']");
    inputElement.removeClass('input-error');
    $("div.text-error[for='" + inputName + "']").hide();
 }
}
