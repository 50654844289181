export const CONSTANTS: any = {
    WELCOME_BA_PORTAL: 'Welcome to Sephora BA App User Management!',
    SEPHORA_BA_USR_MANAGE: 'SEPHORA BA APP USER MANAGEMENT',
    ACTION: 'Action',TITLE: 'Sephora BA App User Management',
    WELCOME_TITLE: 'Welcome to Sephora BA App User Management!',
    BUGSNAG_API_KEY: '6667ad32cfca896748f5aad28f4b69dc',
    LOGGING_IN: 'Logging in',
    LOGGING_OUT: 'Logging out',
    LOGIN_OKTA: 'Log in with OKTA',
    GENERATE_OTP: 'Generate OTP',
    RESEND_OTP: 'Resend OTP',
    CONFIRM_LBL: 'Confirm', 
    LOGOUT: 'Logout',
    EMPLOYEE_ID: 'Employee ID.',
    EMPLOYEE_ID_EMPTY: 'EMPLOYEE ID is empty.',
    EMPLOYEE_ID_INVALID: 'Invalid EMPLOYEE_ID.',
    ACTIVITY_GROUP_LABEL: 'Activity Group',
    OLD_EMPLOYEE_ID: 'Old Employee ID',
    PASSWORD: 'Password',
    PASSWORD_EMPTY: 'Password is empty.',
    PASSWORD_INVALID: 'Invalid password.',
    BA_APP_PASSWORD: 'BA App Password',
    BA_APP_PASSWORD_CONFIRM: 'BA App Password Confirm',
    SERVER_ERROR: 'Cannot connect to Server.',
    SERVER_ERROR_CONNECT_ADMIN: 'Server error - please contact your administrator.',
    LOGIN: 'Log in',
    FORGOT_PASSWORD: 'Forgot your password?',
    OR: 'or ',
    NOT_A_SEPHORA_USER: 'Not a Sephora user?', 
    LOGIN_HERE: 'Login here', 
    SPEHORA_RIGHTS: 'Sephora Asia Pte Ltd. All rights reserved.',
    USER_MANAGEMENT: 'User Management',
    STORE_MANAGEMENT: 'Store Management',
    RESOURCE_MANAGEMENT: 'Resource Management',
    ACTIVITY_BOOKINGS: 'Activity Bookings',
    RESOURCE_FOR: 'Resource for',
    TERMINAL_MANAGEMENT: 'Terminal Management',
    COUNTRY_MANAGEMENT: 'Country Management',
    CUSTOMER_AUDIT: 'Customer Audit Management',
    OOBA_ORDERS: 'Shop & Ship Orders',
    VIEW: 'View',
    EDIT: 'Edit',
    NO_USER_FOUND: 'No user found.',
    EDIT_CURRENCY: 'Edit currency',
    COUNTRY_ID: 'Country ID',
    COUNTRY_NAME: 'Country Name',
    CURRENCY_NAME: 'Currency Name',
    EXCHANGE_RATE: 'ExchangeRate',
    CURRENCY_NOTE: 'Note that this exchange rate is with respect to SGD.',
    noCurrencyFound: 'No currency found.',
    FULL_NAME: 'Full Name',
    STORE_NAME: 'Store Name',
    COUNTRY_LOCATION: 'Country/Location',
    USER_ROLE: 'User Role',
    SHOW_USR_MSG_1: 'Showing users from ',
    TO: ' to ',
    OF_TOTAL: ' of total ',
    USERS: ' users',
    NEW_USER: 'New User',
    EDIT_USER: 'Edit User',
    VIEW_USER: 'View User',
    NEW_STORE: 'New Store',
    SHOW_STORE_MSG_1: 'Showing stores from ',
    STORES: ' stores',
    STORE_CODE: 'Store Code',
    STORE_EMAIL: 'Store Email',
    EMAIL: 'Email',
    LOCATION: 'Location',
    DEACTIVATE: 'Deactivate',
    ACTIVATE: 'Activate',
    STORE_NOT_FOUND: 'Store not found.',
    NEW_TERMINAL: 'New Terminal',
    NEW_RESOURCE: 'New Resource',
    DOWNLOAD: 'Download',
    EDIT_TERMINAL: 'Edit Terminal',
    EDIT_RESOURCE: 'New Resource',
    VIEW_TERMINAL: 'View Terminal',
    SHOW_TERMINAL_MSG_1: 'Showing terminals from ',
    TERMINALS: ' terminals',
    TERMINAL_ID: 'Terminal ID',
    DEVICE_MODEL: 'Device Model',
    STATE: 'State',
    TERMINAL_NOT_FOUND: 'No Terminal found.',
    SHOW_AUDIT_MSG_1: 'Showing audits from ',
    AUDITS: 'audits',
    AUDIT_ITEM_ID: 'Audit Item ID',
    AUDIT_TYPE: 'Type',
    CUSTOMER_BP_ID: 'Customer BP ID',
    CUSTOMER_NAME: 'Customer Name',
    ORDER_NUMBER: 'Order Number',
    ORDER_ID: 'Order ID',
    BA_ID: 'BA ID',
    STORE_ID: 'Store ID',
    MODIFIED_ON: 'Modified On',
    NO_AUDIT_FOUND: 'No audit found.',
    SHOW_OOBA_MSG_1: 'Showing OOBA orders from',
    RESOURCE_NAME_LABEL: 'Resource Name',
    RESOURCE_LABEL: 'Resource',
    TICKET_NO_LABEL: 'Ticket No.',
    CLIENT_NAME_LABEL: 'Client Name',
    CLIENT_CONTACT_LABEL: 'Client Contact',
    ACTIVITY_TYPE_LABEL: 'Activity Type',
    ACTIVITY_LABEL: 'Activity',
    BA_ATTENDED: 'BA Attended',
    STATUS_LABEL: 'Status',
    EXPERTISE_LABEL: 'Expertise',
    ACTITIVY_NAME_LABEL: 'Activity Name',
    NO_ACTVCE_RESOURCES_LABEL: '!!No resources active for',
    SCHEDULE_LABEL: 'Schedule',
    RECURRING_LABEL: 'Recurring',
    ONE_OFF_LABEL: 'One-off',
    TIME_SLOT_LABEL: 'Time Slot',
    MONDAY_LABEL: 'Monday',
    TUESDAY_LABEL: 'Tuesday',
    WEDNESDAY_LABEL: 'Wednesday',
    THURSDAY_LABEL: 'Thursday',
    FRIDAY_LABEL: 'Friday',
    STATURDAY_LABEL: 'Saturday',
    SUNDAY_LABEL: 'Sunday',
    SHOWING_RESOURCES_FROM: 'Showing resources from',
    SHOWING_BOOKINGS_FROM: 'Showing bookings from',
    BOOKINGS_MSG: 'bookings',
    NO_BOOKING_FOUND: 'No booking found.',
    SELECT_MULTI_OPTIONS: 'Select Option(s)',
    FILTER_BY_ACTIVITY: 'Filter by Activities',
    FILTER_BY_STATUS: 'Filter by Statuses',
    FILTER_BY_ACTIVITY_TYPE: 'Filter by Activity Types',
    FILTER_BY_RESOURCE: 'Filter by Resources',
    BOOKING_FETCH_STORE_FAIL: 'This cannot be changed since the selected store does not have any live Activities or Resouces. Ensure that Activities and Resource are set up and live for this store on Bookings Manager and Schedule Management Service.',
    BOOKING_FILTER_FAIL: 'Failed to load/filter Activities Bookings.',
    REVERT_BTN: 'Revert',
    NO_SHOW_BTN: 'No Show',
    CHECK_IN_BTN: 'Check in',
    COMPLETE_BTN: 'Complete',
    START_BTN: 'Start',
    COMPLETED_BY_TXT: 'Completed by ',
    COMPLETED_TXT: 'Completed ',
    CANCELLED_BY_TXT: 'Cancelled by ',
    CANCELLED_TXT: 'Cancelled ',
    ON_TXT: ' on ',
    AT_TXT: 'at ',
    BOOKING_UPDATE_STATUS_FAIL: 'Update the status failed.',
    ORDERS_TEXT: 'orders',
    RESOURCES_MSG: 'resources',
    BA_NAME: 'BA Name',
    TOTAL_AMOUNT: 'Total Amount',
    STATUS: 'Status',
    NO_OOBA_FOUND: 'No OOBA Order found.',
    NO_RESOURCE_FOUND: 'No resource found.',
    LMS: 'LMS',
    ENTER_VALID_URL: 'Please enter valid URL.',
    OPEN: 'Open',
    REMOVE: 'Remove',
    SOM: 'SOM',
    CHOOSE_FILE: 'Choose File',
    ACTION_BOX: 'Action Box',
    SALES_DASHBOARD: 'Sales Dashboard',
    ENABLE: 'Enable',
    DISABLE: 'Disable',
    SAVE: 'Save',
    BACK: 'Back',
    CANCEL: 'Cancel',
    DELETE: 'Delete',
    CLOSE: 'Close',
    OK: 'OK',
    PUBLISH: 'Publish',
    CREATE: 'Create',
    CHANGE: 'Change',
    SEARCH: 'Search',
    SEARCH_RESOURCE: 'Search by Resource Name',
    SEARCH_CLIENT: 'Search by Client Name',
    EXPERTISE_FILTER: 'Filter by Expertise',
    ACTIVITY_GROUP_FILTER: 'Filter by Activity Group',
    ACTIVITY_NAME_FILTER: 'Filter by Activity Name',
    INPUT_FILTER: 'Input text to filter',
    CUSTOMER_INFO_AUDIT: 'Customer Information Audit Trail',
    BEAUTY_PASS_ID: 'Beauty Pass ID',
    UPC: 'UPC',
    AUDIT_TRIAL: 'Audit Trail',
    DIGITAL_SIGNATURE: 'Digital Signature',
    CHNAGE_WEB_PASSWORD_TITLE: 'Change Web Portal Password',
    WEB_PORTAL_PASSWORD: 'Web Portal Password',
    WEB_PORTAL_PASSWORD_CONFIRM: 'Web Portal Password Confirm',
    NEW_PASSWORD: 'New password',
    PASSWORD_CONFIRMATION: 'Password Confirmation',
    PASSWORD_NOT_MATCH: 'Password does not match.',
    CHANGE_PASSWORD: 'Change Password',
    ENTER_NEW_PASSWORD: 'Please enter your new password.',
    CONFIRM_NEW_PASSWORD: 'Confirm New Password',
    ENTER_PASSWORD_CONFIRMATION: 'Please enter password confirmation',
    UPDATE_PASSWORD: 'Update Password',
    ITEMS: 'Items',             
    SUBTOTAL: 'SUBTOTAL',   
    PRICE_BEFORE_GST: 'Price before GST',
    GST_INCLUDED: 'GST 7.0% (included)', 
    SHIPPING: 'Shipping',    
    TOTAL: 'TOTAL',
    POINTS_EARNED: 'Points Earned',
    ESTIMATED_DELIVERY_DATE: 'ESTIMATED DELIVERY DATE',
    GST_REGISTRATION_NO: 'GST REGISTRATION NO',
    ORDER_NUMBER_TEXT: 'ORDER NUMBER',
    DOO: 'DATE OF ORDER',
    PAYMENT_METHOD: 'PAYMENT METHOD',
    SHIP_ADDRESS: 'SHIP TO ADDRESS',
    EMPLOYEE_ID_PASSWORD_RESET: "Enter your Employee ID. We'll send you a link to reset your password.",
    RECOVER_PASSWORD: 'Recover password',
    RESET_WEB_PASSWORD: 'Reset Web Portal Password',
    INPUT_NEW_PASSWORD: 'Please input your new password',
    HOST: 'Host',
    SALES_TARGET_MSG: 'Sales Target Message',
    LIMIT_26: 'Limit: 26 characters (',
    CHARACTERS_LEFT: '  characters remaining)',
    AVERAGE_BASKET_TARGET: 'Average Basket Target',
    SEPHORA_COLL_SALES_TARGET: 'Sephora Collection Sales Target',
    OOBA_SALES_TARGET: 'OOBA Sales Target',
    REDEEMABLE: 'Redeemable',
    STOCK_VISIBLE: 'Stock Visible',
    SERVICE_VISIBLE: 'Service Visible',
    ENDLESS_AISLE_TRACK_ORDER: 'Endless Aisle + Track online orders',
    EDIT_HISTORY: 'Edit History',
    CREATE_STORE: 'Create store',
    DELETE_STORE: 'Delete store',
    ROW_ID: 'Row ID',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    DATE_LABEL: 'Date',
    DAY_LABEL: 'Day',
    TIME_LABEL: 'Time',
    FROM_LABEL: 'From',
    TO_LABEL: 'To',
    NOT_AVAILABLE_LABEL: 'Not Available',
    AVAILABLE_LABEL: 'Available To Open',
    OPEN_LABEL: 'Open',
    ROLE: 'Role',
    CREATE_USER: 'Create User',
    DELETE_USER: 'Delete User',
    ERROR_REQUIRE_TEXT: 'This field must not be blank.',
    ERROR_REQUIRE_SELECT: 'Please select one item.',
    CONFIRM: 'Confirm Delete User.',
    TERMINAL_CONFIRM:  'Confirm Delete Terminal.',
    RESOURCE_DELETE_CONFIRM:  'Confirm Delete Resource.',
    DELETE_USER_CONFIRM: 'Are you sure you want to permanently delete this user ?',
    RESOURCE_PUBLISH_CONFIRM:  'Confirm Publish Resource.',
    RESOURCE_PUBLISH_CONFIRM_CONTENT: 'Are you sure that you want to publish this resource without any opened time slots ?',
    RESOURCE_PUBLISH_CONFIRM_NOTHING_CHANGES_FOUND: ' No changes found',
    USER_EXIST:'This user is already exists.',
    CONFIRM_DELETE_STORE: 'Confirm Delete Store.',
    DELETE_STORE_CONFIRM: 'Are you sure you want to permanently delete this store ?',
    DELETE_TERMINAL_CONFIRM: 'Are you sure you want to permanently delete this terminal ?',
    RESOURCE_DELETE_CONFIRM_CONTENT: 'Are you sure that you want to permanently delete this resource ?',
    ERROR_REQUIRE_PASSWORD: 'SMT, SM, Retail Ops and System Admin must have their User Management Web Portal password set.',
    ERROR_REQUIRE_SELECT_DROPDOWN: 'Please select an option.',
    ERROR_VALID_URL: 'Please enter valid Url.',
    ERROR_VALID_URL_LENGTH: 'You may enter an URL with maximum length 255 characters.',
    ERROR_FILE_SIZE: 'You may upload files with sizes less than or equal to 50 MB.',
    ERROR_FILE_TYPE: 'You may upload files with only file-type PDF.',
    ERROR_DATE_RANGE: 'You may select a till date that is later than the selected start date.',
    ERROR_DATE_RANGE_MORE_THAN_31: 'You may select a maximum of 31 days from the selected start date.',
    ERROR_DATE_RANGE_LESS_THAN_7: 'You may select a minimum of 7 days from the selected start date for the recurring schedule.',
    ERROR_DATE_RANGE_MORE_THAN_3_MONTHS: 'Maximum of 3 months from the selected start date is allowed.',
    ERROR_TIME_SLOTS_1: 'Please select the Time-Off should not override any other Time-Off.',
    ERROR_TIME_SLOTS_2: 'Make sure the Time off schedule time range should be within the resource schedule time and after Store open time range.',
    ERROR_TIME_SLOTS_3: 'Please select the end time equals or earlier than to the end time of the schedule above.',
    ERROR_TIME_SLOTS_4: 'Please select Add or Remove this time slots before Publish the resource.',
    ERROR_TIME_SLOTS_5: 'Please select valid from and to time in time off.',
    ERROR_TIME_SLOTS_6: 'Selected time off overlaps with an existing selected slot',
    ERROR_NUMBER_INVALID: 'Please input a number value.',
    ERROR_SALES_TARGET_MESSAGE: 'Sales Target Message must be less than or equal to 26 characters.',
    YES: 'YES',
    NO: 'NO',
    VIEW_AUDIT_TRIAL: 'View audit trail',
    NEW_PASSWORD_OLD_PASSWORD_SAME: 'The new password cannot be the same as the current password.',
    CHANGE_PASSWORD_USER: 'Change password for the user: ',
    CHANGE_PASSWORD_FAILED: ' is failed.',
    ERROR: 'Error!',
    ERROR_MSG: 'Error when trying to update the currency to the database table.',
    SUCCESS: 'Success!',
    INFO: 'Info!',
    BA_APP_PASSWORD_NOT_ADDED: 'If BA App Password is not added, the user can only login through OKTA on BA App',
    SUCCESS_MSG: 'All changes saved successfully',
    WARNING: 'Warning!',
    UPLOAD_FILE_1: 'Upload file to ',
    UPLOAD_FILE_2: ' S3 bucket successfully',
    UPLOAD_FILE_ERROR: 'Error when trying to upload file',
    UPLOAD_FILE_ERROR_1: 'to the S3 bucket.',
    PASSWORD_EXPIRY: 'Based on Sephora IT security guidelines, you are required to change your Web Portal password every 90 days. Your password has expired.',
    CLICK_MSG: 'Click \'OK\' to proceed.',
    SEPHORA_WEB_PORTAL: 'Sephora Web Portal',
    PASSWORD_DONOT_MATCH: `Passwords inputted don't match, please input new password again.`,
    INVALID_USER_ID: 'Invalid user ID.',
    INVALID_BEAUTY_ADVISOR: 'User ID is Beauty Advisor.',
    TOKEN_EXPIRED: 'Your token has expired or/and your loginId is invalid. Please request for a different one.',
    PASSWORD_RESET_SUCCESS: 'Your password has been reset successfully. Please login with your new credentials.',
    ERROR_VALIDATING: 'Error in validating password!',
    TOKEN_EXPIRED_MSG: 'Your token is invalid or has expired. Please request for a different one.',
    TOKEN_LOGIN_VALIDATED: 'Your token and loginId are validate successfully. Please enter your new password',
    DELETE_STORE_FAIL: 'Delete the store is failed.',
    DELETE_SUCCESS: ' is deleted successfully.',
    DELETE_FAIL: ' delete failed.', 
    STORE_CREATE_SUCCESS: 'The store was created successfully',
    UPDATE_STORE_FAIL: 'Update the store is failed.',
    CREATE_STORE_FAIL: 'Create new store is failed.',
    TODAYS_GOAL: `Today's Goals`,
    STORE_HOST_EXIST: `There's already a store with this host.`,
    STORE_NAME_EXIST: `There's already a store with this name.`,
    STORE_EMAIL_EXIST: `There's already a store with this email.`,
    VIEW_STORE: 'View Store',
    EDIT_STORE: 'Edit Store',
    TERMINAL_ID_EXISTS: 'A terminal with this terminal Id was already existing.',
    TERMINAL_MODEL_EXISTS: 'The Terminal Model does not exist.',
    STORE_NOT_EXISTS: 'The Store does not exist.',
    TERMINAL_CREATE_SUCCESS: 'The terminal was created successfully',
    RESOURCE_CREATE_SUCCESS: 'The resource was created successfully %s',
    TERMINAL_UPDATE_FAIL: 'Update the terminal is failed.',
    TERMINAL_CREATE_FAIL: 'Create new terminal is failed.',
    RESOURCE_CREATE_FAIL: 'Create new resource is failed.',
    RESOURCE_UPDATE_FAIL: 'Update the resource is failed.',
    RESOURCE_FETCH_STORE_FAIL: 'This cannot be changed since the selected store does not have any live activities or open times. Make sure that Services and Activity Groups or Open Times are set up and live for this store on Bookings Manager.',
    AUS_EMP_ID_INVALID: `Employee Id must be contains only 6 digits [0-9] or started with 'AUS' or 'aus', and follow with 6 digits [0-9]`,
    NZ_EMP_ID_INVALID: `Employee Id must be contains only 6 digits [0-9] or started with 'NZL1' or 'nzl1', and follow with 5 digits [0-9]`,
    TH_EMP_ID_INVALID: `Employee Id must be contains only 6 digits [0-9] or started with 'THA00' or 'tha00', then flow with 1 digit [1-9] and follow with 3 digits [0-9]`,
    MY_EMP_ID_INVALID: `Employee Id must be contains only 6 digits [0-9] or started with 'MYS0' or 'mys', and follow with 5 digits [0-9]`,
    SG_EMP_ID_INVALID: `Employee Id must be contains only 6 digits [0-9] or started with 'SGP' or 'sgp', and follow with 6 digits [0-9]`,
    HK_EMP_ID_INVALID: `Employee Id must be contains only 6 digits [0-9] or started with 'HKG852' or 'hkg852', and follow with 4 digits [0-9]`,
    KR_EMP_ID_INVALID: `Employee Id must be contains only 6 digits [0-9] or started with 'KOR' or 'kor', follow with _ (underscore) and follow with 6 or 7 digits [0-9]`,
    PH_EMP_ID_INVALID: `Employee Id must be contains only 6 digits [0-9] or started with 'PHP' or 'php', and follow with 6 digits [0-9]`,
    ID_EMP_ID_INVALID: `Employee Id must be contains only 6 digits [0-9] or started with 'IND' or 'ind', and follow with 6 digits [0-9]`,
    FIELD_REQUIRED: 'This field must not be blank',
    PASS_NOT_MATCH: `Passwords inputted don't match`,
    USER_CREATED: 'The user was created successfully.',
    USER_CREATE_FAIL: 'Create new user is failed.',
    USER_UPDATE_FAIL: 'Update the user is failed.',
    BA_APP_PASS_CHANGE: 'Confirm BA App Password Change',
    DATE_FORMAT_INVALID: 'Date format is invalid!',
    INPUT_DATE: 'Please input date format.',
    EMAIL_TEXT: 'Email:',
    CHANGED_TO: ' changed to ',
    PHONE_TEXT: 'Phone:',
    COMM_COUNTRY: 'Communication Country: ',
    EMAIL_OPT_IN: 'Email Opt-in: ',
    SMS_OPT_IN: 'SMS Opt-in: ',
    REDEMPTION_MADE_BY: 'Redemption was made by ',
    AT_THE: ' at the ',
    SELECT_COUNTRY: 'Select a Country',
    SELECT_STORE: 'Select a Store',
    SELECT_ACTIVITY_TYPE: 'Select an Activity Type',
    SELECT_OPTION: 'Select an option',
    SELECT_OPTIONS: 'Select option(s)',
    SELECT_ALL: 'Select All',
    UNSELECT_ALL: 'UnSelect All',
    INVALID_STORE_EMAIL: 'Invalid Store Email.',
    INVALID_STORE_CODE: 'Invalid Store Code.',
    DELETE_TERMINAL_FAIL: 'Delete the terminal is failed.',
    PASS_UPDATED_SUCCESS: 's BA App password was updated successfully.',
    PASS_CANNOT_BE_SAME: 'Your new password cannot be the same as your current password.',
    CHANGE_PASS_USER: 'Change password for user: ',
    FAIL_MSG: ' is failed.',
    PORTAL_PASS_CHANGE: 'Confirm Web Portal Password Change',
    PASS_UPDATED_SUCCESS_PORTAL: 's web portal password was updated successfully.',
    NEW_PASS_CANNOT_BE_SAME: 'New password can not be same old password.',
    INVALID_EMAIL: 'Invalid email.',
    USER_DIFFERENT_COUNTRY: 'This user is about to be assigned to a different country. Are you sure?',
    USER_DIFFERENT_COUNTRY_STORE: 'This user is about to be assigned to a different store. Are you sure?',
    DELETE_USER_FAIL: 'Delete the user: ',
    SALES_TARGET: 'Sales Target',
    EMPLOYEE_EMAIL: 'OKTA ID',
    BOOK_BY_BA: 'Book by BA',
    ERROR_REQUIRE_SLOTS: 'Slots are empty. Select slots to proceed',

}