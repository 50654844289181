<nav class="navbar navbar-light navbar-expand-md">
  <div>
    <div class="navbar-brand" (click)="gotoMain()">
      <img class="sephora-logo" src="assets/images/sephora-logo-small.png">
    </div>
    <a *ngIf="showBackButton" [routerLink]="['/management/']" [queryParams]="{t:getParam()}">
      < Back
    </a>
  </div>

  <div class="navbar-brand abs sephora-title">{{sephoraBAUsrManagement ?  ('SEPHORA_BA_USR_MANAGE' | translate) : sephoraBAUsrManagement}}</div>
  <div class="d-flex flex-row justify-content-end non-collapse">
    <ul class="navbar-nav flex-row">
      <li class="nav-item">
        <i class="fa fa-user" aria-hidden="true"></i>
        <div class="nav-link link-cursor-pointer username" (click)="gotoProfile()">{{authService.firstName}}</div>
      </li>
    </ul>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
  <div *ngIf="authService.firstName != 'Guest'" class="navbar-collapse collapse" id="collapsingNavbar">   

    <div class="btn-group" dropdown>
      <button id="button-animated" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-animated">
        <i class="fa fa-globe" aria-hidden="true"></i> {{ siteLanguage }}
        <span class="caret"></span>
      </button>
      <ul
        id="dropdown-animated"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="dropdownMenuButton">
        <ng-container *ngFor="let language of languageList">
          <li role="menuitem">
            <a class="dropdown-item" (click)="changeSiteLanguage(language.code)">
              {{ language.label }}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link link-cursor-pointer" (click)="logout()">{{logoutText ?  ('LOGOUT' | translate) : logoutText}}</a>
      </li>
    </ul>
  </div>
</nav>

<router-outlet></router-outlet>