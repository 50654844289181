import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';

declare var require: any;

@Injectable()
export class UtilService {

  properCaseOf(str: string) {
    return str.trim().toLowerCase().split(' ').map(function(word: string) {
      return word.replace(word[0], word[0].toUpperCase());
   }).join(' ');
 }

  formatDateTime(updateTimeStamp: string){
    var moment = require('moment-timezone');
    var sing = moment.utc(updateTimeStamp).format();
    let localDate = new Date(sing);
    let res = moment(localDate).format('DD-MMM-YYYY, hh:mm A');
    return res;
 }

  formatDate(updateTimeStamp: string){
    var moment = require('moment-timezone');
    var sing = moment.utc(updateTimeStamp).format();
    let localDate = new Date(sing);
    let res = moment(localDate).format('DD-MMMM-YYYY');
    return res;
 }

 formatDateTimeNew(updateTimeStamp: string){
  var moment = require('moment-timezone');
  var sing = moment.utc(updateTimeStamp).format();
  let localDate = new Date(sing);
  let res = moment(localDate).format('DD-MMM-YYYY, hh:mm A');
  return res;
}

  getIso639CodeShort(countryName : string) {
    switch (countryName) {
      case 'Australia':
          return 'au';
      case 'Malaysia':
          return 'my';
      case 'Thailand':
          return 'th';
      case 'New Zealand':
          return 'nz';
      case 'Hong Kong':
          return 'hk';
      case "South Korea":
          return "kr";
      case "Korea":
          return "kr";
      case 'Philippines':
          return 'ph';
      case 'Indonesia':
          return 'id';
      default:
          return 'sg';
   }
 }

  getIso639CodeFromCountryName(countryName : string) {
    switch (countryName) {
      case 'Australia':
          return 'en-AU';
      case 'Malaysia':
          return 'en-MY';
      case 'Thailand':
          return 'en-TH';
      case 'New Zealand':
          return 'en-NZ';
      case 'Hong Kong':
          return 'en-HK';
      case "South Korea":
          return "kr";
      case "Korea":
          return "en-KR";
      case 'Philippines':
          return 'en-PH';
      case 'Indonesia':
          return 'en-ID';
      default:
          return 'en-SG';
   }
 }

  dataSuccess(res: any) {
    return res;
 }

  extractData(res: any) {
    return res.body;
 }

  handleError(err: any) {
    if (err.status === 401) {
        window.location.href = window.location.origin + '/#/login';
    }
    return throwError(() => err);
 }

}