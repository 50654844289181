<!--Change password modal-->
<div class="modal fade" id="changePasswordModal" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body message-body" style="padding: 20px 20px 20px 20px;">
                <div class="message-header"></div>
                <div class="message-content">
                    <form>
                        <div class="form-group">
                            <div class="row">
                                <span class="col-md-4">{{newPasswordText ?  ('NEW_PASSWORD' | translate) : newPasswordText}}</span>
                                <div class="col-md-8">
                                    <input [(ngModel)]="newPassword" id="password" name="password" class="col-md-12" type="password" maxlength="20" spellcheck="false"
                                    autocomplete="new-password" required style="width: 100%" (focusout)="clearErrorIfValid($event)" (ngModelChange)="ecomValidatePassword()">
                                    <div class="text-error" for="password">{{enterNewPassword ?  ('ENTER_NEW_PASSWORD' | translate) : enterNewPassword}}</div>
                                    <div [hidden]="!showMessages_newPassword" style="margin-top:10px">
                                        <p *ngFor="let message of passwordValidationMessages" [ngClass]="{'errorMessage': message.is_error == true, 'successMessage' : message.is_error == false }">
                                            <span [hidden]="message.is_error">&#10003;</span>
                                            <span [hidden]="!message.is_error">&#8226;</span>
                                            {{message.message}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <span class="col-md-4">{{confirmNewPassword  ?  ('CONFIRM_NEW_PASSWORD' | translate) : confirmNewPassword}}</span>
                                <div class="col-md-8">
                                    <input [(ngModel)]="passwordConfirm" id="password-confirm" name="password-confirm" class="col-md-12" type="password" maxlength="20"
                                        spellcheck="false" required style="width: 100%" (focusout)="clearErrorIfValid($event)"
                                        autocomplete="new-password-confirm">
                                    <div class="text-error" for="password-confirm">{{enterPasswordConfirm  ?  ('ENTER_PASSWORD_CONFIRMATION' | translate) : enterPasswordConfirm}}</div>
                                    <div class="error" *ngIf="((newPassword.length > 0) && (passwordConfirm.length > 0) && (newPassword != passwordConfirm))" class="errorMessage messagePadding"><span>{{passwordNotMatch}}</span></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button id="modalOKButton" type="button" class="btn btn-danger">{{ok ?  ('OK' | translate) : ok}}</button>
                <button id="modalCloseButton" type="button" class="btn btn-dark" data-dismiss="modal">{{cancel ?  ('CANCEL' | translate) : cancel}}</button>
            </div>
        </div>
    </div>
</div>