import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {Title} from "@angular/platform-browser";
import {NotificationsService} from 'angular2-notifications';
import Bugsnag from '@bugsnag/js';

import {ExpiredPasswordModal} from 'src/app/components/modal/expired-password-modal/expired-password-modal.component';
import {AuthService} from 'src/app/services/auth.service';
import {CONSTANTS} from 'src/app/common/constants';
import { CODINGCONSTANTS } from 'src/app/common/coding-constants';
import { Languages } from 'src/app/common/languages';
import {
    DefaultLangChangeEvent,
    LangChangeEvent,
    TranslateService,
    TranslationChangeEvent
  } from '@ngx-translate/core';

// Jquery
declare var $: any;

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild(ExpiredPasswordModal)
  expiredModal!: ExpiredPasswordModal;
  username: string = '';
  password: string = '';
  isFormValid: boolean = false;
  isWebView: boolean = false;
  oAuth2MobileSignedIn: boolean = false;
  oAuth2MobileSignedOut: boolean = false;
  isLoading: boolean = false;
  sephoraTitle: string = CONSTANTS.TITLE;
  welcomeTitle: string = CONSTANTS.WELCOME_TITLE;
  loggingIn: string = CONSTANTS.LOGGING_IN + CODINGCONSTANTS.DOTS;
  loggingOut: string = CONSTANTS.LOGGING_OUT + CODINGCONSTANTS.DOTS;
  loginOkta: string = CONSTANTS.LOGIN_OKTA;
  employeeId: string = CONSTANTS.EMPLOYEE_ID;
  employeeIdEmpty: string = CONSTANTS.EMPLOYEE_ID_EMPTY;
  employeeIdInvalid: string = CONSTANTS.EMPLOYEE_ID_INVALID;
  passwordText: string = CONSTANTS.PASSWORD;
  passwordEmpty: string = CONSTANTS.PASSWORD_EMPTY;
  passwordInvalid: string = CONSTANTS.PASSWORD_INVALID;
  serverError: string = CONSTANTS.SERVER_ERROR;
  serverErrorConnectAdmin: string = CONSTANTS.SERVER_ERROR_CONNECT_ADMIN;
  login: string = CONSTANTS.LOGIN;
  forgotPassword: string = CONSTANTS.FORGOT_PASSWORD;
  or: string = CONSTANTS.OR;
  selectedLanguage = "en-SG";
  siteLanguage = 'English';
  localLanguages = new Languages().language;
  languageList = this.localLanguages;
  isOktaLoginOnly = true

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private cookieService: CookieService,
    private translate: TranslateService) {
      this.translate.onLangChange
          .subscribe((event: LangChangeEvent) => {
          localStorage.setItem("selectedLanguage", event.lang);
      });
  
      this.translate.onTranslationChange
          .subscribe((event: TranslationChangeEvent) => {
      });
  
      this.translate.onDefaultLangChange
          .subscribe((event: DefaultLangChangeEvent) => {
      });
    }

  ngOnInit() {
    localStorage.clear();
    this.title.setTitle('Sephora BA App User Management');
    this.checkWebView();
    this.route.queryParams.subscribe(params => {
      this.oAuth2MobileSignedIn = params['oAuth2MobileSignedIn'];
    });
    if (this.oAuth2MobileSignedIn){
      this.oAuth2MobileLogin();
    }
    this.route.queryParams.subscribe(params => {
      this.oAuth2MobileSignedOut = params['oAuth2MobileSignedOut'];
    });
    if (this.oAuth2MobileSignedOut){
      this.oAuth2MobileLogout();
    }
    this.route.queryParams.subscribe(params => {
      this.isOktaLoginOnly = params['oktaError'] ? false : true;
    });
    localStorage.setItem("selectedLanguage", this.selectedLanguage);
 }

  checkWebView() {
    const userAgent = (window as any).navigator.userAgent.toLowerCase(),
    safari = /safari/.test( userAgent ),
    iOS = /iphone|ipod|ipad/.test( userAgent );
    if (iOS && !safari) {
      this.isWebView = true;
    }
    // To simulate mobile mode in Desktop browser
    //this.isWebView = true
 }

  oAuth2MobileLogin() {
    this.isLoading = true;
    this.authService.oAuth2MobileLogin().subscribe(
      res => {
        if (res) {
          (window as any).webkit.messageHandlers.jsMessageHandler.postMessage(res);
        }
        this.isLoading = false;
      },
      err => {
        (window as any).webkit.messageHandlers.jsMessageHandler.postMessage(err);
        if (err.status === 0) {
          this.isLoading = false;
          window.location.href = window.location.origin + "/oauth2/authorization/okta?device=mobile";
        } else {
          this.notificationsService.error(
            "Error!",
            err.status === 500? err.error : err.statusText,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            }
          );
          this.isLoading = false;
          this.router.navigate(["/login"]);
        }
      }
    );
  }

  oAuth2MobileLogout(){
    this.isLoading = true;
    this.authService.logoutOAuth2().subscribe(
      res => {
        let logoutUrl = this.authService.oktaLogoutUrl + '?id_token_hint=' + this.authService.oktaIdToken + '&post_logout_redirect_uri=' + window.location.origin;
        localStorage.clear();
        this.cookieService.delete('remember');
        this.isLoading = false;
        if (this.authService && this.authService.oktaLogoutUrl && this.authService.oktaIdToken && window && window.location) {
          window.location.href = logoutUrl;
        } else {
          this.router.navigate(["/login"]);
        }
      },
      err => {
        this.notificationsService.error(
          "Error!",
          err.status === 500? err.error : err.statusText,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          }
        );
        this.isLoading = false;
        this.router.navigate(["/login"]);
      }
    );
  }
  
  authenticate() {
    this.isFormValid = true;
    this.clearError();

    if(!window.navigator.onLine){
      $('#password ~ .text-error')
            .eq(2)
            .css('opacity', 1);
      return;
   }
    
    this.validateRequired();
    
    // Stop if form is invalid
    if (!this.isFormValid) return;
    this.isLoading = true;
    this.disableLogin();
    this.authService.login(this.username, this.password).subscribe(
      res => {
        // If user is successfully authenticated
        if (res) {
          var expired = this.authService.expiredWebPass;
          if (expired == 1){
            this.expiredModal.show(
              CONSTANTS.PASSWORD_EXPIRY,
              CONSTANTS.CLICK_MSG,
              () => {
                this.expiredModal.close();
                this.router.navigate(['change-pass']);
             }
            );
         }else{
            this.router.navigate([this.authService.redirectUrl]);
         }
       } 
        this.enableLogin();
        this.isLoading = false;
     },
      err => {
        if(err.status == 401){
          if ('Invalid username!' === err.error.message) {
            $('#username ~ .text-error')
              .eq(1)
              .css('opacity', 1);
         } else {
            // Show password error text
            $('#password ~ .text-error')
              .eq(1)
              .css('opacity', 1);
         }
       }else if (err.status == 0){
          $('#password ~ .text-error')
          .eq(2)
          .css('opacity', 1);
       } else {
          $('#password ~ .text-error')
          .eq(3)
          .css('opacity', 1);
       }

        this.enableLogin();
        this.isLoading = false;
        Bugsnag.notify(err);
     }
    );
 }

 oktaAuthenticate() {
  window.location.href = "/oauth2/authorization/okta";
 }

  handleForgotPassword() {
    this.router.navigate(['/recover']);
 }

  clearErrorIfValid(e: any) {
    if (e.target.value) {
      $(e.target)
        .siblings('.text-error')
        .eq(0)
        .css('opacity', 0);
   }
 }

  clearError() {
    $('.text-error').css('opacity', 0);
 }

  disableLogin() {
    $('.login-button').addClass('disabled');
    $('.login-button').attr('disabled', true);
 }

  enableLogin() {
    $('.login-button').removeClass('disabled');
    $('.login-button').attr('disabled', false);
 }

  validateRequired() {
    if (!this.username || !this.username.trim()) {
      $('#username ~ .text-error')
        .eq(0)
        .css('opacity', 1);
      this.isFormValid = false;
   }
    if (!this.password || !this.password.trim()) {
      $('#password ~ .text-error')
        .eq(0)
        .css('opacity', 1);
      this.isFormValid = false;
   }
 }

  handleKeyDown(event: any) {
    // If 'Enter'
    if (event.keyCode == 13 && this.username && this.password) {
      this.authenticate();
   }
 }
 changeLanguage(){
  alert($('#v div li a').attr('id'));
  this.selectedLanguage = $('#selectedLanguage div li a').attr('id');
  localStorage.setItem("selectedLanguage", this.selectedLanguage);
  this.translateLanguageTo(this.selectedLanguage);
  }
  //Switch language
  translateLanguageTo(lang: string) {
      $('#languageOpted').text(lang);
      this.selectedLanguage = lang;
      this.translate.use(lang);
  }

changeSiteLanguage(localeCode: string): void {
  this.selectedLanguage = localeCode;
  localStorage.setItem("selectedLanguage", this.selectedLanguage);
  const selectedLanguage = this.languageList
    .find((language) => language.code === localeCode)
    ?.label.toString();
  if (selectedLanguage) {
    this.siteLanguage = selectedLanguage;
    this.translate.use(localeCode);
  }
}


}
