import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {forkJoin} from 'rxjs';
import {NotificationsService} from 'angular2-notifications';
import Bugsnag from '@bugsnag/js';

import {ICONS} from 'src/app/common/icons';
import {AuthService} from 'src/app/services/auth.service';
import {TerminalService} from 'src/app/services/terminal.service';
import {TerminalModelService} from 'src/app/services/terminal-model.service';
import {StoreService} from 'src/app/services/store.service';
import {MessageModalComponent} from 'src/app/components/modal/message-modal/message-modal.component';
import {Store} from 'src/app/models/store';
import {TerminalForm} from 'src/app/models/terminal-form';
import {TerminalModel} from 'src/app/models/terminal-model';
import { CONSTANTS } from 'src/app/common/constants';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'terminal-component',
  templateUrl: './terminal.component.html'
})
export class TerminalComponent implements OnInit {
  @ViewChild(MessageModalComponent)
  messageModal!: MessageModalComponent;

  // Model
  terminalForm: TerminalForm = {
    terminalId: 0,
    terminalCode: '',
    model: '',
    storeCountry: '',
    storeCode: '',
    state: 0,
 };

  terminalId: number = 0;
  orgTerminalForm: TerminalForm = {terminalId: 0, terminalCode: '', model: '', storeCountry: '', storeCode: '', state: 0};

  models: TerminalModel [] = [];
  stores: Store[]  = [];

  defaultSelectOption: string = CONSTANTS.SELECT_OPTION;
  isFormValid: boolean = false;
  isEditMode: boolean = false;
  isViewMode: boolean = false;
  isModelOnValid: boolean = false;
  isStoreOnValid: boolean = false;
  isLoading: boolean = true;
  isSendingForm: boolean = false;
  state: boolean = false;
  terminalManagement: string = CONSTANTS.TERMINAL_MANAGEMENT;
  rowId: string = CONSTANTS.ROW_ID
  terminalIdText: string = CONSTANTS.TERMINAL_ID;
  deviceModel: string = CONSTANTS.DEVICE_MODEL;
  location: string = CONSTANTS.LOCATION;
  storeName: string = CONSTANTS.STORE_NAME;
  stateText: string = CONSTANTS.STATE;
  activeText: string = CONSTANTS.ACTIVE;
  inactiveText: string = CONSTANTS.INACTIVE;
  create: string = CONSTANTS.CREATE;
  save: string = CONSTANTS.SAVE;
  cancel: string = CONSTANTS.CANCEL;
  delete: string = CONSTANTS.DELETE;
  close: string = CONSTANTS.CLOSE;

  countries: string[] = [];

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    protected authService: AuthService,
    private storeService: StoreService,
    private terminalService: TerminalService,
    private terminalModelService: TerminalModelService,
    public translate: TranslateService
    ) {
    // this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
    // Set default language
    this.translate.setDefaultLang('en-SG');
  }

  ngOnInit() {
    this.checkIfEditMode();
    if (this.authService.isRetailOps || this.authService.isSystemAdmin) {
      this.countries = this.authService.isSystemAdmin ? this.authService.countries : this.countries = [this.authService.location];
      this.terminalForm.storeCountry = this.authService.location;
      this.getMasterData(this.terminalForm.storeCountry);
   }
    this.isLoading = false;
 }

  private checkIfEditMode() {
    // Get path variables
    this.route.params.subscribe(params => {
      let terminalId = params['terminalId'];
      // Is create mode
      if (!terminalId) {
        this.title.setTitle(CONSTANTS.NEW_TERMINAL);
        return;
     }
      // Edit/View mode
      // If there's an ID provided, fill forms with corresponding user data
      if (terminalId) {
        this.terminalId = terminalId;
        if(this.router.url.includes('edit')) {
          this.title.setTitle(CONSTANTS.EDIT_TERMINAL);
          this.isEditMode = true;
       } else if (this.router.url.includes('view')) {
          this.title.setTitle(CONSTANTS.VIEW_TERMINAL);
          this.isViewMode = true;
       }
        this.terminalService.getOneTerminal(terminalId).subscribe(
          res => {
            this.setUpTerminalInfo(res);
         },
          err => {
            Bugsnag.notify(err);
         }
        );
     }
   });
 }

  private setUpTerminalInfo(info: any) {
    // Use setTimeout to get around angular model update bug
    setTimeout(() => {
      if (this.terminalForm.storeCountry !== info.storeCountry) {
        this.storeService.getStoresByCountry(info.storeCountry).subscribe(
        res => {
          this.stores = res;
       },
        err => {
          Bugsnag.notify(err);
       }
      );
     }
      // Assign attributes
      this.terminalForm = Object.assign({}, info);
      this.orgTerminalForm = Object.assign({}, this.terminalForm);
      this.state = this.terminalForm.state == 1;
   }, 0);
 }

  private getMasterData(storeCountry: string) {
    forkJoin(
      // res[0]
      this.terminalModelService.getModels(),
      // res[1]
      this.storeService.getStoresByCountry(storeCountry)
    ).subscribe(
      res => {
        // Load terminal models
        this.models = res[0];

        // Load stores
        this.stores = res[1];
     },
      err => {
        Bugsnag.notify(err);
     }
    );
 }

  addNewDeviceModel() {}

  updateDeviceModel() {}

  deleteDeviceModel() {}

  onSelectCountry(event: any) {
    const value = (event.target as HTMLInputElement).value;
    const name = (event.target as HTMLInputElement).name;
    if (value && value.trim()) {
      this.isLoading = true;
      this.storeService.getStoresByCountry(value).subscribe(
        res => {
          this.stores = res;
       },
        err => {
          Bugsnag.notify(err);
       }
      );
      this.isLoading = false;
   }
 }

  clearErrorIfValidRequire(event: any) {
    const value = (event.target as HTMLInputElement).value;
    const name = (event.target as HTMLInputElement).name;
    if (value && value.trim() && value !== this.defaultSelectOption) {
      this._clearError(name);
   } else {
      this._validateRequire(name, name !== 'terminalCode' ? CONSTANTS.ERROR_REQUIRE_SELECT : CONSTANTS.ERROR_REQUIRE_TEXT);
   }
 }

  radioTerminalSelection(state: number) {
    this.terminalForm.state = state;
 }

  submitTerminalForm() {
    // No error default state, form's valid
    this.isFormValid = true;
    this.validateForm();
    if (!this.isFormValid) return;
    let terminalCodeChanged = !this.isEditMode || (this.orgTerminalForm.terminalCode != this.terminalForm.terminalCode);
    let terminalModelChanged = !this.isEditMode || (this.orgTerminalForm.model != this.terminalForm.model);
    let storeCodeChanged = !this.isEditMode || (this.orgTerminalForm.storeCode.trim() != this.terminalForm.storeCode.trim());
    forkJoin(
      // res[0]
      this.terminalService.getOneTerminalByCode(this.terminalForm.terminalCode.trim() || 'null'),
      // res[1]
      this.terminalModelService.getOneModel(this.terminalForm.model || 'null'),
      // res[2]
      this.storeService.getStoreByStoreCode(this.terminalForm.storeCode || 'null')
    ).subscribe(res => {
      if (terminalCodeChanged && res[0]) {
        this.isFormValid = false;
        this._showError('terminalCode', CONSTANTS.TERMINAL_ID_EXISTS);
     }
      if (terminalModelChanged && !res[1]) {
        this.isFormValid = false;
        this._showError('model', CONSTANTS.TERMINAL_MODEL_EXISTS);
     }
      if (storeCodeChanged && !res[2]) {
        this.isFormValid = false;
        this._showError('storeCode', CONSTANTS.STORE_NOT_EXISTS);
     }
      if (!this.isFormValid) return;

      if (this.isEditMode) {
        this.updateTerminal();
     } else {
        this.createTerminal();
     }
   });
 }

  validateForm() {
    if (!this.terminalForm.terminalCode || !this.terminalForm.terminalCode.trim()) {
      this._validateRequire('terminalCode', CONSTANTS.ERROR_REQUIRE_TEXT);
      this.isFormValid = false;
   }
    if (!this.terminalForm.model || this.terminalForm.model === this.defaultSelectOption) {
      this._validateRequire('model', CONSTANTS.ERROR_REQUIRE_SELECT);
      this.isFormValid = false;
   }
    if (!this.terminalForm.storeCode || this.terminalForm.storeCode === this.defaultSelectOption) {
      this._validateRequire('storeCode', CONSTANTS.ERROR_REQUIRE_SELECT);
      this.isFormValid = false;
   }
 }

  createTerminal() {
    this.isSendingForm = true;
    this.terminalService.createTerminal(this.terminalForm).subscribe(
      res => {
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.TERMINAL_CREATE_SUCCESS,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.backToTerminalList();
        this.isSendingForm = false;
     },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          CONSTANTS.TERMINAL_CREATE_FAIL,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isSendingForm = false;
        Bugsnag.notify(err);
     }
    );
 }

  updateTerminal() {
    this.isSendingForm = true;
    this.terminalService.updateTerminal(this.terminalForm).subscribe(
      res => {
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.SUCCESS_MSG,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.router.navigate(['/terminal/view', this.terminalForm.terminalId]);
        this.isSendingForm = false;
     },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          CONSTANTS.TERMINAL_UPDATE_FAIL,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isSendingForm = false;
        Bugsnag.notify(err);
     }
    );
 }

  cancelForm() {
    this.backToTerminalList();
 }

  backToTerminalList() {
    this.router.navigate(['/management'], {queryParams: {t: 'terminal'}});
 }

  showDeleteTerminalConfirmDialog() {
    this.messageModal.show(
      CONSTANTS.TERMINAL_CONFIRM,
      CONSTANTS.DELETE_TERMINAL_CONFIRM,
      ICONS.CONFIRM,
      null,
      'Cancel',
      () => {
        this.messageModal.close();
        this.deleteTerminal();
     },
      'OK'
    );
 }

  deleteTerminal() {
    this.isSendingForm = true;
    this.terminalService.deleteTerminal(this.terminalForm.terminalId).subscribe(
      res => {
        this.showSuccessPopup(this.terminalForm.terminalCode + CONSTANTS.DELETE_SUCCESS);
        this.isSendingForm = false;
        this.backToTerminalList();
     },
      err => {
        this.showErrorPopup(CONSTANTS.DELETE_TERMINAL_FAIL);
        this.isSendingForm = false;
        Bugsnag.notify(err);
     }
    );
 }

  showSuccessPopup(message: string) {
    this.notificationsService.success(
      CONSTANTS.SUCCESS,
      message,
      {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
     }
    );
 }

  showErrorPopup(message: string) {
    this.notificationsService.error(
      CONSTANTS.ERROR,
      message,
      {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
     }
    );
 }

  // Make sure required fields are filled out
  private _validateRequire(inputName: string, message: string) {
    this._clearError(inputName);
    let content = $("[name='" + inputName + "']").val();
    if (!content || !content.trim()) {
      this.isFormValid = false;
      this._showError(inputName, message);
   }
 }

  // Show error message under input
  private _showError(inputName: string, message: string) {
    let inputElement = $("[name ='" + inputName + "']");
    inputElement.addClass('input-error');
    $("div.text-error[for='" + inputName + "']")
      .html(message)
      .show();
 }

  //Clear error message under input
  private _clearError(inputName: string) {
    let inputElement = $("[name ='" + inputName + "']");
    inputElement.removeClass('input-error');
    $("div.text-error[for='" + inputName + "']").hide();
 }
}
