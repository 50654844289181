import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import Bugsnag from '@bugsnag/js';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.nonLocal) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => Bugsnag.notify(err));
