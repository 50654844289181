import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';

@Injectable()
export class StoreService {
  constructor(private http: HttpClient, private utilService: UtilService, protected authService: AuthService) {}

  filterStores(
    page: number,
    limit: number,
    keywordSearch: string,
    filterStoreCode: string,
    filterStoreName: string,
    filterStoreEmail: string,
    filterLocation: string
  ): Observable<any> {
    let params = {
      offset: (page - 1) * limit,
      limit,
      keywordSearch,
      filterStoreCode,
      filterStoreName,
      filterStoreEmail,
      filterLocation
   };
    localStorage.setItem('pageNum', page + '');
    localStorage.setItem('limitNum', limit + '')
    localStorage.setItem('keywordSearch', keywordSearch);
    localStorage.setItem('filter1', filterStoreCode);
    localStorage.setItem('filter2', filterStoreName);
    localStorage.setItem('filter3', filterStoreEmail);
    localStorage.setItem('filter4', filterLocation);
    return this.http
      .post(ENDPOINTS.STORE_FILTER, params, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  getStores(): Observable<any> {
    return this.http
      .get(ENDPOINTS.STORE, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  getStoreByStoreCode(storeCode: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.STORE_BY + storeCode, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  getStoresByCountry(country: string): Observable<any>{
    return this.http
      .get(ENDPOINTS.STORE_BY_COUNTRY + country, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  getStoreByUniqueKey(key: string): Observable<any> {
    let data = {'key': key};
    return this.http
      .post(ENDPOINTS.STORE_BY + 'unique', data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  createStore(data: any): Observable<any> {
    return this.http
      .post(ENDPOINTS.STORE, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  updateStore(storeCode: string, data: any): Observable<any> {
    return this.http
      .put(ENDPOINTS.STORE_BY + storeCode, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  setStoreStatus(storeCode: string, status: number): Observable<any> {
    return this.http
      .get(ENDPOINTS.STORE_BY + storeCode + '/' + status, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  deleteStore(id: string): Observable<any> {
    return this.http
      .delete(ENDPOINTS.STORE_BY + id, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

}