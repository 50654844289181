import {Component, OnInit} from '@angular/core';
import {UserService} from 'src/app/services/user.service';
import {PasswordValiationMessage} from 'src/app/models/password-validation-message';
import Bugsnag from '@bugsnag/js';
import {CONSTANTS} from 'src/app/common/constants';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
    selector: 'change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.css']
})

export class ChangePasswordModalComponent implements OnInit {
    index: string = '';
    newPassword: string = '';
    passwordConfirm: string = '';
    isFormValid: boolean = false;
    typeChange: number = 0;
    passwordValidationMessages: Array<PasswordValiationMessage> = [];
    showMessages_newPassword = false;
    newPasswordText: string = CONSTANTS.NEW_PASSWORD;
    enterNewPassword: string = CONSTANTS.ENTER_NEW_PASSWORD;
    confirmNewPassword: string = CONSTANTS.CONFIRM_NEW_PASSWORD;
    enterPasswordConfirm: string = CONSTANTS.ENTER_PASSWORD_CONFIRMATION;
    passwordNotMatch: string = CONSTANTS.PASSWORD_NOT_MATCH;
    ok: string = CONSTANTS.OK;
    cancel: string = CONSTANTS.CANCEL;

    constructor(
        private userService: UserService,
        public translate: TranslateService) {
            this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
            // Set default language
            this.translate.setDefaultLang('en-SG');
        }
    ngOnInit(): void {}

    show(header: string, onCloseClick?: any, closeMessage?: string, onOkClick?: any, okMessage?: string, index?: number) {
        this.newPassword = '';
        this.passwordConfirm = '';
        this.clearError();
        this._clearErrorPw('password');
        this._clearErrorPw('password-confirm');

        let changePasswordModal;
        if (index) {
            this.index = index.toString();
       } else {
            this.index = '';
       }
        setTimeout(() => {
            changePasswordModal = $('#changePasswordModal' + this.index);
            let modalCloseButton = changePasswordModal.find('#modalCloseButton');
            let modalOKButton = changePasswordModal.find('#modalOKButton');
            let modalBackDrop = changePasswordModal.find('.modal-backdrop');
            modalBackDrop.css('z-index', 1600);
            modalCloseButton.unbind();
            modalOKButton.unbind();
            // Set header
            changePasswordModal.find('.message-header').html(header);

            if (closeMessage) {
                modalCloseButton.text(closeMessage);
                modalCloseButton.show();
           } else {
                modalCloseButton.hide();
           }
            if (okMessage) {
                modalOKButton.text(okMessage);
                modalOKButton.show();
           } else {
                modalOKButton.hide();
           }
            changePasswordModal.on('hidden.bs.modal', () => {
                modalBackDrop.css('z-index', 1040);
                if (modalBackDrop.length > 0) {
                    $('body').addClass('modal-open');
               }
           })
            if (onCloseClick) modalCloseButton.one('click', null, null, onCloseClick);
            if (onOkClick) modalOKButton.click(onOkClick);
            if (changePasswordModal.hasClass('in')) return;
            else changePasswordModal.modal('show');
       })
   }

    compareConfirmPassword(inputName: string) {
        let content = $(`[name='` + inputName + `']`).val();
        this._clearErrorPw(inputName);
        if (!content || !content.trim()) {
            $('#password-confirm ~ .text-error')
                .eq(0)
                .show();
            this.isFormValid = false;
            return;
       }
        if (this.newPassword !== content) {
            this.isFormValid = false;
            this._showErrorPw(inputName, CONSTANTS.PASSWORD_DONOT_MATCH);
       }
   }

    clearErrorIfValid(e: any) {
        if (e.target.value) {
            $(e.target)
                .siblings('.text-error')
                .hide();
       }
   }

    clearError() {
        $('.text-error').hide();
   }

    disableButtons() {
        $('button').addClass('disabled');
        $('button').attr('disabled', true);
   }

    enableButtons() {
        $('button').removeClass('disabled');
        $('button').attr('disabled', false);
   }

    validateRequired() {
        if (!this.newPassword || !this.newPassword.trim()) {
            $('#password ~ .text-error')
                .eq(0)
                .show();
            this.isFormValid = false;
       }
   }

    checkIsFormValid() {
        for(var iterator = 0; iterator < this.passwordValidationMessages.length; iterator++) {
            let message = this.passwordValidationMessages[iterator];
            if(message.is_error == true) {
                this.isFormValid = false;
                break;
           }
       }
   }

    ecomValidatePassword() {
        if(this.newPassword.length > 0){
            this.clearError();
            this.userService.ecomValidatePassword(this.newPassword, this.passwordConfirm).subscribe(
                res => {
                    this.passwordValidationMessages = [].concat(res.body.data.attributes.password_validations);
               },
                err => {
                    Bugsnag.notify(err);
               }
            );
            this.showMessages_newPassword = true;
       } else {
            this.showMessages_newPassword = false;
       }
   }

    // Show password error message under input
    private _showErrorPw(inputName: string, message: string) {
        let inputElement = $(`[name ='` + inputName + `']`);
        inputElement.focus();
        inputElement.addClass('input-error');
        $(`div.text-error-pw[for='` + inputName + `']`).html(message).show();
   }

    //Clear password error message under input
    private _clearErrorPw(inputName: string) {
        let inputElement = $(`[name ='` + inputName + `']`);
        inputElement.removeClass('input-error');
        $(`div.text-error-pw[for='` + inputName + `']`).hide();
   }

    close() {
        this.passwordValidationMessages = [];
        this.showMessages_newPassword = false;
        $('#changePasswordModal').modal('hide');
   }

    isConfirmSuccessful() {
        this.isFormValid = true;
        this.clearError();
        this.validateRequired();
        this.ecomValidatePassword();
        this.compareConfirmPassword('password-confirm');
        this.checkIsFormValid();
        return this.isFormValid;
   }
}
