import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';

@Injectable()
export class TerminalModelService {
  constructor(private http: HttpClient, private utilService: UtilService, protected authService: AuthService) {}

  getModels(): Observable<any> {
    return this.http
      .get(ENDPOINTS.TERMINAL_MODEL, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  getOneModel(modelId: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.TERMINAL_MODEL_BY + modelId, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  createModel(data: any): Observable<any> {
    return this.http
      .put(ENDPOINTS.TERMINAL_MODEL_BY, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  updateModel(data: any): Observable<any> {
    return this.http
      .post(ENDPOINTS.TERMINAL_MODEL_BY, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

}
