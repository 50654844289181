<!--Modal for showing message-->
<div class="modal fade" id="messageModal{{index}}" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body message-body" style="padding: 20px 20px 20px 20px;">
                <div class="row">
                    <div class="col-md-2">
                        <img class="icon-message" height="40" width="40" />
                    </div>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-xs-9 message-header" style="padding-right: 0px;overflow:hidden;text-overflow: ellipsis;"></div>
                            <div class="col-xs-9 message-content" style="padding-right: 0px;overflow:hidden;text-overflow: ellipsis;"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button id="modalOKButton" type="button" class="btn btn-danger">{{ok ?  ('OK' | translate) : ok}}</button>
                <button id="modalCloseButton" type="button" class="btn btn-dark" data-dismiss="modal">{{cancel ?  ('CANCEL' | translate) : cancel}}</button>
            </div>
        </div>
    </div>
</div>