<div class="title-area" hidden="true">
</div>
<div class="table-area">
  <div class="row">
    <div class="col-sm-2">
      <select [disabled] = "!authService.isSystemAdmin" name="storeCountry" class="form-control" [(ngModel)]="storeCountry" 
      (focusout)="clearErrorIfValidRequire($event.target)" required="required" (change)="onSelectCountry($event)">
        <option class="default-option" value="">{{countryDefaultSelectOption  ? ('SELECT_COUNTRY' | translate) : countryDefaultSelectOption}}</option>
        <option *ngFor="let country of countries" [value]="country">{{country}}</option>
      </select>
      <span class="underline"></span>
      <div class="text-error" for="storeCountry"></div>
    </div>
    <div class="col-sm-2">
      <select name="storeCode" [disabled] = "!(authService.isSystemAdmin || authService.isRetailOps || authService.isBM) || storeCountry===''" class="form-control" [(ngModel)]="storeCode" 
      (focusout)="clearErrorIfValidRequire($event.target)" required="required" (change)="onSelectStore($event)">
        <option class="default-option" value="">{{storeDefaultSelectOption ? ('SELECT_STORE' | translate) : storeDefaultSelectOption}}</option>
        <option *ngFor="let store of stores" [value]="store.storeCode">{{store.storeName}}</option>
      </select>
      <span class="underline"></span>
      <div class="text-error" for="storeCode"></div>
    </div>
    <div class="col-sm-6" style="display:inline-block">
      <div style="position:relative; width:100%">
        <div (click)="getResourceList(1)" class="btn-search">
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>
        <input [disabled] = "!storeCode" style="width:100%" class="form-control pull-right search-bar" type="search" placeholder="{{search ? ('SEARCH_RESOURCE' | translate) : search}}"
          aria-label="Search" [(ngModel)]="keywords" (keyup.enter)="getResourceList(1)" (input)="onSearchInput()">
        <div class="btn-clear" *ngIf="onSearch" (click)="clearSearch()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-sm-2 btn-div">
      <button type="button" class="btn btn-dark btn-new"  [disabled] =  "(this.storeCode == '')" (click) = "beforeGoToAResoucePage()" [routerLink]="['/resource/new', storeCountry, storeCode]">{{newResource ? ('NEW_RESOURCE' | translate) : newResource}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4"></div>
    <div class="col-sm-2">
      <ng-multiselect-dropdown class="form-control-sm" id="expertiseMultiSelect" [disabled]="!storeCode"
      [placeholder]="expertiseMultiselectPlaceHolder"
      [settings]="expertiseDropdownSettings"
      [data]="expertiseDropdownList"
      [(ngModel)]="selectedExpertise"
      (onSelect)="onExpertiseSelect($event)"
      (onDeSelect)="onExpertiseDeSelect($event)"
      (onSelectAll)="onExpertiseSelectAll($event)"
      (onDeSelectAll)="onExpertiseDeselectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-sm-2">
      <ng-multiselect-dropdown class="form-control-sm" id="activityGroupMultiSelect" [disabled]="!storeCode"
      [placeholder]="ActivityGroupMultiselectPlaceHolder"
      [settings]="activityGroupDropdownSettings"
      [data]="activityGroupDropdownList"
      [(ngModel)]="selectedActivityGroups"
      (onSelect)="onActivityGroupSelect($event)"
      (onDeSelect)="onActivityGroupDeSelect($event)"
      (onSelectAll)="onActivityGroupSelectAll($event)"
      (onDeSelectAll)="onActivityGroupDeselectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-sm-2">
      <ng-multiselect-dropdown class="form-control-sm" id="activityNameMultiSelect" [disabled]="!storeCode"
      [placeholder]="ActivityNameMultiselectPlaceHolder"
      [settings]="activityNameDropdownSettings"
      [data]="activityNameDropdownList"
      [(ngModel)]="selectedActivityNames"
      (onSelect)="onActivityNameSelect($event)"
      (onDeSelect)="onActivityNameDeSelect($event)"
      (onSelectAll)="onActivityNameSelectAll($event)"
      (onDeSelectAll)="onActivityNameDeselectAll($event)"
     
      >
      </ng-multiselect-dropdown>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-spinner">
    <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg">
  </div>
  <div class="table-details" *ngIf="storeCode && noActiveResourceActivityNames">{{noActiveResourcesLabel ? ('NO_ACTVCE_RESOURCES_LABEL' | translate) : noActiveResourcesLabel}}&nbsp;<span class="errorMessage">{{noActiveResourceActivityNames}}</span></div>
  <div id="ResourceRes" *ngIf="resources?.length || (!resources?.length && keywords)">
    <div class="table-details" *ngIf="!resources?.length"></div>
    <div class="table-details" *ngIf="resources?.length">{{showingResourcesFrom ? ('SHOWING_RESOURCES_FROM' | translate) : showingResourcesFrom}} {{beginRecord}} {{to ? ('to' | translate) : to}} {{endRecord}} {{ofTotal ? ('OF_TOTAL' | translate) : ofTotal}} {{this.totalRecords}} {{resourcesMsg ? ('RESOURCES_MSG' | translate) : resourcesMsg}}</div>
    <table class="table table-hover table-ba" [class.opaque]="isLoading">
      <thead class="thead-dark">
        <tr>
          <th class="col-md-1">{{resourceNameLabel ? ('RESOURCE_NAME_LABEL' | translate) : resourceNameLabel}}</th>
          <th class="col-md-2">{{expertiseLabel ? ('EXPERTISE_LABEL' | translate) : expertiseLabel}}</th>
          <th class="col-md-2">{{activityGroupLabel ? ('ACTIVITY_GROUP_LABEL' | translate) : activityGroupLabel}}</th>
          <th class="col-md-3">{{activityNameLabel ? ('ACTITIVY_NAME_LABEL' | translate) : activityNameLabel}}</th>
          <th class="col-md-2" colspan="3" style="text-align: center;">{{action ? ('ACTION' | translate) : action}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let resource of resources | paginate: { itemsPerPage: limit, currentPage: currentPage , id: 'item-server', totalItems: totalRecords }; let i = index">
          <td>{{resource.name}}</td>
          <td>{{resource.expertise}}</td>
          <td>{{resource.activityGroups}}</td>
          <td>{{resource.activityNames}}</td>
          <td align="right">
            <button type="button" class="btn btn-view" (click) = "beforeGoToAResoucePage()" [routerLink]="['/resource/view', storeCountry, storeCode, resource.id]">{{view ? ('VIEW' | translate) : view}}</button>
          </td>
          <td align="right">
            <button type="button" class="btn btn-edit" (click) = "beforeGoToAResoucePage()" [routerLink]="['/resource/edit', storeCountry, storeCode, resource.id]">{{edit ? ('EDIT' | translate) : edit}}</button>
          </td>
          <td align="right">
            <button type="button" [disabled]="(!authService.isSystemAdmin && !authService.isBM)" class="btn btn-activeate-deactivate" (click) = "deactivateResource(resource.id, resource.status)">{{resource.status ? (deactivate ? ('DEACTIVATE' | translate) : deactivate) : (activate  ? ('ACTIVATE' | translate) : activate)}}</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-found" *ngIf="!isLoading && !resources?.length">{{noResourceFound ? ('NO_RESOURCE_FOUND' | translate) : noResourceFound}}</div>
    <div class="pagination" *ngIf="!isLoading && resources?.length">
      <pagination-controls (pageChange)="getResourceList($event)" id="item-server"></pagination-controls>
    </div>
  </div>
</div>