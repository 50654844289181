export const CODINGCONSTANTS: any = {
    SA_ROLE: 'System Admin',
    RO_ROLE: 'Retail Ops',
    SM_ROLE: 'SM',
    SMT_ROLE: 'SMT',
    BA_ROLE: 'Beauty Advisor',
    BM_ROLE: 'Brand Admin',
    BR_ROLE: 'Brand Resource',
    CUSTOMER_INFO_SIGNATURE: 'Customer Info Signature',
    ORDER_SIGNATURE: 'Order Signature',
    ADD_TO_WISHLIST: 'Add To Wishlist',
    REMOVE_FROM_WISHLIST: 'Remove From Wishlist',
    UPDATE_BEAUTY_PROFILE: 'Update Beauty Profile',
    DOTS: ' ...',
    DAYS_OF_WEEK_SUNDAY_TO_STAURDAY: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
    DAYS_OF_WEEK_MONDAY_TO_SUNDAY: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
}