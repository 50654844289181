import { EcommCountries } from './ecomm-countries';
import { environment } from 'src/environments/environment';

export class EcommUtility{
    static ecommCountries = new EcommCountries();
        constructor(ecommCountries: EcommCountries) { 
    }
    static getUrl(acceptLanguage: string) {
        if (environment.nonLocal) {
            return EcommUtility.fetchUrlByCountry(acceptLanguage, this.ecommCountries.production);
        } else {
            return EcommUtility.fetchUrlByCountry(acceptLanguage, this.ecommCountries.development);
        }
    }
    private static fetchUrlByCountry(acceptLanguage: string, env: any) {
        let ecommUrl = env.SG;
        if (acceptLanguage != null && acceptLanguage.indexOf('en-US') != -1) {
            acceptLanguage = 'SG';
        }
        Object.entries(env).forEach(element => {
            const [country, url] = element;
            if (acceptLanguage.indexOf(country) != -1) {
                ecommUrl = url;                
            }
        });
        return ecommUrl;
    }
};