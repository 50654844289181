import {Component, OnInit} from '@angular/core';
import { CONSTANTS } from 'src/app/common/constants';
import { TranslateService } from '@ngx-translate/core';


declare var $: any;
@Component({
    selector: 'message-modal',
    templateUrl: './message-modal.component.html'
})

export class MessageModalComponent implements OnInit {

    index: string = '';
    ok: string = CONSTANTS.OK;
    cancel: string = CONSTANTS.CANCEL;

    constructor(
        public translate: TranslateService) {
        this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
        // Set default language
        this.translate.setDefaultLang('en-SG');
    }
    ngOnInit() {}

    show(header: string, body: string, icon: string, onCloseClick?: any, closeMessage?: string, onOkClick?: any, okMessage?: string, index?: number) {
        let messageModal;
        if (index) {
            this.index = index.toString();
       } else {
            this.index = '';
       }
        setTimeout(() => {
            messageModal = $('#messageModal' + this.index);
            let modalCloseButton = messageModal.find('#modalCloseButton');
            let modalOKButton = messageModal.find('#modalOKButton');
            let modalBackDrop = messageModal.find('.modal-backdrop');
            modalBackDrop.css('z-index', 1600);
            modalCloseButton.unbind();
            modalOKButton.unbind();
            // Set header
            messageModal.find('.message-header').html(header);
            // Set image
            let img = messageModal.find('.icon-message');
            img.attr('src', icon);
            img.show();
            messageModal.find('.message-content').html(body);
            if (closeMessage) {
                modalCloseButton.text(closeMessage);
                modalCloseButton.show();
           } else {
                modalCloseButton.hide();
           }
            if (okMessage) {
                modalOKButton.text(okMessage);
                modalOKButton.show();
           } else {
                modalOKButton.hide();
           }
            messageModal.on('hidden.bs.modal', () => {
                modalBackDrop.css('z-index', 1040);
                if (modalBackDrop.length > 0) {
                    $('body').addClass('modal-open');
               }
           })
            if (onCloseClick) modalCloseButton.one('click', null, null, onCloseClick);
            if (onOkClick) modalOKButton.one('click', null, null, onOkClick);
            if (messageModal.hasClass('in')) return;
            else messageModal.modal('show');
       })
   }

    close() {
        $('#messageModal' + this.index).modal('hide');
   }

}