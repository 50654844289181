import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';
import { EcommUtility } from '../common/ecomm-utility';

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private utilService: UtilService, protected authService: AuthService) {}

  filterUsers(
    page: number,
    limit: number,
    searchAllFlag: boolean,
    currentLocation: string,
    currentStore: string,
    keywordSearch: string,
    filterFullName: string,
    filterBaId: string,
    filterStoreName: string,
    filterLocation: string,
    filterRole: string,
    filterEmail: string,
  ): Observable<any> {
    let params = {
      offset: (page - 1) * limit,
      limit,
      allLocationFlag: searchAllFlag,
      currentLocation,
      currentStore,
      keywordSearch,
      filterFullName,
      filterBaId,
      filterStoreName,
      filterLocation,
      filterRole,
      filterEmail
   };
    localStorage.setItem('pageNum', page + '');
    localStorage.setItem('limitNum', limit + '')
    localStorage.setItem('keywordSearch', keywordSearch);
    localStorage.setItem('filter2', filterFullName);
    localStorage.setItem('filter1', filterBaId);
    localStorage.setItem('filter3', filterStoreName);
    localStorage.setItem('filter4', filterLocation);
    localStorage.setItem('filter5', filterRole);
    localStorage.setItem('filter6', filterEmail);
    return this.http
      .post(ENDPOINTS.USER_FILTER, params, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  createUser(data: any): Observable<any> {
    return this.http
      .post(ENDPOINTS.USER, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  getOneUser(userId: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.USER_BY + userId, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  updateUser(userId: string, data: any): Observable<any> {
    return this.http
      .put(ENDPOINTS.USER_BY + userId, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  delete(id: string): Observable<any> {
    return this.http
      .delete(ENDPOINTS.USER_BY + id, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  getUserNumber(loginId: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.USER_BY + loginId + '/number', {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

 getUserEmail(email: string): Observable<any> {
  return this.http
    .get(ENDPOINTS.USER_BY + email + '/email', {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
    .pipe(map(this.utilService.extractData),
    catchError(this.utilService.handleError));
}

  getRoles(): Observable<any> {
    return this.http
      .get(ENDPOINTS.ROLE, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  recoverWebPassword(userId: string) {
    return this.http
      .post(ENDPOINTS.RECOVER_WEB_PASSWORD, userId, {headers:{'Content-Type': 'application/json', 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  resetWebPassword(password: string, token: string) {
    let body = {
      password,
      token
   }
    return this.http
      .post(ENDPOINTS.RESET_WEB_PASSWORD, body, {headers:{'Content-Type': 'application/json', 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  ecomValidatePassword(password: string, passwordConfirm: string): Observable<any> {
    let accept_language = localStorage.getItem('selectedLanguage') as string;
    const ecomPasswordValidateUrl = EcommUtility.getUrl(accept_language) + '/api/v2.5/password/validators';
    const payload = {
      'user': {
        'email': localStorage.getItem('userId') as string,
        'type': 'sign_up',
        'password': password,
        'password_confirmation': passwordConfirm
     }
   }
    const country = localStorage.getItem('storeCountry') as string;
    return this.http
      .post(ecomPasswordValidateUrl, payload, {headers:{'Content-Type': 'application/json', 'accept-language': accept_language}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

}