<div class="user-wrapper">
    <div>
      <div class="table-title">
        {{oobaOrders ? ('OOBA_ORDERS' | translate) : oobaOrders}}
      </div>
    </div>
    <div *ngIf="isLoading" class="loading-spinner">
      <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg" />
    </div>
    <div *ngIf="!isLoading" class="sephora-container">
      <form class="form-horizontal">
  
        <div class="form-group">
          <div class="row">
            <label for="baId" class="control-label col-md-2">{{ba_id ? ( 'BA_ID'| translate) : ba_id}}</label>
            <div class="col-md-6">
              <input type="text" id="baId" name="baId" [disabled]="true" class="form-control" [(ngModel)]="order.baLoginId">
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="baName" class="control-label col-md-2">{{baName ? ( 'BA_NAME'| translate) : baName}}</label>
            <div class="col-md-6">
              <input type="text" id="baName" name="baName" [disabled]="true" class="form-control" [(ngModel)]="order.baName">
            </div>
          </div>
        </div>
  
        <div class="form-group">
          <div class="row">
            <label class="control-label col-md-2">{{items ? ( 'ITEMS'| translate) : items}}</label>
            <div class="col-md-6 table-area">
              <table class="table table-hover table-ba" [class.opaque]="isLoading">
                <thead class="thead-dark">
                  <tr>
                    <th id='brand'>Brand</th>
                    <th id='name'>Name</th>
                    <th id='icon'>Icon</th>
                    <th id='qty'>Quantity</th>x
                    <th id='fee'>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of order.items">
                    <td>
                      {{item.brand}}
                    </td>
                    <td>
                      {{item.name}}
                    </td>
                    <td>
                      <img style="background: gray" src="{{item.icon}}" height="50" width="75">
                    </td>
                    <td>
                      {{item.qty}}
                    </td>
                    <td>
                      {{item.price}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="subTotal" class="control-label col-md-2">{{subtotal ? ( 'SUBTOTAL'| translate) : subtotal}}</label>
            <div class="col-md-6">
              <input type="text" id="subtotal" name="subtotal" [disabled]="true" class="form-control" [(ngModel)]="order.subtotal">
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="preGst" class="control-label col-md-2">{{priceBeforeGST ? ( 'PRICE_BEFORE_GST'| translate) : priceBeforeGST}}</label>
            <div class="col-md-6">
              <input type="text" id="preGst" name="preGst" [disabled]="true" class="form-control" [(ngModel)]="order.preGst">
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="gstPercent" class="control-label col-md-2">{{gstIncluded ? ( 'GST_INCLUDED'| translate) : gstIncluded}}</label>
            <div class="col-md-6">
              <input type="text" id="gstPercent" name="gstPercent" [disabled]="true" class="form-control" [(ngModel)]="order.gstPercent">
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="shippingCost" class="control-label col-md-2">{{shipping ? ( 'SHIPPING'| translate) : shipping}}</label>
            <div class="col-md-6">
              <input [disabled]="true" id="shippingCost" name="shippingCost" type="text" class="form-control" [(ngModel)]="order.shippingCost">
            </div>
          </div>
        </div>
  
        <div class="form-group">
          <div class="row">
            <label for="totalAmount" class="control-label col-md-2">{{total ? ( 'TOTAL'| translate) : total}}</label>
            <div class="col-md-6">
              <input [disabled]="true" id="totalAmount" name="totalAmount" type="text" class="form-control" [(ngModel)]="order.totalAmount">
            </div>
          </div>
        </div>
  
        <div class="form-group">
          <div class="row">
            <label for="pointsEarned" class="control-label col-md-2">{{pointsEarnedText ? ( 'POINTS_EARNED'| translate) : pointsEarnedText}}</label>
            <div class="col-md-6">
              <input [disabled]="true" id="pointsEarned" name="pointsEarned" type="text" class="form-control" [(ngModel)]="order.pointsEarned">
            </div>
          </div>
        </div>
  
        <div class="form-group">
          <div class="row">
            <label for="deliveryDate" class="control-label col-md-2">{{estimatedDeliveryDate ? ( 'ESTIMATED_DELIVERY_DATE'| translate) : estimatedDeliveryDate}}</label>
            <div class="col-md-6">
              <input type="text" [disabled]="true" id="deliveryDate" name="deliveryDate" class="form-control" [(ngModel)]="order.deliveryDate">
            </div>
          </div>
        </div>

        <div class="form-group">
            <div class="row">
              <label for="gstRegNo" class="control-label col-md-2">{{gstRegistrationNo ? ( 'GST_REGISTRATION_NO'| translate) : gstRegistrationNo}}</label>
              <div class="col-md-6">
                <input type="text" [disabled]="true" id="gstRegNo" name="gstRegNo" class="form-control" [(ngModel)]="order.gstRegNo">
              </div>
            </div>
          </div>

        <div class="form-group">
          <div class="row">
            <label for="orderNo" class="control-label col-md-2">{{orderNumberText ? ( 'ORDER_NUMBER_TEXT'| translate) : orderNumberText}}</label>
            <div class="col-md-6">
              <input type="text" [disabled]="true" id="orderNo" name="orderNo" class="form-control" [(ngModel)]="order.orderNo">
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="createdAt" class="control-label col-md-2">{{dateOfOrder ? ( 'DOO'| translate) : dateOfOrder}}</label>
            <div class="col-md-6">
              <input type="text" [disabled]="true" id="createdAt" name="createdAt" class="form-control" [(ngModel)]="order.createdAt">
            </div>
          </div>
        </div>

        <div class="form-group">
            <div class="row">
              <label for="paymentMethod" class="control-label col-md-2">{{paymentMethod ? ( 'PAYMENT_METHOD'| translate) : paymentMethod}}</label>
              <div class="col-md-6">
                <input type="text" [disabled]="true" id="paymentMethod" name="paymentMethod" class="form-control" [(ngModel)]="order.paymentMethod">
              </div>
            </div>
          </div>
  
        <div class="form-group">
          <div class="row">
            <label for="address" class="control-label col-md-2">{{shipAddress ? ( 'SHIP_ADDRESS'| translate) : shipAddress}}</label>
            <div class="col-md-6">
              <textarea rows="4" readonly id="address" name="address" type="text" class="form-control" [(ngModel)]="order.address"></textarea>
            </div>
          </div>
        </div>
  
        <div class="form-close">
          <div class="col-md-8">
            <div class="pull-right">
              <button type="button" class="btn btn-dark" (click)="cancelForm()">{{back ? ( 'BACK'| translate) : back}}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  