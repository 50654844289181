import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import Bugsnag from '@bugsnag/js';

import {CONSTANTS} from 'src/app/common/constants';
import {AuditService} from 'src/app/services/audit.service';
import {UtilService} from 'src/app/services/util.service';
import {MessageModalComponent} from 'src/app/components/modal/message-modal/message-modal.component';
import {AuditForm} from 'src/app/models/audit-form';
import { CODINGCONSTANTS } from 'src/app/common/coding-constants';
import { TranslateService } from '@ngx-translate/core';

// Jquery
declare var $: any;

@Component({
  selector: 'audit-component',
  templateUrl: './audit.component.html'
})
export class AuditComponent implements OnInit {
  @ViewChild(MessageModalComponent)
  messageModal!: MessageModalComponent;

  isLoading: boolean = true;

  auditId : string = '';

  auditIdStr: string = '';
  customerInfoAudit: string = CONSTANTS.CUSTOMER_INFO_AUDIT;
  auditItemId: string = CONSTANTS.AUDIT_ITEM_ID;
  auditType: string = CONSTANTS.AUDIT_TYPE;
  beautyPassId: string = CONSTANTS.BEAUTY_PASS_ID;
  customerName: string = CONSTANTS.CUSTOMER_NAME;
  orderNumber: string = CONSTANTS.ORDER_NUMBER;
  orderId: string = CONSTANTS.ORDER_ID;
  upc: string = CONSTANTS.UPC;
  ba_id: string = CONSTANTS.BA_ID;
  baName: string = CONSTANTS.BA_NAME;
  storeId: string = CONSTANTS.STORE_ID;
  storeName: string = CONSTANTS.STORE_NAME;
  modifiedOn: string = CONSTANTS.MODIFIED_ON;
  audit_trail: string = CONSTANTS.AUDIT_TRIAL;
  digitalSignature: string = CONSTANTS.DIGITAL_SIGNATURE;
  back: string = CONSTANTS.BACK;

  auditForm : AuditForm =  {
    baLoginId: '',
    type: '',
    orderNum: '',
    orderId: '',
    cardNum : '',
    baName: '',
    storeCode : '',
    storeName : '',
    signatureUrl: '',
    customerName: '',
    oldEmail : '',
    newEmail: '',
    oldPhone : '',
    newPhone : '',
    oldCountryName: '',
    newCountryName: '',
    modifiedOn : '',
    oldCountry: '',
    newCountry : '',
    emailOption : '',
    oldEmailOption: '',
    mobileOption : '',
    oldMobileOption: '',
    data: '',
    upc: ''
 };

  time : string = '';
  audit_trail_text : string = '';

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private auditService : AuditService,
    private utilService : UtilService,
    public translate: TranslateService
    ) {
    this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
    // Set default language
    this.translate.setDefaultLang('en-SG');
  }

  ngOnInit() {
    this.getAuditInfo();
    this.auditIdStr = ('000000000'+ Number(this.auditId)).slice(-10);
 }

  getAuditInfo(){
    this.route.params.subscribe(params => {
      let auditId = params['auditId'];
      // Not edit mode
      if (!auditId) {
        this.isLoading = false;
        return;
     }
      if (auditId) {
        this.auditId = auditId;
        this.auditService.getOneAudit(auditId).subscribe(
          res => {
            this.isLoading = false;
            this.setUpAuditInfo(res);
         },
          err => {
            this.isLoading = false;
            Bugsnag.notify(err);
         }
        );
     }
   });
 }

  setupAuditTrailText(auditForm: AuditForm){
    if (!this.auditForm.type) {
      this.auditForm.type = CODINGCONSTANTS.CUSTOMER_INFO_SIGNATURE
   }
    if (this.auditForm.type == CODINGCONSTANTS.CUSTOMER_INFO_SIGNATURE) {
      if (auditForm.newEmail !== auditForm.oldEmail){
        this.audit_trail_text += CONSTANTS.EMAIL_TEXT + '\'' + auditForm.oldEmail + '\'' + CONSTANTS.CHANGED_TO  + ' \'' + auditForm.newEmail + '\'\n'
     }
      if (auditForm.newPhone !== auditForm.oldPhone){
        this.audit_trail_text += CONSTANTS.PHONE_TEXT + ' \'' + auditForm.oldPhone + '\'' + CONSTANTS.CHANGED_TO + '\'' + auditForm.newPhone + '\'\n'
     }
      if (auditForm.oldCountry !== auditForm.newCountry){
        this.audit_trail_text += CONSTANTS.COMM_COUNTRY + '\'' + auditForm.oldCountryName + '\'' + CONSTANTS.CHANGED_TO + '\'' + auditForm.newCountryName + '\'\n'
     }
      if (auditForm.oldEmailOption != auditForm.emailOption){
        let textOld = '';
        let textNew = '';
        if (auditForm.oldEmailOption == '1'){
          textOld = CONSTANTS.YES;
       }else{
          textOld = CONSTANTS.NO;
       }
        if (auditForm.emailOption == '1'){
          textNew = CONSTANTS.YES;
       }else{
          textNew = CONSTANTS.NO;
       }
        this.audit_trail_text += CONSTANTS.EMAIL_OPT_IN + '\'' + textOld + '\'' + CONSTANTS.CHANGED_TO + '\'' + textNew + '\'\n'
     }
      if (auditForm.oldMobileOption != auditForm.mobileOption){
        let textOld = '';
        let textNew = '';
        if (auditForm.oldMobileOption == '1'){
          textOld = CONSTANTS.YES;
       }else{
          textOld = CONSTANTS.NO;
       }
        if (auditForm.mobileOption == '1'){
          textNew = CONSTANTS.YES;
       }else{
          textNew = CONSTANTS.NO;
       }
        this.audit_trail_text += CONSTANTS.SMS_OPT_IN + '\'' + textOld + '\'' + CONSTANTS.CHANGED_TO + '\'' + textNew + '\'\n'
     }
      
      if(!this.audit_trail_text) {
        this.audit_trail_text += CONSTANTS.REDEMPTION_MADE_BY + auditForm.baName + ' (' + auditForm.baLoginId + ')' + CONSTANTS.AT_THE + auditForm.storeName + '\n'
     }
   }

    if (this.auditForm.type == CODINGCONSTANTS.ADD_TO_WISHLIST || this.auditForm.type == CODINGCONSTANTS.REMOVE_FROM_WISHLIST) {
      this.auditForm.upc = JSON.parse(this.auditForm.data)['upc'];
   }

 }

  setUpAuditInfo(info: any) {
    // Use setTimeout to get around angular model update bug
    setTimeout(() => {
      // Assign attributes
      this.auditForm = Object.assign({}, info);
      this.time = this.utilService.formatDateTimeNew(this.auditForm.modifiedOn);
      this.setupAuditTrailText(this.auditForm);
      this.isLoading = false;
   }, 0);
 }

  setUpdateTitle() {
    this.title.setTitle(CONSTANTS.VIEW_AUDIT_TRIAL);
 }

  cancelForm() {
    this.backToAuditList();
 }

  backToAuditList() {
    this.router.navigate(['/management'], {queryParams: {t: 'customer'}});
 }
}
