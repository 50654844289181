<div class="password-wrapper recover-password-wrapper" (keydown)="handleKeyDown($event)">
  <div *ngIf="isFirstStage" class="recover-panel">
    <div *ngIf="isLoading" class="loader"></div>
    <div class="recover-group">
      <div class="recover-text">
        {{employeeIddPasswordReset ? ('EMPLOYEE_ID_PASSWORD_RESET' | translate) : employeeIddPasswordReset}}
      </div>
      <input id="userId" class="recover-input" type="text" maxlength="20" spellcheck="false" [(ngModel)]="userId" required (focusout)="clearErrorIfValid($event)">
      <span class="floating-label">{{employeeId ? ('EMPLOYEE_ID'| translate) : employeeId}}</span>
      <span class="underline"></span>
      <span class="text-error">{{employeeIdEmpty ? ('CONSTANTS.EMPLOYEE_ID_EMPTY' | translate) : employeeIdEmpty}}</span>
    </div>
    <button type="button" class="recover-button" (click)="handleRecoverPassword()">{{recoverPassword ? ('RECOVER_PASSWORD' | translate) : recoverPassword}}</button>
  </div>

  <div *ngIf="!isFirstStage" class="recover-panel">
    <div *ngIf="isLoading" class="loader"></div>
    <div class="recover-group">
      <div class="reset-title-text">{{resetWebPassword ? ('RESET_WEB_PASSWORD' | translate) : resetWebPassword}}</div>
      <div class="reset-text">{{inputNewPassword ? ('INPUT_NEW_PASSWORD' | translate) : inputNewPassword}}</div>
      <div style="position: relative" [class.push-bottom]="$('.text-error-pw').is(':visible')">
        <input id="password" class="recover-input" name="password" type="password" maxlength="20" spellcheck="false" [(ngModel)]="password"
          required (focusout)="clearErrorIfValid($event)" (ngModelChange)="ecomValidatePassword()">
        <span class="floating-label">{{newPassword ? ('NEW_PASSWORD' | translate) : newPassword}} *</span>
        <span class="underline"></span>
        <span class="text-error">{{enterNewPassword ? ('ENTER_NEW_PASSWORD' | translate) : enterNewPassword}}</span>
        <div [hidden]="!passwordMessage" style="margin-top:10px">
          <p *ngFor="let message of passwordValidationMessages" [ngClass]="{'errorMessage': message.is_error == true, 'successMessage' : message.is_error == false }">
              <span [hidden]="message.is_error">&#10003;</span>
              <span [hidden]="!message.is_error">&#8226;</span>
              {{message.message}}
          </p>
      </div>
      <div style="position: relative; margin-top: 20px;" [class.push-bottom]="$('.text-error-pw').is(':visible')">
        <input id="password-confirm" class="recover-input" name="password-confirm" type="password" maxlength="20" spellcheck="false" [(ngModel)]="passwordConfirm"
          required (focusout)="clearErrorIfValid($event)">
        <span class="floating-label">{{passwordConfirmation ? ('PASSWORD_CONFIRMATION' | translate) : passwordConfirmation}} *</span>
        <span class="underline"></span>
        <span class="text-error">{{enterNewPassword ? ('ENTER_NEW_PASSWORD' | translate) : enterNewPassword}}</span>
        <div class="error" *ngIf="((password.length > 0) && (passwordConfirm.length > 0) && (password != passwordConfirm))" class="errorMessage messagePadding"><span>{{passwordNotMatch ? ('PASSWORD_NOT_MATCH' | translate) : passwordNotMatch}}</span></div>
      </div>
    </div>
    <div style="text-align:center;">
      <button class="reset-button" type="button" (click)="handleResetPassword()">{{updatePassword ? ('UPDATE_PASSWORD' | translate) : updatePassword}}</button>
    </div>
  </div>
</div>