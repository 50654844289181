import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';

// Services
import {NotificationsService} from 'angular2-notifications';
import {UserService} from 'src/app/services/user.service';
import {PasswordValiationMessage} from 'src/app/models/password-validation-message';
import {CONSTANTS} from 'src/app/common/constants';
import { TranslateService } from '@ngx-translate/core';

// Jquery
declare var $: any;

@Component({
  selector: 'password-component',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
  $ = $;
  userId: string = '';
  password: string = '';
  passwordConfirm: string = '';
  token: string = '';
  passwordValidationMessages: Array<PasswordValiationMessage> = [];
  passwordMessage = false;
  //
  isFirstStage: boolean = true;
  isFormValid: boolean = false;
  isLoading: boolean = false;
  employeeId: string = CONSTANTS.EMPLOYEE_ID;
  employeeIdEmpty: string = CONSTANTS.EMPLOYEE_ID_EMPTY;
  employeeIddPasswordReset: string = CONSTANTS.EMPLOYEE_ID_PASSWORD_RESET;
  recoverPassword: string = CONSTANTS.RECOVER_PASSWORD;
  resetWebPassword: string = CONSTANTS.RESET_WEB_PASSWORD;
  inputNewPassword: string = CONSTANTS.INPUT_NEW_PASSWORD;
  newPassword: string = CONSTANTS.NEW_PASSWORD;
  enterNewPassword: string = CONSTANTS.ENTER_NEW_PASSWORD;
  passwordConfirmation: string = CONSTANTS.PASSWORD_CONFIRMATION;
  passwordNotMatch: string = CONSTANTS.PASSWORD_NOT_MATCH;
  updatePassword: string = CONSTANTS.UPDATE_PASSWORD;

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private notificationsService: NotificationsService,
    public translate: TranslateService
    ) {
      this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
      // Set default language
      this.translate.setDefaultLang('en-SG');
    }

  ngOnInit() {
    this.title.setTitle('Recover password');
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      if (this.token) {
        this.isFirstStage = false;
        this.prepaireEcomPasswordValidation();
     }
   });
 }

  handleRecoverPassword() {
    this.isFormValid = true;
    this.clearError();
    this.validateRequired();
    if (!this.isFormValid) return;
    this.isLoading = true;
    this.disableButtons();
    this.recoverWebPassword();
 }

  handleResetPassword() {
    this.isFormValid = true;
    this.clearError();
    this.validateRequired();
    this.ecomValidatePassword();
    this.compareConfirmPassword('password-confirm');
    if (!this.isFormValid) return;
    this.isLoading = true;
    this.disableButtons();
    this.resetPassword();
 }

  compareConfirmPassword(inputName: string) {
    let content = $(`[name='` + inputName + `']`).val();
    this._clearErrorPw(inputName);

    if (!content || !content.trim()) {
      this.isFormValid = false;
      return;
   }
    if (this.password !== content) {
      this.isFormValid = false;
      this._showErrorPw(inputName);
   }
 }

  prepaireEcomPasswordValidation() {
    this.userService.resetWebPassword('', this.token).subscribe(
      res => {
        localStorage.setItem('ecomUrl', res.body.ecomUrl);
        localStorage.setItem('storeCountry', res.body.storeCountry);
        localStorage.setItem('userId', res.body.userId);
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.TOKEN_LOGIN_VALIDATED,
          {
            timeOut: 1000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isLoading = false;
        this.enableButtons();
     },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          CONSTANTS.TOKEN_EXPIRED_MSG,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.enableButtons();
        this.isLoading = false;
     }
    );
 }

  ecomValidatePassword() {
    if(this.password.length > 0){
        this.clearError();
        this.userService.ecomValidatePassword(this.password, this.passwordConfirm).subscribe(
            res => {
              this.passwordValidationMessages = [].concat(res.body.data.attributes.password_validations);
           },
            err => {
              this.showErrorPopup(CONSTANTS.ERROR_VALIDATING);
           }
        );
        this.passwordMessage = true;
   } else {
        this.passwordMessage = false;
   }
 }

  resetPassword() {
    this.userService.resetWebPassword(this.password, this.token).subscribe(
      res => {
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.PASSWORD_RESET_SUCCESS,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isLoading = false;
        this.enableButtons();
        this.router.navigate(['/login']);
     },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          CONSTANTS.TOKEN_EXPIRED,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.enableButtons();
        this.isLoading = false;
     }
    );
 }

  recoverWebPassword() {
    this.userService.recoverWebPassword(this.userId).subscribe(
      res => {
        this.isLoading = false;
        this.enableButtons();
        this.router.navigate(['/login']);
     },
      err => {
        if (err.status == 400){
          this.notificationsService.error(
            CONSTANTS.ERROR,
            CONSTANTS.INVALID_BEAUTY_ADVISOR,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
           }
          );
          this.enableButtons();
          this.isLoading = false;
       }else{
          this.notificationsService.error(
            CONSTANTS.ERROR,
            CONSTANTS.INVALID_USER_ID,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
           }
          );
          this.enableButtons();
          this.isLoading = false;
       }
     }
    );
 }

  clearErrorIfValid(e: any) {
    if (e.target.value) {
      $(e.target)
        .siblings('.text-error')
        .eq(0)
        .css('opacity', 0);
   }
 }

  clearError() {
    $('.text-error').css('opacity', 0);
 }

  disableButtons() {
    $('button').addClass('disabled');
    $('button').attr('disabled', true);
 }

  enableButtons() {
    $('button').removeClass('disabled');
    $('button').attr('disabled', false);
 }

  validateRequired() {
    if (this.isFirstStage && (!this.userId || !this.userId.trim())) {
      $('#userId ~ .text-error').css('opacity', 1);
      this.isFormValid = false;
   }
    if (!this.isFirstStage && (!this.password || !this.password.trim())) {
      $('#password ~ .text-error')
        .eq(0)
        .css('opacity', 1);
      this.isFormValid = false;
   }
 }

  // Show password error message under input
  private _showErrorPw(inputName: string) {
    let inputElement = $(`[name ='` + inputName + `']`);
    inputElement.focus();
    inputElement.addClass('input-error');
    $(`div.text-error-pw[for='` + inputName + `']`).show();
 }

  //Clear password error message under input
  private _clearErrorPw(inputName: string) {
    let inputElement = $(`[name ='` + inputName + `']`);
    inputElement.removeClass('input-error');
    $(`div.text-error-pw[for='` + inputName + `']`).hide();
 }

  showErrorPopup(message: string) {
    this.notificationsService.error(
      CONSTANTS.ERROR,
      message,
      {
        timeOut: 1000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
     }
    );
 }

  handleKeyDown(event: any) {
    if (this.isLoading) return;
    // If 'Enter'
    if (event.keyCode == 13) {
      if (this.isFirstStage) this.handleRecoverPassword();
      else this.handleResetPassword();
   }
 }
}