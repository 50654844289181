import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';

@Injectable()
export class AuditService {
  constructor(private http: HttpClient, private utilService: UtilService, protected authService: AuthService) {}

  filterAuditTrail(
    baLocation: string,
    page: number,
    limit: number,
    keywordSearch: string,
    filterCusName: string,
    filterCusId: string,
    filterBaId: string,
    filterStoreId: string,
    filterStoreName: string,
    filterModifiedOn: string,
    filterType: string,
    filterOrderNum: string,
    filterOrderId: string,
    filterAuditId: string,
    timezoneOffset: number
  ): Observable<any> {
    let params = {
      baLocation,
      offset: (page - 1) * limit,
      limit,
      keywordSearch,
      filterCusName,
      filterCusId,
      filterBaId,
      filterStoreId,
      filterStoreName,
      filterModifiedOn,
      filterType,
      filterOrderNum,
      filterOrderId,
      filterAuditId,
      timezoneOffset
   };
    localStorage.setItem('pageNum', page + '');
    localStorage.setItem('limitNum', limit + '')
    localStorage.setItem('keywordSearch', keywordSearch);
    localStorage.setItem('filter3', filterCusName);
    localStorage.setItem('filter2', filterCusId);
    localStorage.setItem('filter5', filterStoreId);
    localStorage.setItem('filter4', filterBaId);
    localStorage.setItem('filter6', filterStoreName);
    localStorage.setItem('filter7', filterModifiedOn);
    localStorage.setItem('filter8', filterType);
    localStorage.setItem('filter9', filterOrderNum);
    localStorage.setItem('filter10', filterOrderId);
    localStorage.setItem('filter1', filterAuditId);
    return this.http
      .post(ENDPOINTS.AUDIT_TRAIL_FILTER, params, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  getOneAudit(auditId: string): Observable<any>{
    return this.http
      .get(ENDPOINTS.AUDIT + auditId, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

}
