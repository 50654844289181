<div class="user-wrapper">
    <div>
      <div class="table-title">
        {{customerInfoAudit ? ( 'CUSTOMER_AUDIT'| translate) : customerInfoAudit}}
      </div>
    </div>
    <div *ngIf="isLoading" class="loading-spinner">
      <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg" />
    </div>
    <div *ngIf="!isLoading" class="sephora-container">
      <form class="form-horizontal">
  
        <div class="form-group">
          <div class="row">
            <label for="id" class="control-label col-md-2">{{auditItemId ? ( 'AUDIT_ITEM_ID'| translate) : auditItemId}}</label>
            <div class="col-md-6">
              <input type="text" id="id" name="id" [disabled]="true" class="form-control" [(ngModel)]="auditIdStr">
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="type" class="control-label col-md-2">{{auditType ? ( 'AUDIT_TYPE'| translate) : auditType}}</label>
            <div class="col-md-6">
              <input type="text" id="type" name="type" [disabled]="true" class="form-control" [(ngModel)]="auditForm.type">
            </div>
          </div>
        </div>

        <div class="form-group" [hidden]="!auditForm.cardNum">
          <div class="row">
            <label for="cardNum" class="control-label col-md-2">{{beautyPassId ? ( 'BEAUTY_PASS_ID'| translate) : beautyPassId}}</label>
            <div class="col-md-6">
              <input type="text" id="cardNum" name="cardNum" [disabled]="true" class="form-control" [(ngModel)]="auditForm.cardNum">
            </div>
          </div>
        </div>

        <div class="form-group" [hidden]="!auditForm.customerName">
          <div class="row">
            <label for="cusName" class="control-label col-md-2">{{customerName ? ( 'CUSTOMER_NAME'| translate) : customerName}}</label>
            <div class="col-md-6">
              <input type="text" id="cusName" name="cusName" [disabled]="true" class="form-control" [(ngModel)]="auditForm.customerName">
            </div>
          </div>
        </div>
  
        <div class="form-group" [hidden]="!auditForm.orderNum">
          <div class="row">
            <label for="orderNum" class="control-label col-md-2">{{orderNumber ? ( 'ORDER_NUMBER'| translate) : orderNumber}}</label>
            <div class="col-md-6">
              <input type="text" id="orderNum" name="orderNum" [disabled]="true" class="form-control" [(ngModel)]="auditForm.orderNum">
            </div>
          </div>
        </div>

        <div class="form-group" [hidden]="!auditForm.orderId">
          <div class="row">
            <label for="orderId" class="control-label col-md-2">{{orderId ? ( 'ORDER_ID'| translate) : orderId}}</label>
            <div class="col-md-6">
              <input type="text" id="orderId" name="orderId" [disabled]="true" class="form-control" [(ngModel)]="auditForm.orderId">
            </div>
          </div>
        </div>

        <div class="form-group" [hidden]="!auditForm.upc">
          <div class="row">
            <label for="upc" class="control-label col-md-2">{{upc ? ( 'UPC'| translate) : upc}}</label>
            <div class="col-md-6">
              <input type="text" id="upc" name="upc" [disabled]="true" class="form-control" [(ngModel)]="auditForm.upc">
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="baID" class="control-label col-md-2">{{ba_id ? ( 'BA_ID'| translate) : ba_id}}</label>
            <div class="col-md-6">
              <input [disabled]="true" id="baID" name="baID" type="text" class="form-control" [(ngModel)]="auditForm.baLoginId">
            </div>
          </div>
        </div>
  
        <div class="form-group">
          <div class="row">
            <label for="baName" class="control-label col-md-2">{{baName ? ('BA_NAME'| translate) : baName}}</label>
            <div class="col-md-6">
              <input [disabled]="true" id="baName" name="baName" type="text" class="form-control" [(ngModel)]="auditForm.baName">
            </div>
          </div>
        </div>
  
        <div class="form-group" *ngIf="auditForm.storeCode!='-'">
          <div class="row">
            <label for="storeId" class="control-label col-md-2">{{storeId ? ('STORE_ID'| translate) : storeId}}</label>
            <div class="col-md-6">
              <input [disabled]="true" id="storeId" name="storeId" type="text" class="form-control" [(ngModel)]="auditForm.storeCode">
            </div>
          </div>
        </div>
  
        <div class="form-group" *ngIf="auditForm.storeName!='-'">
          <div class="row">
            <label for="storeName" class="control-label col-md-2">{{storeName ? ('STORE_NAME'| translate) : storeName}}</label>
            <div class="col-md-6">
              <input type="text" [disabled]="true" id="storeName" name="storeName" class="form-control" [(ngModel)]="auditForm.storeName">
            </div>
          </div>
        </div>

        <div class="form-group">
            <div class="row">
              <label for="modifiedOn" class="control-label col-md-2">{{modifiedOn ? ('MODIFIED_ON'| translate) : modifiedOn}}</label>
              <div class="col-md-6">
                <input type="text" [disabled]="true" id="modifiedOn" name="modifiedOn" class="form-control" [(ngModel)]="time">
              </div>
            </div>
          </div>
  
        <div class="form-group" [hidden]="!audit_trail_text">
          <div class="row">
            <label for="auditTrail" class="control-label col-md-2">{{audit_trail ? ('AUDIT_TRIAL'| translate) : audit_trail}}</label>
            <div class="col-md-6">
              <textarea rows="4" readonly id="auditTrail" name="auditTrail" type="text" class="form-control" [(ngModel)]="audit_trail_text"></textarea>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="auditForm.signatureUrl">
          <div class="row">
            <label for="signature" class="control-label col-md-2">{{digitalSignature ? ('DIGITAL_SIGNATURE'| translate) : digitalSignature}}</label>
            <img id="signature" style="margin-left: 20px ; background: gray" src="{{auditForm.signatureUrl}}" height="190" width="300">
          </div>
        </div>
  
        <div class="form-close">
          <div class="col-md-8">
            <div class="pull-right">
              <button type="button" class="btn btn-dark" (click)="cancelForm()">{{back ? ('BACK'| translate) : back}}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  