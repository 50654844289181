import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';
import Bugsnag from '@bugsnag/js';

import {MessageModalComponent} from 'src/app/components/modal/message-modal/message-modal.component';
import {CountryService} from 'src/app/services/country.service';
import {Currency} from 'src/app/models/currency-item';
import {CONSTANTS} from 'src/app/common/constants';
import { TranslateService } from '@ngx-translate/core';

// Jquery
declare var $: any;

const SOM_FLAG = 'SOM';
const ACTION_BOX_FLAG = 'ACTION_BOX';

@Component({
  selector: 'country-component',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {
  @ViewChild(MessageModalComponent)
  messageModal!: MessageModalComponent;

  isLoading: boolean = true;
  isSendingForm: boolean = false;

  isFormValid: boolean = false;
  isSOMFileValid: boolean = true;
  isActionBoxFileValid: boolean = true;
  isLMSValid: boolean = true;
  isCountryValid: boolean = true;
  isCurrencyValid: boolean = true;
  isRateValid: boolean = true;
  editCurrency: string = CONSTANTS.EDIT_CURRENCY;
  countryId: string = CONSTANTS.COUNTRY_ID;
  countryName: string = CONSTANTS.COUNTRY_NAME;
  currencyName: string = CONSTANTS.CURRENCY_NAME;
  exchangeRate: string = CONSTANTS.EXCHANGE_RATE;
  currencyNote: string = CONSTANTS.CURRENCY_NOTE;
  lms: string = CONSTANTS.LMS;
  enterValidURL: string = CONSTANTS.ENTER_VALID_URL;
  open: string = CONSTANTS.OPEN;
  remove: string = CONSTANTS.REMOVE;
  som: string = CONSTANTS.SOM;
  chooseFile: string = CONSTANTS.CHOOSE_FILE;
  actionBox: string = CONSTANTS.ACTION_BOX;
  salesDashboard: string = CONSTANTS.SALES_DASHBOARD;
  enable: string = CONSTANTS.ENABLE;
  disable: string = CONSTANTS.DISABLE;
  save: string = CONSTANTS.SAVE;
  back: string = CONSTANTS.BACK;

  currency: Currency = {
    countryId: 0,
    countryName: '',
    currencyName: '',
    exchangeRate: '',
    somFile: new File([],''),
    somFileName: '',
    som: '',
    uploadSom: false,
    lms: '',
    actionBoxFile: new File([],''),
    actionBoxFileName: '',
    actionBox: '',
    uploadActionBox: false,
    salesDashboard: false,
 };

  currentCurrency: Currency = {
    countryId: 0,
    countryName: '',
    currencyName: '',
    exchangeRate: '',
    somFile: new File([],''),
    somFileName: '',
    som: '',
    uploadSom: false,
    lms: '',
    actionBoxFile: new File([],''),
    actionBoxFileName: '',
    actionBox: '',
    uploadActionBox: false,
    salesDashboard: false,
 };

  @ViewChild('fileControl')
  fileControl!: ElementRef;
  @ViewChild('actionBoxFileControl')
  actionBoxFileControl!: ElementRef;

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private countryService: CountryService,
    public translate: TranslateService
    ) {
    this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
    // Set default language
    this.translate.setDefaultLang('en-SG');
  }

  ngOnInit() {
    this.title.setTitle(CONSTANTS.COUNTRY_MANAGEMENT);
    this.getCurrencyInfo();
 }

  uploadSOM() {$('#somFile').click(); return false;}
  uploadActionBox() {$('#actionBoxFile').click(); return false;}

  fileChange(event: any) {
    if (event.target.files[0]) {
      this.currency.somFile = event.target.files[0];
      this.currency.somFileName = event.target.files[0].name;
      this._clearError('somFile');
      this.isSOMFileValid = true;
      this.checkMimeType(this.currency.somFile, SOM_FLAG);
   }
    if (this.currency.somFile || this.currency.somFileName) {
      $('[name="clearSom"]').removeClass('disabled');
   }
    if (this.isSOMFileValid) {
      this._clearError('somFile');
      this._validateFileSize('somFile', CONSTANTS.ERROR_FILE_SIZE);
   }
 }

  clearSomFile() {
    this.fileControl.nativeElement.value = null;
    this.currency.somFile = new File([],'');
    this.currency.somFileName = '';
    if (!this.currency.somFile) {
      $('[name="clearSom"]').addClass('disabled');
      $('[name="download"]').addClass('disabled');
   }
    this._clearError('somFile');
    this.isSOMFileValid = true;
    this.validateForm();
 }

  clearLms() {
    this.currency.lms = '';
    if (!this.currency.lms) {
      $('[name="clearLms"]').addClass('disabled');
      $('[name="openLink"]').addClass('disabled');
   }
    this._clearError('lms');
    this.isLMSValid = true;
    this.validateForm();
 }

  actionBoxfileChange(event: any) {
    if (event.target.files[0]) {
      this.currency.actionBoxFile = event.target.files[0];
      this.currency.actionBoxFileName = event.target.files[0].name;
      this.isActionBoxFileValid = true;
      this._clearError('actionBoxFile');
      this.checkMimeType(this.currency.actionBoxFile, ACTION_BOX_FLAG);
   }
    if (this.currency.actionBoxFile || this.currency.actionBoxFileName) {
      $('[name="clearActionBox"]').removeClass('disabled');
   }
    if (this.isActionBoxFileValid) {
      this._clearError('actionBoxFile');
      this._validateFileSize('actionBoxFile', CONSTANTS.ERROR_FILE_SIZE);
   }
 }

  clearActionBoxFile() {
    this.actionBoxFileControl.nativeElement.value = null;
    this.currency.actionBoxFile = new File([],'');
    this.currency.actionBoxFileName = '';
    if (!this.currency.actionBoxFile) {
      $('[name="clearActionBox"]').addClass('disabled');
      $('[name="actionBoxDownload"]').addClass('disabled');
   }
    this._clearError('actionBoxFile');
    this.isActionBoxFileValid = true;
    this.validateForm();
 }

  validateCountryName() {
    this.isCountryValid = true;
    this._validateRequire('countryName', CONSTANTS.ERROR_REQUIRE_TEXT);
 }

  validateCurrencyName() {
    this.isCurrencyValid = true;
    this._validateRequire('currencyName', CONSTANTS.ERROR_REQUIRE_TEXT);
 }

  validateExchangeRate() {
    this.isRateValid = true;
    this._validateRequire('exchangeRate', CONSTANTS.ERROR_REQUIRE_TEXT);
 }

  validateUrl() {
    this._clearError('lms');
    this.isLMSValid = true;
    this._validateUrl('lms', CONSTANTS.ERROR_VALID_URL);
    this._validateUrlLength('lms', CONSTANTS.ERROR_VALID_URL_LENGTH);
    if (this.currency.lms) {
      $('[name="clearLms"]').removeClass('disabled');
   }
    this.validateForm();
 }

  checkMimeType(file: any, flag: string) {
      let fileReader = new FileReader();
      fileReader.onloadend = (e: any) => {
        let arr = (new Uint8Array(e.target.result)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
       }
        if (header !== '25504446') {
          if(flag === SOM_FLAG) {
            this.isSOMFileValid = false;
            this._showError('somFile', CONSTANTS.ERROR_FILE_TYPE);
         } else if (flag === ACTION_BOX_FLAG) {
            this.isActionBoxFileValid = false;
            this._showError('actionBoxFile', CONSTANTS.ERROR_FILE_TYPE);
         }
       }
        this.validateForm();
     }
      fileReader.readAsArrayBuffer(file);
 }

  getCurrencyInfo() {
    this.route.params.subscribe(params => {
      let countryId = params['countryId'];
      // Not edit mode
      this.isLoading = false;
      this.countryService.getCountryInfo(countryId).subscribe(
        res => {
          this.isLoading = false;
          this.showInfo(res);
       },
        err => {
          this.isLoading = false;
          Bugsnag.notify(err);
       }
      );
   });
 }


  showInfo(info: any) {
    // Use setTimeout to get around angular model update bug
    setTimeout(() => {
      // Assign attributes
      this.currency = Object.assign({}, info);
      this.currentCurrency = Object.assign({}, info);

      if (this.currency.som) {
        $('[name="download"]').removeClass('disabled');
        $('[name="clearSom"]').removeClass('disabled');
     }
      if (this.currency.actionBox) {
        $('[name="actionBoxDownload"]').removeClass('disabled');
        $('[name="clearActionBox"]').removeClass('disabled');
     }
      if (this.currency.lms) {
        $('[name="openLink"]').removeClass('disabled');
        $('[name="clearLms"]').removeClass('disabled');
     }
      this.isLoading = false;
   }, 0);
 }

  radioServiceSelection(status: number) {
    this.currency.salesDashboard = (status == 1);
    this.validateForm();
 }

  validateForm() {
    this.isFormValid = JSON.stringify(this.currentCurrency) !== JSON.stringify(this.currency)
      && this.isSOMFileValid && this.isActionBoxFileValid && this.isLMSValid && this.isCountryValid && this.isCurrencyValid && this.isRateValid;
 }

  submitForm() {
    // No error default state, form's valid
    this.validateForm();
    if (!this.isFormValid) return;
    this.updateCurrency();
 }

  updateCurrency() {
    this.isSendingForm = true;

    if(this.currency.somFile) {this.currency.uploadSom = true;}
    if(this.currency.actionBoxFile) {this.currency.uploadActionBox = true;}

    this.countryService.updateCountry(this.currency.countryId, this.currency).subscribe(
      res => {
        if (res.updated === 0) {
          this.currency.uploadSom = false;
          this.currency.uploadActionBox = false;
          if (this.currency.somFile) {
            this.countryService.uploadFileCMToS3Bucket(res.somUrl, this.currency.somFile).subscribe(
              res1 => {
             },
              res1f => {
                if (res1f.status === 200) {
                  this.notificationsService.success(
                    CONSTANTS.SUCCESS,
                    CONSTANTS.UPLOAD_FILE_1 +` ${this.currency.somFileName}` + CONSTANTS.UPLOAD_FILE_2,
                    {
                      timeOut: 2500,
                      showProgressBar: true,
                      pauseOnHover: true,
                      clickToClose: true
                   }
                  );
               } else {
                  this.notificationsService.error(
                    CONSTANTS.ERROR,
                    CONSTANTS.UPLOAD_FILE_ERROR +` ${this.currency.somFileName}`+ CONSTANTS.UPLOAD_FILE_ERROR_1 +` ${res1f.error.error}`,
                    {
                      timeOut: 2500,
                      showProgressBar: true,
                      pauseOnHover: true,
                      clickToClose: true
                   }
                  );
               }
             }
            );
         }
          if (this.currency.actionBoxFile) {
            this.countryService.uploadFileCMToS3Bucket(res.actionBoxUrl, this.currency.actionBoxFile).subscribe(
              res2 => {
             },
              res2f => {
                if (res2f.status === 200) {
                  this.notificationsService.success(
                    CONSTANTS.SUCCESS,
                    CONSTANTS.UPLOAD_FILE_1 +`${this.currency.actionBoxFileName} `+CONSTANTS.UPLOAD_FILE_2,
                    {
                      timeOut: 2500,
                      showProgressBar: true,
                      pauseOnHover: true,
                      clickToClose: true
                   }
                  );
               } else {
                  this.notificationsService.error(
                    CONSTANTS.ERROR,
                    CONSTANTS.UPLOAD_FILE_ERROR + ` ${this.currency.actionBoxFileName}`+ CONSTANTS.UPLOAD_FILE_ERROR_1 +`  ${res2f.error.error}`,
                    {
                      timeOut: 2500,
                      showProgressBar: true,
                      pauseOnHover: true,
                      clickToClose: true
                   }
                  );
               }
             }
            );
         }
          if (this.currency.uploadSom == false && this.currency.uploadActionBox == false) {
            if (res.somUrl) {
              this.currency.som = res.somUrl.split('?')[0];
           }
            if (res.actionBoxUrl) {
              this.currency.actionBox = res.actionBoxUrl.split('?')[0];
           }
            this.countryService.updateCountry(this.currency.countryId, this.currency).subscribe(
              response => {
                if (response.updated == 1) {
                  this.notificationsService.success(
                    CONSTANTS.SUCCESS,
                    CONSTANTS.SUCCESS_MSG,
                    {
                      timeOut: 5000,
                      showProgressBar: true,
                      pauseOnHover: true,
                      clickToClose: true
                   }
                  );
                  this.router.navigate(['/management'], {queryParams: {t: 'country'}});
                  this.isSendingForm = false;
               }
             },
              err => {
                this.notificationsService.error(
                  CONSTANTS.ERROR,
                  CONSTANTS.ERROR_MSG + ` ${JSON.stringify(err)}`,
                  {
                    timeOut: 5000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                 }
                );
                this.isSendingForm = false;
                Bugsnag.notify(err);
             }
            );
         }
       }
        if (res.updated == 1) {
          this.notificationsService.success(
            CONSTANTS.SUCCESS,
            CONSTANTS.SUCCESS_MSG,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
           }
          );
       }
        this.router.navigate(['/management'], {queryParams: {t: 'country'}});
        this.isSendingForm = false;
     },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          CONSTANTS.ERROR_MSG +`${JSON.stringify(err)}`,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isSendingForm = false;
        Bugsnag.notify(err);
     }
    );
 }

  cancelForm() {
    this.router.navigate(['/management'], {queryParams: {t: 'country'}});
 }

  private _validateRequire(inputName: string, message: string) {
    this._clearError(inputName);
    let content = $("[name='" + inputName + "']").val();
    if (!content || !content.trim()) {
      this._showError(inputName, message);
      if(inputName === 'countryName') {
        this.isCountryValid = false;
     } else if (inputName === 'currencyName') {
        this.isCurrencyValid = false;
     }
      else if (inputName === 'exchangeRate') {
        this.isRateValid = false;
     }
   }
    this.validateForm();
 }

  private _validateUrl(inputName: string, message: string) {
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    let content = $("[name='" + inputName + "']").val();
    if (content && content.trim() && !content.match(regex)) {
      this._showError(inputName, message);
      this.isLMSValid = false;
   }
    this.validateForm();
 }

  private _validateUrlLength(inputName: string, message: string) {
    let content = $("[name='" + inputName + "']").val();
    if (content && content.trim() && content.length > 255) {
      this._showError(inputName, message);
      this.isLMSValid = false;
   }
    this.validateForm();
 }

  private _validateFileSize(inputName: string, message: string) {
    if (inputName === 'somFile' && this.currency.somFile && this.currency.somFile.size > 50000000) {
      this._showError(inputName, message);
      this.isSOMFileValid = false;
   }
    if (inputName === 'actionBoxFile' && this.currency.actionBoxFile && this.currency.actionBoxFile.size > 50000000) {
      this._showError(inputName, message);
      this.isActionBoxFileValid = false;
   }
    this.validateForm();
 }

  //Clear error message under input
  private _clearError(inputName: string) {
    let inputElement = $("[name ='" + inputName + "']");
    inputElement.removeClass('input-error');
    $("div.text-error[for='" + inputName + "']").hide();
 }

  // Show error message under input
  private _showError(inputName: string, message: string) {
    let inputElement = $("[name ='" + inputName + "']");
    inputElement.addClass('input-error');
    $("div.text-error[for='" + inputName + "']")
      .html(message)
      .show();
 }
}
