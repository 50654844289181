import {Component, OnInit} from '@angular/core';
import {CONSTANTS} from 'src/app/common/constants';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'expired-password-modal',
    templateUrl: './expired-password-modal.component.html',
    styleUrls: ['./expired-password-modal.component.css']

})
export class ExpiredPasswordModal implements OnInit {

    ok: string = CONSTANTS.OK;

    constructor(
        public translate: TranslateService) {
        this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
        // Set default language
        this.translate.setDefaultLang('en-SG');
    }

    ngOnInit() {}

    show(header: string, body: string, onOkClick?: any) {
        let expiredPasswordModal = $('#expiredPasswordModal');
        $('#expiredPasswordModal').modal('show');
        $('#expiredPasswordModal').find('.message-header').html(header);
        $('#expiredPasswordModal').find('.message-content').html(body);
        let modalOKButton = expiredPasswordModal.find('#modalOKButton');
        if (onOkClick) modalOKButton.click(onOkClick);
   }

    close() {
        $('#expiredPasswordModal').modal('hide');
   }
}
