import {Injectable} from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import {AuthService} from 'src/app/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    const oAuth2SignedIn = route.queryParams['oAuth2SignedIn'];
    return this.checkLogin(url, oAuth2SignedIn);
 }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
 }

  checkLogin(url: string, oAuth2SignedIn: string): boolean {
    if (this.authService.isLoggedIn || oAuth2SignedIn) {
      return true;
   }
    this.authService.redirectUrl = url;
    this.router.navigate(['/login']);
    return false;
 }
}
