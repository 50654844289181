import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';

@Injectable()
export class OrderService {
  constructor(private http: HttpClient, private utilService: UtilService, protected authService: AuthService) {}

  queryOrders(storeCode: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.ORDER_QUERY + storeCode, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.handleError));
 }

  getEcomToken(ecomUrl: string, ba: any): Observable<any> {
    const ecomTokenUrl = ecomUrl + '/api/auth/v1/token';
    const flow = 'app';
    const grant_type = 'ba_token';

    let params = {
      grant_type,
      flow
   };

    return this.http
      .post(ecomTokenUrl, params, {
        headers:{
          'Content-Type': 'application/json', 
          'X-App-Platform': 'ba_mobileapp_ios',
          'X-App-Version': '1.0.0',
          'X-Site-Country': this.utilService.getIso639CodeShort(ba.location),
          'Accept-Language': this.utilService.getIso639CodeFromCountryName(ba.location),
          'X-Token': ba.baToken
       }, 
        observe: 'response', 
        responseType: 'json'}
      ).pipe(map(this.utilService.extractData),
      catchError(this.handleError));
 }

  getOrders(ecomOrderUrl: string, location: string, ecomToken: any): Observable<any> {
    return this.http
      .get(ecomOrderUrl, {
        headers:{
          'Content-Type': 'application/json', 
          'X-App-Platform': 'ba_mobileapp_ios',
          'X-App-Version': '1.0.0',
          'X-Site-Country': this.utilService.getIso639CodeShort(location),
          'Accept-Language': this.utilService.getIso639CodeFromCountryName(location),
          'Authorization': ecomToken.token_type + ' ' + ecomToken.access_token
       }, 
        observe: 'response', 
        responseType: 'json'}
      ).pipe(map(this.utilService.extractData),
      catchError(this.handleError));
 }

  handleError(error: any) {
    return throwError(error);
 }
}
