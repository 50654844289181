import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {NotificationsService} from 'angular2-notifications';
import Bugsnag from '@bugsnag/js';

import {AuthService} from 'src/app/services/auth.service'
import {CONSTANTS} from 'src/app/common/constants';
import { Languages } from 'src/app/common/languages';
import {
    DefaultLangChangeEvent,
    LangChangeEvent,
    TranslateService,
    TranslationChangeEvent
  } from '@ngx-translate/core';


// Jquery
declare var $: any;

@Component({
    selector: 'navbar-component',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
    showBackButton: boolean = false;
    sephoraBAUsrManagement: string = CONSTANTS.SEPHORA_BA_USR_MANAGE;
    logoutText: string = CONSTANTS.LOGOUT;
    localLanguages = new Languages().language;
    selectedLanguage = "en-SG";
    siteLanguage = 'English';

    languageList = this.localLanguages;
    constructor(
        protected authService: AuthService,
        private router: Router,
        private notificationsService: NotificationsService,
        private cookieService: CookieService,
        private translate: TranslateService) {
        this.translate.onLangChange
            .subscribe((event: LangChangeEvent) => {
            localStorage.setItem("selectedLanguage", event.lang);
            });
    
        this.translate.onTranslationChange
            .subscribe((event: TranslationChangeEvent) => {
            });
    
        this.translate.onDefaultLangChange
            .subscribe((event: DefaultLangChangeEvent) => {
            });
        router.events.subscribe((val) => {
            if (val.toString().includes('management')) {
                this.showBackButton = false;
           }else{
                this.showBackButton = true;
           }
       });
   }

    ngOnInit() {
        localStorage.setItem("selectedLanguage", this.selectedLanguage);       
   }

    gotoProfile() {
        let id = this.authService.id;
        this.router.navigate(['/user/view/' + id]);
   }

    getParam(){
        let content = window.location.href;
        if (content.includes('store')) {
            return 'store';
       }else if (content.includes('terminal')){
            return 'terminal';
       }else if (content.includes('resource')){
            return 'resource';
        }else if (content.includes('audit')){
            return 'customer';
       }else if (content.includes('country')){
            return 'country';
       }else if (content.includes('order')){
            return 'oobaorder';
       } else {
           return 'user'
       }
   }

    gotoMain() {
        let content = window.location.href;
        if (content.includes('store')) {
            this.router.navigate(['/management'], {queryParams: {t: 'store'}});
       } else {
            this.router.navigate(['/management']);
       }
   }

    logout() {
        this.authService
            .logout()
            .subscribe(res => {
                this.cookieService.delete('remember');
                localStorage.clear();
                if (!this.authService.isOAuth2Authenticate) {
                    this.router.navigate(['/login']);
               }
           }, err => {
                this.notificationsService.error(
                    "Error!",
                    err.status === 500? err.error : err.statusText,
                    {
                    timeOut: 5000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                    }
                );
                Bugsnag.notify(err);
                if (!this.authService.isOAuth2Authenticate) {
                    this.router.navigate(['/login']);
                }
           }
        );
        if (this.authService.isOAuth2Authenticate) {
            this.authService
            .logoutOAuth2()
            .subscribe(
            response => {
                let logoutUrl = this.authService.oktaLogoutUrl + '?id_token_hint=' + this.authService.oktaIdToken + '&post_logout_redirect_uri=' + window.location.origin;
                window.location.href = logoutUrl;
           },
           err => {
            this.notificationsService.error(
                "Error!",
                err.status === 500? err.error : err.statusText,
                {
                  timeOut: 5000,
                  showProgressBar: true,
                  pauseOnHover: true,
                  clickToClose: true
                }
            );
            Bugsnag.notify(err);
            this.router.navigate(['/login']);
       });
       }
   }

   changeLanguage(){
    alert($('#v div li a').attr('id'));;
    this.selectedLanguage = $('#selectedLanguage div li a').attr('id');
    localStorage.setItem("selectedLanguage", this.selectedLanguage);
    this.translateLanguageTo(this.selectedLanguage);
    }
    //Switch language
    translateLanguageTo(lang: string) {
        $('#languageOpted').text(lang);
        this.selectedLanguage = lang;
        this.translate.use(lang);
    }

  changeSiteLanguage(localeCode: string): void {
    this.selectedLanguage = localeCode;
    localStorage.setItem("selectedLanguage", this.selectedLanguage);
    const selectedLanguage = this.languageList
      .find((language) => language.code === localeCode)
      ?.label.toString();
    if (selectedLanguage) {
      this.siteLanguage = selectedLanguage;
      this.translate.use(localeCode);
    }
  }
}