import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';

@Injectable()
export class TerminalService {
  constructor(private http: HttpClient, private utilService: UtilService, protected authService: AuthService) {}

  filterTerminals(
    page: number,
    limit: number,
    keywordSearch: string,
    filterTerminalCode: string,
    filterModel: string,
    filterStoreName: string,
    filterState: string
  ): Observable<any> {
    let state = '';
    if (filterState.toUpperCase() === 'Active'.toUpperCase()) {
      state = '1';
    } else if (filterState.toUpperCase() === 'Inactive'.toUpperCase()) {
      state = '0';
    } else {
      state = filterState;
    }
    let params = {
      offset: (page - 1) * limit,
      limit,
      keywordSearch,
      filterTerminalCode,
      filterModel,
      filterStoreName,
      filterState: state
   };
    localStorage.setItem('pageNum', page + '');
    localStorage.setItem('limitNum', limit + '')
    localStorage.setItem('keywordSearch', keywordSearch);
    localStorage.setItem('filter1', filterTerminalCode);
    localStorage.setItem('filter2', filterModel);
    localStorage.setItem('filter3', filterStoreName);
    localStorage.setItem('filter4', filterState);
    return this.http
      .post(ENDPOINTS.TERMINAL_FILTER, params, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  getTerminals(): Observable<any> {
    return this.http
      .get(ENDPOINTS.TERMINAL, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  createTerminal(data: any): Observable<any> {
    return this.http
      .put(ENDPOINTS.TERMINAL, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  updateTerminal(data: any): Observable<any> {
    return this.http
      .post(ENDPOINTS.TERMINAL, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  getOneTerminalByCode(terminalCode: string): Observable<any> {
    let data = {"terminalCode": terminalCode};
    return this.http
      .post(ENDPOINTS.TERMINAL_CODE, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  getOneTerminal(terminalId: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.TERMINAL_BY + terminalId, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }

  deleteTerminal(terminalId: number): Observable<any> {
    return this.http
      .delete(ENDPOINTS.TERMINAL_BY + terminalId, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

}
