import {NgModule, ErrorHandler} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {APP_BASE_HREF} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import Bugsnag from '@bugsnag/js';

import {environment} from 'src/environments/environment';
import {CONSTANTS} from 'src/app/common/constants';
import {AppRoutingModule} from 'src/app/app-routing.module';
import {AppComponent} from 'src/app/app.component';
import {ExpiredPasswordModal} from 'src/app/components/modal/expired-password-modal/expired-password-modal.component';
import {ChangePasswordModalComponent} from 'src/app/components/modal/change-password-modal/change-password-modal.component';
import {MessageModalComponent} from 'src/app/components/modal/message-modal/message-modal.component';
import {NavbarComponent} from 'src/app/components/navbar/navbar.component';
import {LoginComponent} from 'src/app/components/login/login.component';
import {ChangeWebPassComponent} from 'src/app/components/changewebpass/changewebpass.component';
import {PasswordComponent} from 'src/app/components/password/password.component';
import {UserComponent} from 'src/app/components/user/user.component';
import {MainComponent} from 'src/app/components/main/main.component';
import {StoreComponent} from 'src/app/components/store/store.component';
import {TerminalComponent} from 'src/app/components/terminal/terminal.component';
import {ResourceComponent} from 'src/app/components/resource/resource.component';
import {CountryComponent} from 'src/app/components/country/country.component';
import {AuditComponent} from 'src/app/components/audit/audit.component';
import {OrderComponent} from 'src/app/components/order/order.component';
import {ResourceManagementComponent} from 'src/app/components/resource-management/resource-management.component';
import {BookingComponent} from 'src/app/components/booking/booking.component';
import {AuthService} from 'src/app/services/auth.service';
import {AuthGuard} from 'src/app/services/auth-guard.service';
import {UserService} from 'src/app/services/user.service';
import {StoreService} from 'src/app/services/store.service';
import {UtilService} from 'src/app/services/util.service';
import {AuditService} from 'src/app/services/audit.service';
import {TerminalService} from 'src/app/services/terminal.service';
import {TerminalModelService} from 'src/app/services/terminal-model.service';
import {CountryService} from 'src/app/services/country.service';
import {OrderService} from 'src/app/services/ooba-order.service';
import { ResourceService } from './services/resource.service';
import { BookingService } from './services/booking.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from './translation.config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxTranslateModule } from './translate/translate.module';
import { FullCalendarModule } from '@fullcalendar/angular'; // FullCalendar module


Bugsnag.start({
  apiKey: CONSTANTS.BUGSNAG_API_KEY,
  releaseStage: environment.releaseStage
});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

// Factory function required during AOT compilation
export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    ExpiredPasswordModal,
    ChangePasswordModalComponent,
    MessageModalComponent,
    NavbarComponent,
    LoginComponent,
    ChangeWebPassComponent,
    PasswordComponent,
    UserComponent,
    MainComponent,
    CountryComponent,
    StoreComponent,
    TerminalComponent,
    ResourceComponent,
    AuditComponent,
    OrderComponent,
    ResourceManagementComponent,
    BookingComponent
  ],
  imports: [
    BrowserModule,
    FullCalendarModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    NgxTranslateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    })
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    AuthService,
    AuthGuard,
    UserService,
    StoreService,
    UtilService,
    AuditService,
    TerminalService,
    TerminalModelService,
    CountryService,
    OrderService,
    ResourceService,
    BookingService,
    {provide: ErrorHandler, useFactory: errorHandlerFactory}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
