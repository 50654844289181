export class Languages {
    language = [
        {

            code: "en-SG",
            label: "English"
        },
        {
            code: "th-TH",
            label: "Thai"
        },
        {
            code: "id-ID",
            label: "Bahasa Indonesia"
        },
        {
            code: "ko-KR",
            label: "Korean"
        }
    ];
};