<div class="sephora-wrapper">
    <div class="sephora-logo-container" [hidden]="isWebView || oAuth2MobileSignedIn || oAuth2MobileSignedOut">
        <img class="sephora-logo" src="assets/images/sephora-logo.png">
        <br>
           
    </div>
      <br>
    <div class="oAuth2Signin" [hidden]="!isWebView || oAuth2MobileSignedIn || oAuth2MobileSignedOut"><a href="/oauth2/authorization/okta?device=mobile">{{loginOkta ? ('LOGIN_OKTA'| translate) : loginOkta}}</a></div>

    <div class="sephora-title"  [hidden]="isWebView || oAuth2MobileSignedIn || oAuth2MobileSignedOut">
        {{sephoraTitle ? ('TITLE'| translate) : sephoraTitle}}
        <div class="btn-group sephora-language" dropdown >
          <button id="button-animated" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-animated">
            <i class="fa fa-globe" aria-hidden="true"></i> {{siteLanguage}}
            <span class="caret"></span>
          </button>
          <ul
            id="dropdown-animated"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu" style="text-align: center;"
            aria-labelledby="dropdownMenuButton">
            <ng-container *ngFor="let language of languageList">
              <li role="menuitem">
                <a class="dropdown-item" (click)="changeSiteLanguage(language.code)">
                  {{ language.label }}
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
    </div>
   
    <div class="login-group-wrapper" [hidden]="isWebView || oAuth2MobileSignedIn || oAuth2MobileSignedOut">
       
      <div *ngIf="isLoading" class="loading-spinner">
        <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg" />
      </div>
        <form>
            <div class="login-group" (keydown)="handleKeyDown($event)">
                <div class="sephora-welcome">
                    {{welcomeTitle ? ('WELCOME_TITLE'| translate) : welcomeTitle}}
                </div>
                <div *ngIf="isOktaLoginOnly">
                  <button type="button" i18n="@@loginOkta" class="okta-login-button" (click)="oktaAuthenticate()">{{loginOkta ? ('LOGIN_OKTA' | translate) : loginOkta}}</button>
                </div>
                <div *ngIf="!isOktaLoginOnly" style="position: relative">
                  <div style="position: relative">
                      <input i18n="@@username" id="username" name="username" class="login-input" type="text" maxlength="255" spellcheck="false" [(ngModel)]="username"
                          required (focusout)="clearErrorIfValid($event)" autocomplete="username" />
                      <span class="floating-label">{{employeeId ? ('EMPLOYEE_ID'| translate) : employeeId}}</span>
                      <span class="underline"></span>
                      <span class="text-error">{{employeeIdEmpty ? ('CONSTANTS.EMPLOYEE_ID_EMPTY' | translate) : employeeIdEmpty}}</span>
                      <span class="text-error">{{employeeIdInvalid  ? ('CONSTANTS.EMPLOYEE_ID_INVALID' | translate) : employeeIdInvalid}}</span>
                  </div>
                  <div style="position: relative">
                      <input i18n="password" id="password" name="password" class="login-input" maxlength="20" type="password" spellcheck="false" [(ngModel)]="password" required
                          (focusout)="clearErrorIfValid($event)" autocomplete="password" />
                      <span class="floating-label">{{passwordText ? ('PASSWORD' | translate) : passwordText}}</span>
                      <span class="underline"></span>
                      <span class="text-error">{{passwordEmpty ? ('PASSWORD_EMPTY' | translate) : passwordEmpty}}</span>
                      <span class="text-error">{{passwordInvalid ? ('PASSWORD_INVALID' | translate) : passwordInvalid}}</span>
                      <span class="text-error" style="top: 100px">{{serverError ? ('SERVER_ERROR' | translate) : serverError}}</span>
                      <span class="text-error" style="top: 100px">{{serverErrorConnectAdmin ? ('SERVER_ERROR_CONNECT_ADMIN' | translate) : serverErrorConnectAdmin}}</span>
                  </div>
                  <button type="button" i18n="@@LoginButton" class="login-button" (click)="authenticate()">{{login ? ('LOGIN' | translate) : login}}</button>
                  <div class="recoverPasswordText" i18n="@@forgotPassword" (click)="handleForgotPassword()">> {{forgotPassword ? ('FORGOT_PASSWORD' | translate) : forgotPassword}}</div>
                  <div class="oAuth2Signin" i18n="@@loginOkta">{{or}}<a href="/oauth2/authorization/okta">{{loginOkta ? ('LOGIN_OKTA' | translate) : loginOkta}}</a></div>
                </div>
            </div>
        </form>
    </div>
</div>
<expired-password-modal></expired-password-modal>