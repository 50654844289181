import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import Bugsnag from '@bugsnag/js';

import {CONSTANTS} from 'src/app/common/constants';
import {ICONS} from 'src/app/common/icons';

import {AuthService} from 'src/app/services/auth.service';
import {StoreService} from 'src/app/services/store.service';
import {UtilService} from 'src/app/services/util.service';
import {UserService} from 'src/app/services/user.service';
import {ResourceService} from 'src/app/services/resource.service';

import {MessageModalComponent} from 'src/app/components/modal/message-modal/message-modal.component';
import {ChangePasswordModalComponent} from 'src/app/components/modal/change-password-modal/change-password-modal.component';
import {PasswordValiationMessage} from 'src/app/models/password-validation-message';

import {UserForm} from 'src/app/models/user-form';
import {EditHistory} from 'src/app/models/edit-history';
import {CODINGCONSTANTS} from 'src/app/common/coding-constants';
import { TranslateService } from '@ngx-translate/core';

// Jquery
declare var $: any;

@Component({
  selector: 'user-component',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit {
  readonly BA_ROLE = CODINGCONSTANTS.BA_ROLE;
  @ViewChild(MessageModalComponent)
  messageModal!: MessageModalComponent;
  @ViewChild(ChangePasswordModalComponent)
  changePasswordModal!: ChangePasswordModalComponent;

  // Model
  userForm: UserForm = {
    fullName: '',
    baLoginId: '',
    oldLoginId: '',
    email: '',
    location: '',
    baAppPassword: '',
    webPortalPassword: '',
    storeCode: '',
    storeEmail: '',
    storeCountry: '',
    editHistory: [],
    roleId: '',
    hasBaAppPassword: true,
    hasWebPortalPassword: true,
    activityGroupIds: []
 };
  baAppPasswordConfirm: string = '';
  webPortalPasswordConfirm: string = '';

  userId: string = '';
  baLoginId: string = '';
  locations: string[] = [];
  stores: any = [];
  allStores: any = [];
  currentUserRoleId: string = '';
  allowViewRoles: any = [];
  allowEditRoles: any = [];
  roles: any = [];
  editHistoriesText: string = '';

  defaultSelectOption: string = CONSTANTS.SELECT_OPTION;
  isFormValid: boolean = false;
  isConflictUser: boolean = false;
  isEditMode: boolean = false;
  isViewMode: boolean = false;
  isLoading: boolean = true;
  employeeIdEditable: boolean = false;
  isTargetRetailOpsOrSA: boolean = false;
  isTargetBA: boolean = false;
  areTargetBrandRoles: boolean = false;

  activityGroups: any[] = [];
  multiselectPlaceHolder: string = '';
  multiselectAll: string = '';
  multisunelectAll: string = '';
  activityGroupDropdownList: any[] = [];
  selectedActivityGroups: any[] = [];
  activityGroupDropdownSettings: IDropdownSettings = {};

  currentEditUser!: UserForm;
  oldRole: string = '';
  userIdNeedEdit: string = '';
  appPasswordValidationMessages: Array<PasswordValiationMessage> = [];
  webPasswordValidationMessages: Array<PasswordValiationMessage> = [];
  showMessages_webPortalPassword = false;
  showMessages_baAppPassword = false;
  validationMessages = '';
  userManagement: string = CONSTANTS.USER_MANAGEMENT;
  role: string = CONSTANTS.ROLE;
  fullName: string = CONSTANTS.FULL_NAME;
  employeeId: string = CONSTANTS.EMPLOYEE_ID;
  oldEmployeeId: string = CONSTANTS.OLD_EMPLOYEE_ID;
  baAppPassword: string = CONSTANTS.BA_APP_PASSWORD;
  change: string = CONSTANTS.CHANGE;
  baAppPasswordConfirmText: string = CONSTANTS.BA_APP_PASSWORD_CONFIRM;
  webPortalPassword: string = CONSTANTS.WEB_PORTAL_PASSWORD;
  webPortalPasswordConfirmText: string = CONSTANTS.WEB_PORTAL_PASSWORD_CONFIRM;
  locationText: string = CONSTANTS.LOCATION;
  storeName: string = CONSTANTS.STORE_NAME;
  storeEmail: string = CONSTANTS.STORE_EMAIL;
  email: string = CONSTANTS.EMAIL;
  editHistory: string = CONSTANTS.EDIT_HISTORY;
  createUserText: string = CONSTANTS.CREATE_USER;
  save: string = CONSTANTS.SAVE;
  cancel: string = CONSTANTS.CANCEL;
  close: string = CONSTANTS.CLOSE;
  deleteUserText: string = CONSTANTS.DELETE_USER;
  passwordNotMatch: string = CONSTANTS.PASSWORD_NOT_MATCH;
  activityGroupLabel: string = CONSTANTS.ACTIVITY_GROUP_LABEL;
  hasChanges: boolean = false;

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private notificationsService: NotificationsService,
    protected authService: AuthService,
    private utilService: UtilService,
    private userService: UserService,
    private resourceService: ResourceService,
    public translate: TranslateService
    ) {
    this.translate.setDefaultLang('en-SG');
  }

  ngOnInit() {
    this.currentUserRoleId = localStorage.getItem('currentUserRoleId') as string;
    this.allowViewRoles = JSON.parse(localStorage.getItem('allowViewRoles') as any);
    this.allowEditRoles = JSON.parse(localStorage.getItem('allowEditRoles') as any);
    this.multiselectPlaceHolder = this.translate.instant(CONSTANTS.SELECT_OPTIONS);
    this.multiselectAll = this.translate.instant(CONSTANTS.SELECT_ALL);
    this.multisunelectAll = this.translate.instant(CONSTANTS.UNSELECT_ALL);
    this.fetchActivityGroups();
    this.activityGroupDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'activityGroup',
      selectAllText: this.multiselectAll,
      unSelectAllText: this.multisunelectAll,
      allowSearchFilter: true
    };

    this.isLoading = true;
    this.storeService.getStores().subscribe(
      res => {
        this.isLoading = false;
        let authorities: any = [];
        try {
          authorities = [].concat(this.authService.authorities as any);
        } catch (err: any) {
          authorities = [{'authority' : null}];
          Bugsnag.notify(err);
        }

        let role = this.allowViewRoles.find((it: any) => it.name == authorities[0].authority);

        switch (role.name) {
          case CODINGCONSTANTS.SM_ROLE: case CODINGCONSTANTS.SMT_ROLE: {//SMT, SM
            this.stores = res.filter((it: any) => it.storeCode == this.authService.storeCode);
            this.locations = [this.authService.location];
            break;
          }
         case CODINGCONSTANTS.RO_ROLE: case CODINGCONSTANTS.BM_ROLE: {//Retail ops, Brand Admin
            this.stores = res.filter((it: any) => it.storeCountry == this.authService.location);
            this.locations = [this.authService.location];
            break;
          }
          case CODINGCONSTANTS.SA_ROLE: {//System admin
            this.stores = res;
            this.allStores = res;
            this.locations = this.authService.countries;
            break;
          }
        }

        // Check if mode is UPDATE
        this.checkIfEditMode();
     },
      err => {
        Bugsnag.notify(err);
        this.isLoading = false;
     }
    );
  }

  checkIfEditMode() {
    // Get path variables
    this.route.params.subscribe(params => {
      let userId = params['userId'];
      // New mode
      if (!userId) {
        this.title.setTitle(CONSTANTS.NEW_USER);
        this.roles = this.allowEditRoles;
        if (this.authService.isSystemAdmin) {
          this.roles = this.allowViewRoles;
          this.userForm.location = '';
       }
        if (this.authService.isRetailOps) {
          this.userForm.storeCountry = this.authService.storeCountry;
          this.userForm.location = this.authService.location;
       }
        if (this.authService.isSM || this.authService.isSMT) {
          this.userForm.storeCode = this.authService.storeCode;
          let currentStore = this.stores.find((it: any) => it.storeCode == this.userForm.storeCode);
          if (currentStore) {
            this.userForm.storeCountry = currentStore.storeCountry;
            this.userForm.storeEmail = currentStore.storeEmail;
         }
       }
        if (this.authService.isSMT) {
          this.userForm.roleId = this.allowEditRoles[0].id as string;
          this.isTargetBA = true;
       }
        if (this.authService.isBM) {
          this.userForm.storeCountry = this.authService.location;
        } 
        this.isLoading = false;
        return;
      }
      // Edit/View mode
      // If there's an ID provided, fill forms with corresponding user data
      if (userId) {
        this.userId = userId;
        if(this.router.url.includes('edit')) {
          this.title.setTitle(CONSTANTS.EDIT_USER);
          this.roles = this.allowEditRoles;
          if (this.authService.isSystemAdmin || this.authService.id === userId) {
            this.roles = this.allowViewRoles;
         }
          this.isEditMode = true;
       } else if (this.router.url.includes('view')) {
          this.title.setTitle(CONSTANTS.VIEW_USER);
          this.roles = this.allowViewRoles;
          this.isViewMode = true;
       }
        this.isLoading = true;
        this.userService.getOneUser(userId).subscribe(
          res => {
            this.setUpUserInfo(res);
            this.setEditHistories(res);
            this.isLoading = false;
         },
          err => {
            Bugsnag.notify(err);
            this.isLoading = false;
         }
        );
     }
   });
  }

  setUpUserInfo(info: any) {
    // Use setTimeout to get around angular model update bug
    setTimeout(() => {
      // Assign attributes
      this.userForm = Object.assign({}, info);
      this.currentEditUser = Object.assign({}, info);
      this.userForm.hasBaAppPassword = false;
      this.userForm.hasWebPortalPassword = false;

      let role = this.allowViewRoles.find((r: any) => r.id == this.userForm.roleId);
      if (this.authService.isSystemAdmin) {
        this.employeeIdEditable = true;
      } else if (this.authService.isRetailOps && role.name !== CODINGCONSTANTS.RO_ROLE) {
        this.employeeIdEditable = true;
      }

      if ([CODINGCONSTANTS.RO_ROLE, CODINGCONSTANTS.SA_ROLE].indexOf(role.name) >= 0) {
        this.isTargetRetailOpsOrSA = true;
      } else {
        if (CODINGCONSTANTS.BA_ROLE === role.name) {
          this.isTargetBA = true;
        } else if ([CODINGCONSTANTS.BM_ROLE, CODINGCONSTANTS.BR_ROLE].indexOf(role.name) >= 0) {
          this.areTargetBrandRoles = true;
          this.userForm.activityGroupIds = (info['activityGroupIds']).length ? info['activityGroupIds'] : [];
          this.currentEditUser.activityGroupIds = (info['activityGroupIds']).length ? info['activityGroupIds'] : [];
          this.selectedActivityGroups = this.activityGroupDropdownList.filter((it: any) => this.userForm.activityGroupIds.includes(it.id as number));
        }
      }

      if (this.isEditMode && this.authService.isSystemAdmin) {
          this.stores = this.allStores.filter((it: any) => it.storeCountry == this.userForm.storeCountry);
      }

      this.isLoading = false;
   }, 0);
  }

  /** Set up audit trail content */
  setEditHistories(res: any) {
    this.editHistoriesText = ''
    let editHistories: EditHistory[];
    editHistories = res['editHistory'];
    let size = editHistories.length;
    for (let i = size - 1; i >= 0; i--) {
      let history = editHistories[i];
      let time = this.utilService.formatDateTime(history.updateTimeStamp);
      //let time = history.updateTimeStamp;
      switch (history.type) {
        case 0:
          if (i + 1 === size) {
            this.editHistoriesText += 'Edited on <' + time + '> by ' + history.updateUserFullName + ' (' + history.updateUserBAId + '):\n';
         } else if (editHistories[i].updateTimeStamp !== editHistories[i + 1].updateTimeStamp) {
            this.editHistoriesText += '-------\n';
            this.editHistoriesText += 'Edited on <' + time + '> by ' + history.updateUserFullName + ' (' + history.updateUserBAId + '):\n';
         }

          if (history.oldValue === null) {
            history.oldValue = `''`;
         }
          if (history.newValue === null) {
            history.newValue = `''`;
         }
          this.editHistoriesText += history.fieldName + ': ' + history.oldValue + ' changed to ' + history.newValue + '.\n';
          break;
        case 1:
          if (i + 1 !== size) {
            this.editHistoriesText += '-------\n';
         }
          this.editHistoriesText += 'BA App Password changed at ' + time + ' by ' + history.updateUserFullName + ' (' + history.updateUserBAId + ').\n';
          break;
        case 2:
          if (i + 1 !== size) {
            this.editHistoriesText += '-------\n';
         }
          this.editHistoriesText += 'Web Portal Password changed at ' + time + ' by ' + history.updateUserFullName + ' (' + history.updateUserBAId + ').\n';
          break;
        case 3:
          if (i + 1 !== size) {
            this.editHistoriesText += '-------\n';
         }
          this.editHistoriesText += 'Password updated via function “Recover Password” on ' + time + '.\n';
          break;
        case 4:
          if (i + 1 !== size) {
            this.editHistoriesText += '-------\n';
         }
          this.editHistoriesText += 'Created on ' + time + ' by ' + history.updateUserFullName + ' (' + history.updateUserBAId + ').\n';
          break;
        default:
          break;
     }
   }
 }

 onSelectRole(event: any) {
  this.isTargetRetailOpsOrSA = false;
  this.areTargetBrandRoles = false;
  this.isTargetBA = false;
  if ((event.target as HTMLInputElement).value) {
    let role = this.allowViewRoles.find((it: any) => it.id === (event.target as HTMLInputElement).value);
    if (role) {
      this.userForm.hasWebPortalPassword = true;

      if (this.authService.isRetailOps || this.authService.isBM) {
        this.userForm.storeCountry = this.authService.location;
      }

      if (role.name === CODINGCONSTANTS.BA_ROLE) {
        this.isTargetBA = true;
        this._clearError('webPortalPassword');
        this._clearErrorPw('webPortalPassword');
        this.userForm.hasWebPortalPassword = false;
      } else if ([CODINGCONSTANTS.BM_ROLE, CODINGCONSTANTS.BR_ROLE].includes(role.name)) {
        this.areTargetBrandRoles = true;
        this._clearError('baAppPassword');
        this._clearErrorPw('baAppPassword');
        this._clearError('webPortalPassword');
        this._clearErrorPw('webPortalPassword');
        this.userForm.hasBaAppPassword = false;
        this.userForm.hasWebPortalPassword = false;
        if (this.authService.isBM && !this.isEditMode && role.name === CODINGCONSTANTS.BR_ROLE) {
          this.userService.getOneUser(this.authService.id).subscribe(
            res => {
              this.userForm.activityGroupIds = res.activityGroupIds;
              this.selectedActivityGroups = this.activityGroupDropdownList.filter((it: any) => this.userForm.activityGroupIds.includes(it.id as number));
           },
            err => {
              Bugsnag.notify(err);
           }
          );
        }
      } else if (role.name === CODINGCONSTANTS.SA_ROLE || role.name === CODINGCONSTANTS.RO_ROLE) {
        this.isTargetRetailOpsOrSA = true;
        this.userForm.storeCountry = '';
      }

      if (this.isEditMode) {
        this.oldRole = this.allowViewRoles.find((it: any) => it.id == this.currentEditUser.roleId).name;
        if(this.oldRole === CODINGCONSTANTS.BA_ROLE) {
          this.userForm.webPortalPassword = '';
        }
        if ([CODINGCONSTANTS.BM_ROLE, CODINGCONSTANTS.BR_ROLE].includes(this.oldRole)) {
          this.userForm.webPortalPassword = '';
          this.userForm.baAppPassword = '';
        }
        if (this.isTargetRetailOpsOrSA) {
          this.userForm.storeCode = this.userForm.storeCode? this.userForm.storeCode : this.currentEditUser.storeCode;
          this.userForm.storeEmail = this.userForm.storeEmail? this.userForm.storeEmail :  this.currentEditUser.storeEmail;
          this.userForm.location = this.userForm.location? this.userForm.location : this.currentEditUser.location? this.currentEditUser.location : this.currentEditUser.storeCountry;
        } else if (this.areTargetBrandRoles) {
          this.userForm.storeCountry = this.userForm.storeCountry ? this.userForm.storeCountry : this.currentEditUser.storeCountry? this.currentEditUser.storeCountry : this.currentEditUser.location;
        } else {
          this.userForm.storeCode = this.userForm.storeCode? this.userForm.storeCode : this.currentEditUser.storeCode;
          this.userForm.storeCountry = this.userForm.storeCountry ? this.userForm.storeCountry : this.currentEditUser.storeCountry? this.currentEditUser.storeCountry : this.currentEditUser.location;
          this.userForm.storeEmail = this.userForm.storeEmail? this.userForm.storeEmail :  this.currentEditUser.storeEmail;
        }
      }
    }
  }
 }

  validateEmployeeIdOnInput() {
    let currentStore;
    if (!this.isTargetRetailOpsOrSA && !this.areTargetBrandRoles) {
      currentStore = this.stores.find((it: any) => it.storeCode == this.userForm.storeCode);
      if (currentStore) {
        this.validateEmployeeId(currentStore.storeCountry);
      }
    }
 }

  validateEmployeeId(storeCountry: String) {
    switch (storeCountry) {
      case 'Australia':
        this._validEmployeeIDForCountry(/^AUS[0-9]{6}$/, storeCountry);
        break;
      case 'New Zealand':
        this._validEmployeeIDForCountry(/^NZL1[0-9]{5}$/, storeCountry);
        break;
      case 'Thailand':
        this._validEmployeeIDForCountry(/^THA00[1-9][0-9]{3}$/, storeCountry);
        break;
      case 'Malaysia':
        this._validEmployeeIDForCountry(/^MYS0[0-9]{5}$/, storeCountry);
        break;
      case 'Singapore':
        this._validEmployeeIDForCountry(/^SGP[0-9]{6}$/, storeCountry);
        break;
      case 'Hong Kong':
        this._validEmployeeIDForCountry(/^HKG852[0-9]{4}$/, storeCountry);
        break;
      case 'South Korea':
        this._validEmployeeIDForCountry(/^KOR[0-9]{6,7}$/, storeCountry);
        break;
      case 'Korea':
        this._validEmployeeIDForCountry(/^KOR[0-9]{6,7}$/, storeCountry);
        break;
      case 'Philippines':
        this._validEmployeeIDForCountry(/^PHP[0-9]{6}$/, storeCountry);
        break;
      case 'Indonesia':
        this._validEmployeeIDForCountry(/^IND[0-9]{6}$/, storeCountry);
        break;
      default:
   }
 }

  _validEmployeeIDForCountry(regex: any, storeCountry: String) {
    this._clearError('baLoginId');
    if (!this.userForm.baLoginId) {
      this.isFormValid = false;
      this._showError('baLoginId', CONSTANTS.ERROR_REQUIRE_TEXT);
    }

    if (!regex.test(this.userForm.baLoginId.toUpperCase()) && !/^[0-9]{6}$/.test(this.userForm.baLoginId)) {
      this.isFormValid = false;
      this._showError('baLoginId', this.showEmployeeIdError(storeCountry));
    }
 }

  showEmployeeIdError(storeCountry: String) {
    let error = CONSTANTS.EMPLOYEE_ID_INVALID;
    switch (storeCountry) {
      case 'Australia':
        error = CONSTANTS.AUS_EMP_ID_INVALID;
        break;
      case 'New Zealand':
        error = CONSTANTS.NZ_EMP_ID_INVALID;
        break;
      case 'Thailand':
        error = CONSTANTS.TH_EMP_ID_INVALID;
        break;
      case 'Malaysia':
        error = CONSTANTS.MY_EMP_ID_INVALID;
        break;
      case 'Singapore':
        error = CONSTANTS.SG_EMP_ID_INVALID;
        break;
      case 'Hong Kong':
        error = CONSTANTS.HK_EMP_ID_INVALID;
        break;
      case 'South Korea':
        error = CONSTANTS.KR_EMP_ID_INVALID;
        break;
      case 'Korea':
        error = CONSTANTS.KR_EMP_ID_INVALID;
        break;
      case 'Philippines':
        error = CONSTANTS.PH_EMP_ID_INVALID;
        break;
      case 'Indonesia':
        error = CONSTANTS.ID_EMP_ID_INVALID;
        break;
      default:
   }
    return error;
 }

  isUserExist() {
    if ((!this.isEditMode || this.userForm.baLoginId != this.currentEditUser.baLoginId)) {
      this.userService.getUserNumber(this.userForm.baLoginId).subscribe(num => {
        if (num) {
          this.isConflictUser = true;
          this._showError('baLoginId', CONSTANTS.USER_EXIST);
        } else {
          this.isConflictUser = false;
          this._clearError('baLoginId');
        }
      },
      err => {
        Bugsnag.notify(err);
     });
    } else if (this.isEditMode && this.userForm.baLoginId === this.currentEditUser.baLoginId) {
      this.isConflictUser = false;
      this._clearError('baLoginId');
    }
 }

  isUserExistByEmail() {
    if ((!this.isEditMode || this.userForm.email != this.currentEditUser.email)) {
      this.userService.getUserEmail(this.userForm.email).subscribe(res => {
        if (res) {
          this.isConflictUser = true;
          this._showError('email', CONSTANTS.USER_EXIST);
        } else {
          this.isConflictUser = false;
          this._clearError('email');
        }
      },
      err => {
        Bugsnag.notify(err);
     });
    } else if (this.isEditMode && this.userForm.email === this.currentEditUser.email) {
      this.isConflictUser = false;
      this._clearError('email');
    }
  }

  onSelectStore(event: any) {
    if ((event.target as HTMLInputElement).value) {
      this.userForm.storeEmail = (this.stores.find((str: any) => str.storeCode == this.userForm.storeCode)).storeEmail;
    } else {
      this.userForm.storeEmail = '';
    }
  }

  onSystemAdminSelectLocation(event: any) {
    const value = (event.target as HTMLInputElement).value;
    this.userForm.storeCode = '';
    this.userForm.storeEmail = '';
    this.userForm.storeCountry = value;
    this.stores = this.allStores.filter((str: any) => str.storeCountry == value);
 }

  validateSelectedActivityGroups(event: any) {
    if (!this.isViewMode) {
      const target = event.target as HTMLInputElement
      if (target) {
        $("div.text-error[for='activityGroupMultiSelect']").hide();
        if (!this.selectedActivityGroups.length) {
          this.isFormValid = false;
          $("div.text-error[for='activityGroupMultiSelect']").html(CONSTANTS.ERROR_REQUIRE_TEXT).show();
        }
      }
    }
  }

  private fetchActivityGroups() {
    this.isLoading = true;
    this.resourceService.fetchActivityGroups('').subscribe(
      res => {
        this.activityGroupDropdownList = res.body.activityGroups;
        if (this.userForm.activityGroupIds && this.userForm.activityGroupIds.length) {
          this.selectedActivityGroups = this.activityGroupDropdownList.filter((it: any) => this.userForm.activityGroupIds.includes(it.id as number));
        }
        this.isLoading = false;
     },
      err => {
        Bugsnag.notify(err);
        this.isLoading = false;
     }
    );
  }

  onActivityGroupSelect(item: any) {
    this.userForm.activityGroupIds.push(item.id);
  }

  onActivityGroupDeSelect(item: any) {
    this.userForm.activityGroupIds = this.userForm.activityGroupIds.filter(i => i != item.id);
  }

  onActivityGroupSelectAll(items: any) {
    this.userForm.activityGroupIds = this.activityGroupDropdownList.map((e: any) => {return e.id});
  }

  submitUserForm() {
    // No error default state, form's valid
    this.isFormValid = true;
    $('.text-error').hide();
    if (this.authService.isOAuth2Authenticate && !this.areTargetBrandRoles) {
      this.userForm.email = this.userForm.email.trim();
      this._validateRequire('email', CONSTANTS.ERROR_REQUIRE_TEXT);
      if (this.userForm.email) {
        this._validateEmail('email', this.userForm.email);
      }
    } else {
      // If not authenticated by Okta (baLoginId/password or external email)
      this.userForm.baLoginId = this.userForm.baLoginId.trim();
      this._validateRequire('baLoginId', CONSTANTS.ERROR_REQUIRE_TEXT);
      if (this.userForm.baLoginId) {
        this._validateEmployeeIdIncludeSpaces();
        if (!this.areTargetBrandRoles) {
          this.validateEmployeeIdOnInput();
          if (!this.isEditMode) {
          // If not authenticated by Okta 
              //then the ba app password is mandatory
              this._validateRequire('baAppPassword', CONSTANTS.ERROR_REQUIRE_TEXT);
              if (this.userForm.baAppPassword) {
                this._confirmBaAppPassword();
                this.ecomValidatePassword('baAppPassword');
              }
                  //and if the selected role is not a BA then the web portal password is mandatory
              if (!this.isTargetBA) {
                this._validateRequire('webPortalPassword', CONSTANTS.ERROR_REQUIRE_TEXT);
                if (this.userForm.webPortalPassword) {
                  this._confirmWebPortalPassword();
                  this.ecomValidatePassword('webPortalPassword');
                }
              }
          }
        }
      }
    }

    this._validateRequire('roleId', CONSTANTS.ERROR_REQUIRE_TEXT);
    this._validateRequire('fullName', CONSTANTS.ERROR_REQUIRE_TEXT);
    if (this.authService.isSystemAdmin) {
      this._validateRequire('location', CONSTANTS.ERROR_REQUIRE_TEXT);
    }

    if (this.areTargetBrandRoles) {
      if (this.userForm.baLoginId) {
        this._validateEmail('baLoginId', this.userForm.baLoginId);
      }
      if (this.selectedActivityGroups.length) {
        this.userForm.activityGroupIds = this.selectedActivityGroups.map((e: any) => {return e.id});
        $("div.text-error[for='activityGroupMultiSelect']").hide();
      } else {
        this.isFormValid = false;
        $("div.text-error[for='activityGroupMultiSelect']").html(CONSTANTS.ERROR_REQUIRE_TEXT).show();
      }
    }

    if (this.authService.isBM) {
      this.userForm.location = this.userForm.storeCountry;
    }

    if (!this.isFormValid) {
      return;
    } else {
      this.registerOrUpdateUser();
    }
  }

  registerOrUpdateUser() {
    this.userForm.fullName = this.utilService.properCaseOf(this.userForm.fullName);
    if (this.isEditMode) {
      this.checkHasChange();
      if (this.hasChanges) {
        this.updateUser();
      } else {
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.SUCCESS_MSG,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          }
        );
      }
   } else {
      this.isLoading = true;
      // Because the password fields will not show in the creating/editing user form so we disable the confirmation about the passwords.
      /*if(!this.userForm.baAppPassword || this.userForm.baAppPassword.length==0) {
        var text = CONSTANTS.BA_APP_PASSWORD_NOT_ADDED;
        if(confirm(text) == true) {
          this.saveCreateForm();
          this.createUser();
        }
      } else {
        this.saveCreateForm()
        this.createUser();
      }*/
    this.saveCreateForm()
    this.createUser();
   }
 }

  private _validateEmployeeIdIncludeSpaces() {
    let regex = /\s+/;
    let content = this.userForm.baLoginId.trim();
    this._clearError('baLoginId');
    if (regex.test(content) == true) {
      this.isFormValid = false;
      this._showError('baLoginId', CONSTANTS.EMPLOYEE_ID_INVALID);
    }
 }

  private _confirmBaAppPassword() {
    this._clearError('baAppPasswordConfirm');
    if (this.userForm.baAppPassword != this.baAppPasswordConfirm) {
      this.isFormValid = false;
      this._showError('baAppPasswordConfirm', CONSTANTS.PASS_NOT_MATCH);
    }
  }

  private _confirmWebPortalPassword() {
    this._clearError('webPortalPasswordConfirm');
    if (this.userForm.webPortalPassword != this.webPortalPasswordConfirm) {
      this.isFormValid = false;
      this._showError('webPortalPasswordConfirm', CONSTANTS.PASS_NOT_MATCH);
    }
  }

  createUser() {
    this.isLoading = true;
    this.userService.createUser(this.userForm).subscribe(
      res => {
        this.showSuccessPopup(CONSTANTS.USER_CREATED);
        this.router.navigate(['/management']);
        this.isLoading = false;
     },
      err => {
        if(err.status === 409){
          this.showErrorPopup(CONSTANTS.USER_EXIST);
        } else {
          this.showErrorPopup(CONSTANTS.USER_CREATE_FAIL);
        }
        this.isLoading = false;
        Bugsnag.notify(err);
     }
    );
 }

  private checkHasChange() {

    let histories: EditHistory[] = [];

    if (this.userForm.fullName !== this.currentEditUser.fullName) {
      this.saveChangeForm(histories, 'Full Name', this.currentEditUser.fullName, this.userForm.fullName, 0);
    }

    if (this.userForm.baLoginId && this.userForm.baLoginId !== this.currentEditUser.baLoginId) {
      this.saveChangeForm(histories, 'Employee ID', this.currentEditUser.baLoginId, this.userForm.baLoginId, 0);
    }

    if (this.userForm.email && this.userForm.email !== this.currentEditUser.email) {
      this.saveChangeForm(histories, 'Email',
        this.currentEditUser.email, this.userForm.email, 0);
    }


    if (!this.isTargetRetailOpsOrSA && !this.areTargetBrandRoles) {
      if (this.userForm.storeCode !== this.currentEditUser.storeCode) {

        let oldStore = this.stores.find((str: any) => str.storeCode == this.currentEditUser.storeCode);
        let oldStoreName;
        if (oldStore) {
          oldStoreName = oldStore.storeName;
        }

        let newStore = this.stores.find((str: any) => str.storeCode == this.userForm.storeCode);
        let newStoreName;
        if (newStore) {
          newStoreName = newStore.storeName;
        }

        if (oldStoreName && newStoreName) {
          this.saveChangeForm(histories, 'Store', oldStoreName, newStoreName, 0);
        }
      }

      if (this.userForm.storeEmail !== this.currentEditUser.storeEmail) {
        this.saveChangeForm(histories, 'Store Email',
          this.currentEditUser.storeEmail, this.userForm.storeEmail, 0);
     }
      if (this.userForm.storeCountry !== this.currentEditUser.storeCountry) {
        if (this.userForm.location !== '') {
          this.saveChangeForm(histories, 'Location',
            this.currentEditUser.storeCountry, this.userForm.location, 0);
       } else {
          if (this.currentEditUser.storeCountry != null) {
            this.saveChangeForm(histories, 'Location',
              this.currentEditUser.storeCountry, this.userForm.storeCountry, 0);
         } else {
            this.saveChangeForm(histories, 'Location',
              this.currentEditUser.location, this.userForm.storeCountry, 0);
         }
       }
     }
   } else {
      if (this.areTargetBrandRoles && this.currentEditUser.activityGroupIds && this.userForm.activityGroupIds.toString() !== this.currentEditUser.activityGroupIds.toString()) {
        const ov = this.activityGroupDropdownList.filter((it: any) => this.currentEditUser.activityGroupIds.includes(it.id as number));
        const nv = this.activityGroupDropdownList.filter((it: any) => this.userForm.activityGroupIds.includes(it.id as number));
        this.saveChangeForm(histories, 'Activity Group',
          `"${ov.map((e: any) => {return e.activityGroup}).toString().replaceAll(',', ', ')}"`, `"${nv.map((e: any) => {return e.activityGroup}).toString().replaceAll(',', ', ')}"`, 0);
      }

      if (this.userForm.location !== this.currentEditUser.location) {
        if (!this.areTargetBrandRoles && this.currentEditUser.location == null || this.currentEditUser.location == '') {
          this.saveChangeForm(histories, 'Location',
            this.currentEditUser.storeCountry, this.userForm.location, 0);
        } else {
            this.saveChangeForm(histories, 'Location',
              this.currentEditUser.location, this.userForm.location, 0);
        }
      }
   }

    if (this.userForm.roleId !== this.currentEditUser.roleId) {
      let oldVal = this.allowViewRoles.find((role: any) => role.id == this.currentEditUser.roleId);
      let newVal = this.allowViewRoles.find((role: any) => role.id == this.userForm.roleId);
      this.saveChangeForm(histories, 'Role', oldVal.name, newVal.name, 0);
   }
    this.userForm.editHistory = histories;
 }

  private saveCreateForm() {
    let histories: EditHistory[] = [];
    this.saveChangeForm(histories, '', '', '', 4);
    this.userForm.editHistory = histories;
 }

  private saveChangeForm(histories: any, field: string, oldVal: string, newVal: string, type: number) {
    this.hasChanges = true;
    var item: EditHistory = {
      fieldName: field,
      oldValue: oldVal,
      newValue: newVal,
      updateTimeStamp: '',
      updateUserBAId: this.authService.userId,
      updateUserFullName: this.authService.fullName,
      userId: this.userId,
      type: type
    };
    histories.push(item);
 }

  updateUser() {
    this.isLoading = true;
    this.userService.updateUser(this.userId, this.userForm).subscribe(
      res => {
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.SUCCESS_MSG,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );

        if (this.userId == this.authService.id) {
          this.authService.firstName = this.userForm.fullName.split(' ')[0];
          if (this.userForm.roleId != this.currentEditUser.roleId) {
            let newRole = this.allowViewRoles.find((r: any) => r.id === this.userForm.roleId);
            this.authService.authorities[0].authority = newRole.name;
            this.authService.isSystemAdmin = this.authService.authorities.some(it => it.authority == CODINGCONSTANTS.SA_ROLE);
            this.authService.isRetailOps = this.authService.authorities.some(it => it.authority == CODINGCONSTANTS.RO_ROLE);
            this.authService.isSM = this.authService.authorities.some(it => it.authority == CODINGCONSTANTS.SM_ROLE);
            this.authService.isSMT = this.authService.authorities.some(it => it.authority == CODINGCONSTANTS.SMT_ROLE);
         }
       }
        this.router.navigate(['/user/view', this.userId]);
        this.isLoading = false;
     },
      err => {
        if(err.status === 409){
          this.showErrorPopup(CONSTANTS.USER_EXIST);
        } else {
          this.notificationsService.error(
            CONSTANTS.ERROR,
            CONSTANTS.USER_UPDATE_FAIL,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            }
          );
        }
        this.isLoading = false;
        Bugsnag.notify(err);
     }
    );
 }

  cancelForm() {
    this.router.navigate(['/management']);
 }

  changeBaAppPassword() {
    this.changePasswordModal.show(
      CONSTANTS.BA_APP_PASS_CHANGE,
      () => {
        this.changePasswordModal.close();
     },
      CONSTANTS.CANCEL,
      () => {
        this.changePasswordModal.typeChange = 1;
        if (this.changePasswordModal.isConfirmSuccessful()) {
          let newPassword = this.changePasswordModal.newPassword;
          let fullName = this.authService.fullName;
          let baLoginId = this.authService.userId;
          let userId = this.userId;
          // change password
          let data = {
            newPassword,
            fullName,
            baLoginId,
            userId
         }
          this.authService.changeBAPassword(this.userId, data).subscribe(
            res => {
              this.changePasswordModal.close();
              this.showSuccessPopup(this.userForm.baLoginId + CONSTANTS.PASS_UPDATED_SUCCESS);
              this.checkIfEditMode();
           },
            err => {
              this.changePasswordModal.close();
              if(err.status == 403){
                this.showErrorPopup(CONSTANTS.PASS_CANNOT_BE_SAME);
             }else{
              this.showErrorPopup(CONSTANTS.CHANGE_PASS_USER + this.userId + CONSTANTS.FAIL_MSG);
             }
           Bugsnag.notify(err);
           }
          );
       }
     },
      CONSTANTS.OK
    );
 }

  changeWebPortalPassword() {
    this.changePasswordModal.show(
      CONSTANTS.PORTAL_PASS_CHANGE,
      () => {
        this.changePasswordModal.close();
     },
      CONSTANTS.CANCEL,
      () => {
        this.changePasswordModal.typeChange = 2;
        if (this.changePasswordModal.isConfirmSuccessful()) {
          let newPassword = this.changePasswordModal.newPassword;
          let fullName = this.authService.fullName;
          let baLoginId = this.authService.userId;
          let userId = this.userId;
          // change password
          let data = {
            newPassword,
            fullName,
            baLoginId,
            userId
         }
          this.authService.changeWebPortalPassword(this.userId, data).subscribe(
            res => {
              this.changePasswordModal.close();
              this.showSuccessPopup(this.userForm.baLoginId + CONSTANTS.PASS_UPDATED_SUCCESS_PORTAL);
              this.checkIfEditMode();
           },
            err => {
              this.changePasswordModal.close();
              if(err.status == 403){
                this.showErrorPopup(CONSTANTS.NEW_PASS_CANNOT_BE_SAME);
              }else{
                this.showErrorPopup(CONSTANTS.CHANGE_PASSWORD_USER + this.userId + CONSTANTS.CHANGE_PASSWORD_FAILED);
              }
              Bugsnag.notify(err);
           }
          );

       }
     },
      CONSTANTS.OK
    );
 }

  showSuccessPopup(message: string) {
    this.notificationsService.success(
      CONSTANTS.SUCCESS,
      message,
      {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
     }
    );
 }

  showErrorPopup(message: string) {
    this.notificationsService.error(
      CONSTANTS.ERROR,
      message,
      {
        timeOut: 1000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
     }
    );
 }

  /** Validate handling */

  private _validateEmail(inputName: string, value: string) {
    this._clearError(inputName);
    var re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(value) == false) {
      this.isFormValid = false;
      this._showError(inputName, CONSTANTS.INVALID_EMAIL);
      return false;
    }
    return true;
  }

  // Make sure required fields are filled out
  private _validateRequire(inputName: string, message: string) {
    this._clearError(inputName);
    let content = $(`[name='` + inputName + `']`).val();
    if (!content || !content.trim()) {
      this.isFormValid = false;
      this._showError(inputName, message);
    }
 }

  clearErrorIfValidRequire(target: any) {
    this._clearError(target.name);
    let inputName = (target as HTMLInputElement).getAttribute('ng-reflect-name') as string;
    if (inputName === 'baLoginId' && this.authService.isOAuth2Authenticate && !this.areTargetBrandRoles) {
      return; // Skip
    }
    if (inputName === 'email' && (this.areTargetBrandRoles || ((!this.authService.isOAuth2Authenticate && !this.isTargetRetailOpsOrSA)))) {
      return; // Skip
    }
    let content = target.value;
    if (content && content.trim()) {
      if (inputName === 'email' && ((this.authService.isOAuth2Authenticate && !this.areTargetBrandRoles) || (!this.authService.isOAuth2Authenticate && this.isTargetRetailOpsOrSA))) {
        if (this._validateEmail(inputName, this.userForm.email)) {
          this.isUserExistByEmail();
        }
      }
      if (inputName === 'baLoginId' && this.areTargetBrandRoles) {
        if (this._validateEmail(inputName, this.userForm.baLoginId)) {
          this.isUserExist();
        }
      }
      if (inputName === 'baLoginId' && !this.authService.isOAuth2Authenticate && !this.areTargetBrandRoles) {
        this.validateEmployeeIdOnInput();
        if (!$(`[name ='` + inputName + `']`).hasClass('input-error')) {
          this.isUserExist();
        }
      }
    } else {
      this._validateRequire(inputName, CONSTANTS.ERROR_REQUIRE_TEXT);
      return;
    }
  }

  // Show error message under input
  private _showError(inputName: string, message: string) {
    let inputElement = $(`[name ='` + inputName + `']`);
    // inputElement.focus();
    inputElement.addClass('input-error');
    $(`div.text-error[for='` + inputName + `']`)
      .html(message)
      .show();
 }

  //Clear error message under input
  private _clearError(inputName: string) {
    let inputElement = $(`[name ='` + inputName + `']`);
    inputElement.removeClass('input-error');
    $(`div.text-error[for='` + inputName + `']`).hide();
 }

  //Clear password error message under input
  private _clearErrorPw(inputName: string) {
    let inputElement = $(`[name ='` + inputName + `']`);
    inputElement.removeClass('input-error');
    $(`div.text-error-pw[for='` + inputName + `']`).hide();
 }

  showDeleteUserConfirmDialog() {
    this.messageModal.show(
      CONSTANTS.CONFIRM,
      CONSTANTS.DELETE_USER_CONFIRM,
      ICONS.CONFIRM,
      null,
      CONSTANTS.CANCEL,
      () => {
        this.messageModal.close();
        this.deleteUser();
     },
      CONSTANTS.OK
    );
 }

  ecomValidatePassword(validationSource: string) {
    const webPW = (validationSource === 'webPortalPassword');
    const password = webPW ? this.userForm.webPortalPassword : this.userForm.baAppPassword;
    const passwordConfirm = webPW ? this.webPortalPasswordConfirm : this.baAppPasswordConfirm;
    if(webPW ? this.userForm.webPortalPassword.length > 0 : this.userForm.baAppPassword.length > 0){
      this._validateRequire(validationSource, CONSTANTS.ERROR_REQUIRE_TEXT);
      this.userService.ecomValidatePassword(password, passwordConfirm).subscribe(
        res => {
          webPW ?  this.webPasswordValidationMessages = [].concat(res.body.data.attributes.password_validations) : this.appPasswordValidationMessages = [].concat(res.body.data.attributes.password_validations);
          if (this.appPasswordValidationMessages.length || this.webPasswordValidationMessages.length) {
            this.isFormValid = false;
          }
       },
        err => {
          this.showErrorPopup(CONSTANTS.ERROR_VALIDATING);
          Bugsnag.notify(err);
       }
      );
      webPW ? this.showMessages_webPortalPassword = true : this.showMessages_baAppPassword = true;
    } else {
      webPW ? this.showMessages_webPortalPassword = false : this.showMessages_baAppPassword = false;
    }
  }

  deleteUser() {
    this.isLoading = true;
    this.userService.delete(this.userId).subscribe(
      res => {
        this.showSuccessPopup(this.userForm.baLoginId + CONSTANTS.DELETE_SUCCESS);
        this.isLoading = false;
        this.router.navigate(['/user']);
     },
      err => {
        this.showErrorPopup(CONSTANTS.DELETE_USER_FAIL + this.userId + CONSTANTS.CHANGE_PASSWORD_FAILED);
        Bugsnag.notify(err);
        this.isLoading = false;
     }
    );
 }
}