<div class="layout-root">
    <div class="tab">
      <button [class.active]="tabSelector == 1" class="tablinks" (click)="showContent(1)">{{userManagement ?  ('USER_MANAGEMENT' | translate) : userManagement}}</button>
      <button [class.active]="tabSelector == 2" [hidden]="isBM || isBR" class="tablinks" (click)="showContent(2)">{{storeManagement ?  ('STORE_MANAGEMENT' | translate) :  storeManagement}}</button>
      <button [class.active]="tabSelector == 7" [hidden]="isBR" class="tablinks" (click)="showContent(7)">{{resourceManagement ? ('RESOURCE_MANAGEMENT' | translate) : resourceManagement}}</button>
      <button [class.active]="tabSelector == 8" class="tablinks" (click)="showContent(8)">{{activityBookings ? ('ACTIVITY_BOOKINGS' | translate) : activityBookings}}</button>
      <button [class.active]="tabSelector == 3" [hidden]="!isSystemAdmin && !isRetailOps" class="tablinks" (click)="showContent(3)">{{terminalManagement ? ('TERMINAL_MANAGEMENT' | translate) : terminalManagement}}</button>
      <button [class.active]="tabSelector == 5" [hidden]="!isSystemAdmin && !isRetailOps" class="tablinks" (click)="showContent(5)">{{countryManagement ? ('COUNTRY_MANAGEMENT' | translate) : countryManagement}}</button>
      <button [class.active]="tabSelector == 4" [hidden]="!isSystemAdmin" class="tablinks" (click)="showContent(4)">{{customerAudit ? ( 'CUSTOMER_AUDIT'| translate) : customerAudit}}</button>
      <button [class.active]="tabSelector == 6" [hidden]="isBM || isBR" class="tablinks" (click)="showContent(6)">{{oobaOrdersTab ? ('OOBA_ORDERS' | translate) : oobaOrdersTab}}</button>
    </div>
  
    <div id="UserManager" *ngIf="tabSelector == 1">
      <div class="table-area">
        <div class="row">
          <div class="col-sm-8">
            <button type="button" class="btn btn-dark" routerLink="/user/new">{{newUser ? ('NEW_USER' | translate) : newUser}}</button>
          </div>
          <div class="col-sm-4" style="display:inline-block">
            <div style="position:relative; width:100%">
              <div (click)="getPage(1)" class="btn-search">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <input style="width:100%" class="form-control pull-right search-bar" type="search" id="user-search" placeholder="{{search  ? ('SEARCH' | translate) : search}}"
                aria-label="Search" [(ngModel)]="keywordSearch" (keyup.enter)="getPage(1)" (keyup.backspace)="resetPage($event.target)" (input)="onInput()">
              <div class="btn-clear" *ngIf="onSearch" (click)="clear()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="table-details">{{showingUsrMsg1 ? ('SHOW_USR_MSG_1' | translate) : showingUsrMsg1}} {{beginRecord}} {{to ? ('TO' | translate) : to}} {{endRecord}} {{ofTotal ? ('OF_TOTAL' | translate) : ofTotal}} {{this.totalItems}} {{usersText ? ('USERS' | translate) : usersText}}</div>
        <div *ngIf="isLoading" class="loading-spinner">
          <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg">
        </div>
        <table class="table table-hover table-user" [class.opaque]="isLoading">
          <thead class="thead-dark">
            <tr>
              <th>{{employeeEmail ? ('OKTA ID' | translate) : employeeEmail}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter6"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="ba-login-id-filter" [(ngModel)]="filter6">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter6"></i>
              </th>

              <th>{{employeeId ? ('EMPLOYEE_ID'| translate) : employeeId}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter1"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="ba-login-id-filter" [(ngModel)]="filter1">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter1"></i>
              </th>
  
              <th>{{fullName ? ('FULL_NAME' | translate) : fullName}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter2"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter}}" aria-label="full-name-filter" [(ngModel)]="filter2"
                  tabindex="0">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter2"></i>
              </th>
  
              <th>{{storeName ? ('STORE_NAME' | translate) : storeName}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter3"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter  ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="store-name-filter" [(ngModel)]="filter3">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter3"></i>
              </th>
  
              <th>{{countryLocation ? ('COUNTRY_LOCATION' | translate) : countryLocation}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter4"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter  ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="location-filter" [(ngModel)]="filter4">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter4"></i>
              </th>
  
              <th>{{userRole ? ('USER_ROLE' | translate) : userRole}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter5"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter  ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="role-filter" [(ngModel)]="filter5">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter5"></i>
              </th>
  
              <th width="15%" colspan="2" style="text-align: center;">{{action ? ('ACTION' | translate) : action}}</th>
            </tr>
          </thead>
  
          <tbody>
            <tr (click)="selectRow(user)" *ngFor="let user of users | paginate: { itemsPerPage: limit, currentPage: currentPage , id: 'users-server', totalItems: totalItems }; let i = index">
              <td>
                {{user.email}}
              </td>
              <td>
                {{user.baLoginId}}
              </td>
              <td>
                {{utilService.properCaseOf(user.fullName)}}
              </td>
              <td>
                {{user.storeName||''}}
              </td>
              <td>
                {{user.storeCountry||''}}
              </td>
              <td>
                {{user.roleName}}
              </td>
              <td align="right">
                <button type="button" class="btn btn-view" (click) = "clickNextPage()" [routerLink]="['/user/view', user.id]">{{view ? ('VIEW' | translate) : view}}</button>
              </td>
              <td>
                <button type="button" class="btn btn-edit" (click) = "clickNextPage()" [routerLink]="['/user/edit', user.id]" [disabled]="(!authService.isSystemAdmin && authService.id != user.id && !checkAllowEdit(user.roleName))">{{edit ? ('EDIT' | translate) : edit}}</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <div class="not-found" *ngIf="!isLoading && !users?.length">{{noUserFound ? ('NO_USER_FOUND' | translate) : noUserFound}}</div>
  
        <div class="pagination" *ngIf="!isLoading && users?.length">
          <pagination-controls (pageChange)="getPage($event)" id="users-server"></pagination-controls>
        </div>
      </div>
    </div>
  
    <div id="StoreManager" *ngIf="tabSelector == 2">
      <div class="title-area" hidden="true">
      </div>
      <div class="table-area">
        <div class="row">
          <div class="col-sm-8">
            <button [hidden]="!isSystemAdmin && !isRetailOps" type="button" class="btn btn-dark" routerLink="/store/new">{{newStore ? ('NEW_STORE' | translate) : newStore}}</button>
          </div>
          <div class="col-sm-4" style="display:inline-block">
            <div style="position:relative; width:100%">
              <div (click)="getStoreWithPage(1)" class="btn-search">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <input style="width:100%" class="form-control pull-right search-bar" type="search" placeholder="{{search ? ('SEARCH' | translate) : search}}"
                aria-label="Search" [(ngModel)]="keywordSearch" (keyup.enter)="getStoreWithPage(1)" (keyup.backspace)="resetPage($event.target)" (input)="onInput()">
              <div class="btn-clear" *ngIf="onSearch" (click)="clear()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="table-details">{{showStoreMsg1  ? ('SHOW_STORE_MSG_1' | translate) : showStoreMsg1}}{{beginRecord}} {{to ? ('TO' | translate) : to}} {{endRecord}} {{ofTotal ? ('OF_TOTAL' | translate) : ofTotal}} {{this.totalItems}} {{storesText ? ('STORES' | translate) : storesText}}</div>
        <div *ngIf="isLoading" class="loading-spinner">
          <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg">
        </div>
        <table class="table table-hover table-store" [class.opaque]="isLoading">
          <thead class="thead-dark">
            <tr>
              <th>{{storeCodeText ? ('STORE_CODE' | translate) : storeCodeText}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter1"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="store-code-filter"
                  tabindex="0" [(ngModel)]="filter1">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter1"></i>
              </th>
              <th>{{storeName ? ('STORE_NAME' | translate) : storeName}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter2"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="store-name-filter" [(ngModel)]="filter2">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter2"></i>
              </th>
              <th>{{storeEmail ? ('STORE_EMAIL' | translate) : storeEmail}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter3"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="store-email-filter" [(ngModel)]="filter3">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter3"></i>
              </th>
              <th>{{locationText  ? ('LOCATION' | translate) : locationText}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter4"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="store-country-filter" [(ngModel)]="filter4">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter4"></i>
              </th>
              <th colspan="3" style="text-align: center;">{{action ? ('ACTION' | translate) : action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr (click)="selectStore(store)" *ngFor="let store of stores | paginate: { itemsPerPage: limit, currentPage: currentPage , id: 'stores-server', totalItems: totalItems }; let i = index">
              <td>
                {{store.storeCode}}
              </td>
              <td>
                {{store.storeName}}
              </td>
              <td>
                {{store.storeEmail}}
              </td>
              <td>
                {{store.storeCountry}}
              </td>
              <td align="right">
                <button type="button" class="btn btn-view" (click) = "clickNextPage()" [routerLink]="['/store/view', store.storeCode]">{{view ? ('VIEW' | translate) : view}}</button>
              </td>
              <td align="right">
                <button type="button" class="btn btn-edit" [disabled]="isBM" (click) = "clickNextPage()" [routerLink]="['/store/edit', store.storeCode]">{{edit ? ('EDIT' | translate) : edit}}</button>
              </td>
              <td align="right">
                <button type="button" [disabled]="store.numberOfUsers || store.numberOfTerminals || (!isSystemAdmin && !isRetailOps)" class="btn btn-activeate-deactivate" (click) = "deactivateStore(store.storeCode, $event)">{{store.active ? (deactivate ? ('DEACTIVATE' | translate) : deactivate) : (activate  ? ('ACTIVATE' | translate) : activate)}}</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <div class="not-found" *ngIf="!stores?.length">{{noStoreFound ? ('NO_STORE_FOUND' | translate) : noStoreFound}}</div>
  
        <div class="pagination" *ngIf="!isLoading && stores?.length">
          <pagination-controls (pageChange)="getStoreWithPage($event)" id="stores-server"></pagination-controls>
        </div>
      </div>
    </div>
  
    <div id="TerminalManager" *ngIf="tabSelector == 3">
      <div class="title-area" hidden="true">
      </div>
      <div class="table-area">
        <div class="row">
          <div class="col-sm-8">
            <button type="button" class="btn btn-dark" routerLink="/terminal/new">{{newTerminal ? ('NEW_TERMINAL' | translate) : newTerminal}}</button>
          </div>
          <div class="col-sm-4" style="display:inline-block">
            <div style="position:relative; width:100%">
              <div (click)="getTerminalsWithPage(1)" class="btn-search">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <input style="width:100%" class="form-control pull-right search-bar" type="search" placeholder="{{search ? ('SEARCH' | translate) : search}}"
                aria-label="Search" [(ngModel)]="keywordSearch" (keyup.enter)="getTerminalsWithPage(1)" (keyup.backspace)="resetPage($event.target)" (input)="onInput()">
              <div class="btn-clear" *ngIf="onSearch" (click)="clear()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="table-details">{{showTerminalMsg1 ? ('SHOW_TERMINAL_MSG_1' | translate) : showTerminalMsg1}} {{beginRecord}} {{to ? ('TO' | translate) : to}} {{beginRecord}} {{ofTotal ? ('OF_TOTAL' | translate) : ofTotal}} {{this.totalItems}} {{terminalsText ? ('TERMINALS' | translate) : terminalsText}}</div>
        <div *ngIf="isLoading" class="loading-spinner">
          <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg">
        </div>
        <table class="table table-hover table-terminal" [class.opaque]="isLoading">
          <thead class="thead-dark">
            <tr>
              <th>{{terminalId ? ('TERMINAL_ID' | translate) : terminalId}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter1"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="terminal-id-filter"
                  tabindex="0" [(ngModel)]="filter1">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter1"></i>
              </th>
              <th>{{deviceModel ? ('DEVICE_MODEL' | translate) : deviceModel}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter2"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="termail-model-filter" [(ngModel)]="filter2">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter2"></i>
              </th>
              <th>{{storeName ? ('STORE_NAME' | translate) : storeName}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter3"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="store-name-filter" [(ngModel)]="filter3">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter3"></i>
              </th>
              <th>{{state ? ('STATE' | translate) : state}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter4"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="terminal-state-filter" [(ngModel)]="filter4">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter4"></i>
              </th>
              <th colspan="2" style="text-align: center;">{{action ? ('ACTION' | translate) : action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr (click)="selectTerminal(terminal)" *ngFor="let terminal of terminals | paginate: { itemsPerPage: limit, currentPage: currentPage , id: 'terminals-server', totalItems: totalItems }; let i = index">
              <td>
                {{terminal.terminalCode}}
              </td>
              <td>
                {{terminal.model}}
              </td>
              <td>
                {{terminal.storeName}}
              </td>
              <td>
                {{terminal.state == 1 ? "Active": "Inactive"}}
              </td>
              <td align="right">
                <button type="button" class="btn btn-view" (click) = "clickNextPage()" [routerLink]="['/terminal/view', terminal.terminalId]">{{view ? ('VIEW' | translate) : view}}</button>
              </td>
              <td>
                <button type="button" class="btn btn-edit" (click) = "clickNextPage()" [routerLink]="['/terminal/edit', terminal.terminalId]">{{edit ? ('EDIT' | translate) : edit}}</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <div class="not-found" *ngIf="!isLoading && !terminals?.length">{{noTerminalFound ? ('NO_TERMINAL_FOUND' | translate) : noTerminalFound}}</div>
  
        <div class="pagination" *ngIf="!isLoading && terminals?.length">
          <pagination-controls (pageChange)="getTerminalsWithPage($event)" id="terminals-server"></pagination-controls>
        </div>
      </div>
    </div>
  
    <div id="auditManager" *ngIf="tabSelector == 4">
      <div class="table-area">
        <div class="row">
          <div class="col-sm-4" style="display:inline-block; right: 25px; left: auto; position:absolute">
            <div style="position:relative; width:100%">
              <div (click)="getPageAu(1)" class="btn-search">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <input style="width:100%" class="form-control pull-right search-bar" type="search"  placeholder="{{search ? ('SEARCH' | translate) : search}}"
                aria-label="Search" [(ngModel)]="keywordSearch" (keyup.enter)="getPageAu(1)" (keyup.backspace)="resetPage($event.target)" (input)="onInput()">
              <div class="btn-clear" *ngIf="onSearch" (click)="clear()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="table-details" style="position: absolute">{{auditShowMsg1 ? ('SHOW_AUDIT_MSG_1' | translate) : auditShowMsg1}} {{beginRecord}} {{to ? ('TO' | translate) : to}} {{endRecord}} {{ofTotal ? ('OF_TOTAL' | translate) : ofTotal}} {{this.totalItems}} {{auditText ? ('AUDITS' | translate) : auditText}}</div>
        <div *ngIf="isLoading" class="loading-spinner">
          <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg">
        </div>
        <table class="table table-hover table-customer" style="margin-top: 50px" [class.opaque]="isLoading">
          <thead class="thead-dark">
            <tr>
              <th>{{auditItemId ? ('AUDIT_ITEM_ID' | translate) : auditItemId}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter1"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" [(ngModel)]="filter1">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter1"></i>
              </th>
  
              <th>{{auditType ? ('AUDIT_TYPE' | translate) : auditType}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter8"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" [(ngModel)]="filter8">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter8"></i>
              </th>
  
              <th>{{customerBpId ? ('CUSTOMER_BP_ID' | translate) : customerBpId}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter2"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" [(ngModel)]="filter2">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter2"></i>
              </th>
  
              <th>{{customerName ? ('CUSTOMER_NAME' | translate) : customerName}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter3"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" tabindex="0" [(ngModel)]="filter3">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter3"></i>
              </th>
  
              <th>{{orderNumber ? ('ORDER_NUMBER' | translate) : orderNumber}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter9"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" [(ngModel)]="filter9">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter9"></i>
              </th>
  
              <th>{{orderId ? ('ORDER_ID' | translate) : orderId}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter10"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" [(ngModel)]="filter10">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter10"></i>
              </th>
  
              <th>{{baId ? ('BA_ID' | translate) : baId}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter4"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" [(ngModel)]="filter4">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter4"></i>
              </th>
  
              <th>{{storeId ? ('STORE_ID' | translate) : storeId}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter5"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" [(ngModel)]="filter5">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter5"></i>
              </th>
  
              <th>{{storeName ? ('STORE_NAME' | translate) : storeName}}
                <i class="fa fa-filter" aria-hidden="true" id="btn-filter6"></i>
                <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" [(ngModel)]="filter6">
                <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter6"></i>
              </th>
  
              <th>{{modifiedOn ? ('MODIFIED_ON' | translate) : modifiedOn}}
                  <i class="fa fa-filter" aria-hidden="true" id="btn-filter7"></i>
                  <input class="form-control input-filter" type="search" id="search-modified" placeholder="dd-MMM-yyyy" [(ngModel)]="filter7">
                  <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter7"></i>
              </th>
  
              <th width="7%" colspan="2" style="text-align: center;">{{action ? ('ACTION' | translate) : action}}</th>
            </tr>
          </thead>
  
          <tbody>
            <tr (click)="selectAudit(audit)" *ngFor="let audit of audits | paginate: { itemsPerPage: limit, currentPage: currentPage , id: 'autdits-server', totalItems: totalItems }; let i = index">
              <td>
                {{audit.idStr}}
              </td>
              <td>
                {{audit.type}}
              </td>
              <td>
                {{audit.cardNum}}
              </td>
              <td>
                {{audit.customerName}}
              </td>
              <td>
                {{audit.orderNum}}
              </td>
              <td>
                {{audit.orderId}}
              </td>
              <td>
                {{audit.baLoginId}}
              </td>
              <td>
                {{audit.storeCode||"-"}}
              </td>
              <td>
                {{audit.storeName||"-"}}
              </td>
              <td>
                {{audit.modifiedOn}}
              </td>
              <td align="right">
                <button type="button" style="display: block; margin: auto" class="btn btn-view" (click) = "clickNextPage()" [routerLink]="['/audit/view', audit.id]">{{view ? ('VIEW' | translate) : view}}</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <div class="not-found" *ngIf="!isLoading && !audits?.length">{{noAuditFound ? ('NO_AUDIT_FOUND' | translate) : noAuditFound}}</div>
  
        <div class="pagination" *ngIf="!isLoading && audits?.length">
          <pagination-controls (pageChange)="getPageAu($event)" id="autdits-server"></pagination-controls>
        </div>
      </div>
    </div>
  
    <div id="Currency" *ngIf="tabSelector == 5">
      <div class="table-area">
      
        <table class="table table-hover table-currency" style="margin-top: 50px" [class.opaque]="isLoadingCurrency">
          <thead class="thead-dark">
            <tr>
              <th>{{currencyName ? ('CURRENCY_NAME' | translate) : currencyName}}</th>
  
              <th>{{countryName ? ('COUNTRY_NAME' | translate) : countryName}}</th>
  
              <th>{{countryId ? ('COUNTRY_ID' | translate) : countryId}}</th>
  
              <th>{{exchangeRate ? ('EXCHANGE_RATE' | translate) : exchangeRate}}</th>
  
              <th width="7%" colspan="2" style="text-align: center;">{{action ? ('ACTION' | translate) : action}}</th>
            </tr>
          </thead>
  
          <tbody>
            <tr (click)="selectCurrency(currency)" *ngFor="let currency of currencies; let i = index">
              <td>
                {{currency.currencyName}}
              </td>
              <td>
                {{currency.countryName}}
              </td>
              <td>
                {{currency.countryId}}
              </td>
              <td>
                {{currency.exchangeRate}}
              </td>
              <td>
                <button type="button" class="btn btn-edit" (click) = "clickNextPage()" [routerLink]="['/country/edit', currency.countryId]">{{edit ? ('EDIT' | translate) : edit}}</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="isLoadingCurrency" class="loading-spinner" style="margin-top: 100px">
            <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg">
        </div>
        <div class="not-found" *ngIf="!isLoadingCurrency && !currencies?.length">{{noCurrencyFound ? ('NO_CURRENCY_FOUND' | translate) : noCurrencyFound}}</div>
  
      </div>
    </div>
  
    <div id="OOBAOrders" *ngIf="tabSelector == 6">
      <div class="title-area" hidden="true">
      </div>
      <div class="table-area">
        <div class="row">
          <div class="col-sm-2">
            <select [disabled] = "!authService.isSystemAdmin && !authService.isRetailOps" name="storeCountry" class="form-control" [(ngModel)]="storeCountry" (focusout)="clearErrorIfValidRequire($event.target)" required="required" 
            (change)="onSelectCountry($event, '', false)">
              <option class="default-option" value="">{{countryDefaultSelectOption  ? ('SELECT_COUNTRY' | translate) : countryDefaultSelectOption}}</option>
              <option *ngFor="let country of countries" [value]="country">{{country}}</option>
            </select>
            <span class="underline"></span>
            <div class="text-error" for="storeCountry"></div>
          </div>
          <div class="col-sm-2">
            <select name="storeCode" [disabled] = "!(authService.isSystemAdmin || authService.isRetailOps) || storeCountry===''" class="form-control" [(ngModel)]="storeCode" (focusout)="clearErrorIfValidRequire($event.target)" required="required"
            (change)="onSelectStore($event, '', 1, false)">
              <option class="default-option" value="">{{storeDefaultSelectOption ? ('SELECT_STORE' | translate) : storeDefaultSelectOption}}</option>
              <option *ngFor="let store of stores" [value]="store.storeCode">{{store.storeName}}</option>
            </select>
            <span class="underline"></span>
            <div class="text-error" for="storeCode"></div>
          </div>
          <div class="col-sm-2">
            <input type="date" name="from" [disabled] = "storeCode===''" class="form-control" [(ngModel)]="from" (change)="onDateChangeFrom()"/>
            <span class="underline"></span>
            <div class="text-error" for="from"></div>
          </div>
          <div class="col-sm-2">
            <input type="date" name="till" [disabled] = "storeCode==='' || from===''" class="form-control" [(ngModel)]="till" (change)="onDateChangeTill()"/>
            <span class="underline"></span>
            <div class="text-error" for="till"></div>
          </div>
  
          <div class="col-sm-4" style="display:inline-block">
            <div style="position:relative; width:100%">
              <div (click)="getPageBO(undefined, 1, true)" class="btn-search">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <input style="width:100%" class="form-control pull-right search-bar" type="search" placeholder="{{search ? ('SEARCH' | translate) : search}}"
                aria-label="Search" [(ngModel)]="keywordSearch" (keyup.enter)="getPageBO(undefined, 1, true)" (keyup.backspace)="resetPage($event.target)" (input)="onInput()">
              <div class="btn-clear" *ngIf="onSearch" (click)="clear()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div id="OOBAOrdersResult" [hidden]="!dateFilled">
          <div class="table-details">{{showOobaMsg1 ? ('SHOW_OOBA_MSG_1' | translate) : showOobaMsg1}} {{beginRecord}} {{to ? ('to' | translate) : to}} {{endRecord}} {{ofTotal ? ('OF_TOTAL' | translate) : ofTotal}} {{this.totalItems}} {{ordersText ? ('ORDERS_TEXT' | translate) : ordersText}}</div>
          <div *ngIf="isLoading" class="loading-spinner">
            <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg">
          </div>
          <table class="table table-hover table-ba" [class.opaque]="isLoading">
            <thead class="thead-dark">
              <tr>
                <th>{{baId ? ('BA_ID' | translate) : baId}}
                  <i class="fa fa-filter" aria-hidden="true" id="btn-filter1"></i>
                  <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="ba-id-filter"
                    tabindex="0" [(ngModel)]="filter1">
                  <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter1"></i>
                </th>
                <th>{{baName ? ('BA_NAME' | translate) : baName}}
                  <i class="fa fa-filter" aria-hidden="true" id="btn-filter2"></i>
                  <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="ba-name-filter" [(ngModel)]="filter2">
                  <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter2"></i>
                </th>
                <th>{{orderNumber ? ('ORDER_NUMBER' | translate) : orderNumber}}
                  <i class="fa fa-filter" aria-hidden="true" id="btn-filter3"></i>
                  <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="order-number-filter" [(ngModel)]="filter3">
                  <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter3"></i>
                </th>
                <th>{{totalAmount ? ('TOTAL_AMOUNT' | translate) : totalAmount}}
                  <i class="fa fa-filter" aria-hidden="true" id="btn-filter4"></i>
                  <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="total-amount-filter" [(ngModel)]="filter4">
                  <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter4"></i>
                </th>
                <th>{{status ? ('STATUS' | translate) : status}}
                  <i class="fa fa-filter" aria-hidden="true" id="btn-filter5"></i>
                  <input class="form-control input-filter" type="search" placeholder="{{inputFilter ? ('INPUT_FILTER' | translate) : inputFilter}}" aria-label="status-filter" [(ngModel)]="filter5">
                  <i class="fa fa-times filter-cancel-btn" aria-hidden="true" id="clearfilter5"></i>
                </th>
                <th colspan="2" style="text-align: center;">{{action ? ('ACTION' | translate) : action}}</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)="selectOrder(order)" *ngFor="let order of oobaOrders | paginate: { itemsPerPage: limit, currentPage: currentPage , id: 'ooba-orders-server', totalItems: totalItems }; let i = index">
                <td>
                  {{order.baLoginId}}
                </td>
                <td>
                  {{order.baName}}
                </td>
                <td>
                  {{order.orderNo}}
                </td>
                <td>
                  {{order.totalAmount}}
                </td>
                <td>
                  {{order.status}}
                </td>
                <td align="center">
                  <button type="button" class="btn btn-view" (click) = "clickNextPage()" [routerLink]="['/order/view', order.id]">{{view ? ('VIEW' | translate) : view}}</button>
                </td>
              </tr>
            </tbody>
          </table>
  
          <div class="not-found" *ngIf="!isLoading && !oobaOrders?.length">{{noOOBAFound ? ('NO_OOBA_FOUND' | translate) : noOOBAFound}}</div>
  
          <div class="pagination" *ngIf="!isLoading && oobaOrders?.length">
            <pagination-controls (pageChange)="getPageBO($event, 1, true)" id="ooba-orders-server"></pagination-controls>
          </div>
        </div>
      </div>
    </div>

    <div id="ResourceManagement" *ngIf="tabSelector == 7">
      <div *ngIf= 'loadResourceManagementComponent'>
        <resource-management-component></resource-management-component>
      </div>
    </div>

    <div id="Booking" *ngIf="tabSelector == 8">
      <div *ngIf= 'loadBookingComponent'>
        <booking-component></booking-component>
      </div>
    </div>
  </div>  