import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';
import buildUrl from 'build-url-ts';
import * as moment from 'moment';
import Bugsnag from '@bugsnag/js';

import {CONSTANTS} from 'src/app/common/constants';
import {AuditService} from 'src/app/services/audit.service';
import {AuthService} from 'src/app/services/auth.service';
import {UserService} from 'src/app/services/user.service';
import {StoreService} from 'src/app/services/store.service';
import {TerminalService} from 'src/app/services/terminal.service';
import {CountryService} from 'src/app/services/country.service';
import {OrderService} from 'src/app/services/ooba-order.service';
import {UtilService} from 'src/app/services/util.service';
import {User} from 'src/app/models/user';
import {Ba} from 'src/app/models/ba';
import {Store} from 'src/app/models/store';
import {Order} from 'src/app/models/order';
import {Item} from 'src/app/models/order-item';
import {Terminal} from 'src/app/models/terminal';
import {Audit} from 'src/app/models/audit-trail';
import {Currency} from 'src/app/models/currency-item';
import {OrderQuery} from 'src/app/models/order-query';
import { CODINGCONSTANTS } from 'src/app/common/coding-constants';
import { TranslateService } from '@ngx-translate/core';

// Jquery
declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  // Model
  users: User[] = [];
  stores: Store[] = [];
  allowViewRoles: any = [];
  allowEditRoles: any = [];
  terminals: Terminal[] = [];
  audits: Audit[] = [];
  currencies: Currency[] = [];
  tabSelector = 1;
  userManagement: string = CONSTANTS.USER_MANAGEMENT;
  storeManagement: string = CONSTANTS.STORE_MANAGEMENT;
  resourceManagement: string = CONSTANTS.RESOURCE_MANAGEMENT;
  activityBookings: string = CONSTANTS.ACTIVITY_BOOKINGS;
  terminalManagement: string = CONSTANTS.TERMINAL_MANAGEMENT;
  countryManagement: string = CONSTANTS.COUNTRY_MANAGEMENT;
  customerAudit: string = CONSTANTS.CUSTOMER_AUDIT;
  oobaOrdersTab: string = CONSTANTS.OOBA_ORDERS;
  view: string = CONSTANTS.VIEW;
  edit: string = CONSTANTS.EDIT;
  search: string = CONSTANTS.SEARCH;
  inputFilter: string = CONSTANTS.INPUT_FILTER;

  // user management
  limit: number = 50;
  currentPage: number = 1;
  totalItems: number = 0;
  beginRecord: number = 0;
  endRecord: number = 0;
  isLoading: boolean = false;
  selectedId: string = '';
  isUserTableSet: boolean = false;

  isStoreTableSet: boolean = false;
  isTerminalTableSet: boolean = false;
  isAuTableSet: boolean = false;
  isCurrencyTableSet: boolean = false;
  isBOTableSet: boolean = false;
  onSearch: boolean = false;
  employeeId: string = CONSTANTS.EMPLOYEE_ID;
  fullName: string = CONSTANTS.FULL_NAME;
  storeName: string = CONSTANTS.STORE_NAME;
  employeeEmail: string = CONSTANTS.EMPLOYEE_EMAIL;
  countryLocation: string = CONSTANTS.COUNTRY_LOCATION;
  userRole: string = CONSTANTS.USER_ROLE;
  showingUsrMsg1: string = CONSTANTS.SHOW_USR_MSG_1;
  to: string = CONSTANTS.TO;
  ofTotal: string = CONSTANTS.OF_TOTAL;
  usersText: string = CONSTANTS.USERS;
  newUser: string = CONSTANTS.NEW_USER;
  noUserFound: string = CONSTANTS.NO_USER_FOUND;

  // store management
  searchAllStoreToggle: boolean = true;
  authorities: any = [];
  isSystemAdmin: boolean = false;
  isRetailOps: boolean = false;
  isSM: boolean = false;
  isSMT: boolean = false;
  isBM: boolean = false;
  isBR: boolean = false;
  newStore: string = CONSTANTS.NEW_STORE;
  showStoreMsg1: string = CONSTANTS.SHOW_STORE_MSG_1;
  storesText: string = CONSTANTS.STORES;
  storeCodeText: string = CONSTANTS.STORE_CODE;
  storeEmail: string = CONSTANTS.STORE_EMAIL;
  locationText: string = CONSTANTS.LOCATION;
  deactivate: string = CONSTANTS.DEACTIVATE;
  activate: string = CONSTANTS.ACTIVATE;
  noStoreFound: string = CONSTANTS.NO_STORE_FOUND;

  // terminal management
  newTerminal: string = CONSTANTS.NEW_TERMINAL;
  showTerminalMsg1: string = CONSTANTS.SHOW_TERMINAL_MSG_1;
  terminalsText: string = CONSTANTS.TERMINALS;
  terminalId: string = CONSTANTS.TERMINAL_ID;
  deviceModel: string = CONSTANTS.DEVICE_MODEL;
  state: string = CONSTANTS.STATE;
  noTerminalFound: string = CONSTANTS.NO_TERMINAL_FOUND;

  // currency page
  isLoadingCurrency: boolean = false;
  dateFilled: boolean = false;
  isRefreshing: boolean = false;
  currencyName: string = CONSTANTS.CURRENCY_NAME;
  countryName: string = CONSTANTS.COUNTRY_NAME;
  countryId: string = CONSTANTS.COUNTRY_ID;
  exchangeRate: string = CONSTANTS.EXCHANGE_RATE;
  action: string = CONSTANTS.ACTION;
  noCurrencyFound: string = CONSTANTS.NO_CURRENCY_FOUND;


  // audit trail management
  keywordSearch = '';
  auditShowMsg1: string = CONSTANTS.SHOW_AUDIT_MSG_1;
  auditText: string = CONSTANTS.AUDITS;
  auditItemId: string = CONSTANTS.AUDIT_ITEM_ID;
  auditType: string = CONSTANTS.AUDIT_TYPE;
  customerBpId: string = CONSTANTS.CUSTOMER_BP_ID;
  customerName: string = CONSTANTS.CUSTOMER_NAME;
  orderNumber: string = CONSTANTS.ORDER_NUMBER;
  orderId: string = CONSTANTS.ORDER_ID;
  baId: string = CONSTANTS.BA_ID;
  storeId: string = CONSTANTS.STORE_ID;
  modifiedOn: string = CONSTANTS.MODIFIED_ON;
  noAuditFound: string = CONSTANTS.NO_AUDIT_FOUND;

  // ooba orders
  countries: string[] = []; 
  storeCountry: string = '';
  countryDefaultSelectOption: string = CONSTANTS.SELECT_COUNTRY;
  storeCode: string = '';
  storeDefaultSelectOption: string = CONSTANTS.SELECT_STORE;
  oobaAllOrders: Order[] = [];
  oobaFilteredOrders: Order[] = [];
  oobaOrders: Order[] = [];
  bas: Ba[] = [];
  orderQuery: OrderQuery = {bas: [], ecomUrl: ''};
  from: string = '';
  till: string = '';
  typeBackCus: number = 0;
  showOobaMsg1: string = CONSTANTS.SHOW_OOBA_MSG_1;
  ordersText: string = CONSTANTS.ORDERS_TEXT;
  baName: string = CONSTANTS.BA_NAME;
  totalAmount: string = CONSTANTS.TOTAL_AMOUNT;
  status: string = CONSTANTS.STATUS;
  noOOBAFound: string = CONSTANTS.NO_OOBA_FOUND;

  // filter data
  filter1: string = '';
  filter2: string = '';
  filter3: string = '';
  filter4: string = '';
  filter5: string = '';
  filter6: string = '';
  filter7: string = '';
  filter8: string = '';
  filter9: string = '';
  filter10: string = '';

  // Resource Tab
  loadResourceManagementComponent = false;
  // Activity Bookings Tab
  loadBookingComponent = false;

  // OKTA
  oAuth2SignedIn: boolean = false;

  constructor(
      private title: Title,
      private location: Location,
      private router: Router,
      private route: ActivatedRoute,
      private auditService: AuditService,
      private userService: UserService,
      private storeService: StoreService,
      private terminalService: TerminalService,
      private countryService: CountryService,
      private orderService: OrderService,
      private notificationsService: NotificationsService,
      protected authService: AuthService,
      protected utilService: UtilService,
      public translate: TranslateService
      ) {
      this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
      // Set default language
      this.translate.setDefaultLang('en-SG');
    }

  ngOnInit() {
      this.title.setTitle(CONSTANTS.SEPHORA_WEB_PORTAL);
      this.route.queryParams.subscribe(params => {
          this.oAuth2SignedIn = params['oAuth2SignedIn'];
     });
      if (this.oAuth2SignedIn){
          this.oAuth2Signin();
     } else {
          this.setupProperties();
     }
 }

  oAuth2Signin() {
      this.isLoading = true;
      this.authService.oAuth2Login().subscribe(
        res => {
            this.setupProperties();
            this.isLoading = false;
        },
        err => {
            this.notificationsService.error(
            "Error!",
            err.status === 500 ? err.error : err.status === 403 ? err.error.message : err.statusText,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
           }
        );
        this.isLoading = false;
        Bugsnag.notify(err);
        this.router.navigate(["/login"]);
       }
      );
  }

  setupProperties() {
      this.countries = this.authService.countries;
      this.isSystemAdmin = this.authService.isSystemAdmin;
      this.isRetailOps = this.authService.isRetailOps;
      this.isSM = this.authService.isSM;
      this.isSMT = this.authService.isSMT;
      this.isBM = this.authService.isBM;
      this.isBR = this.authService.isBR;

      try {
          this.authorities = [].concat(this.authService.authorities as any);
     } catch (err: any) {
          this.authorities = [{'authority' : null}];
          this.router.navigate(['/login']);
     }

      if (localStorage.getItem('allowViewRoles') && localStorage.getItem('allowEditRoles')) {
        this.allowViewRoles = JSON.parse(localStorage.getItem('allowViewRoles') as any);
        this.allowEditRoles = JSON.parse(localStorage.getItem('allowEditRoles') as any);
     } else {
        this.fetchRoles();
     }

      this.checkQueryString();
 }


  fetchRoles() {
    this.isLoading = true;
    this.userService.getRoles().subscribe(res => {    
        localStorage.setItem('allRoles', JSON.stringify(res));
        let allRoles = JSON.parse(JSON.stringify(res)); 
        const roleId = allRoles.find((it: any) => it.name == this.authorities[0].authority).id;
        localStorage.setItem('currentUserRoleId', roleId as string);
        this.allowViewRoles = allRoles.filter((it: any) => it.id as number >= Number(roleId));
        if (this.authService.isSystemAdmin) {
            this.allowEditRoles = this.allowViewRoles;
        } else {
            this.allowEditRoles = allRoles.filter((it: any) => it.id as number > Number(roleId));
            if (this.authService.isSM || this.authService.isSMT) {
                this.allowViewRoles = this.allowViewRoles.filter((it: any) => ![CODINGCONSTANTS.BM_ROLE, CODINGCONSTANTS.BR_ROLE].includes(it.name as string));
                this.allowEditRoles = this.allowEditRoles.filter((it: any) => ![CODINGCONSTANTS.BM_ROLE, CODINGCONSTANTS.BR_ROLE].includes(it.name as string));
            }
        }
        localStorage.setItem('allowViewRoles', JSON.stringify(this.allowViewRoles));
        localStorage.setItem('allowEditRoles', JSON.stringify(this.allowEditRoles));
        this.isLoading = false;
     },
      err => {
        this.isLoading = false;
        Bugsnag.notify(err);
   });
 }

  checkQueryString() {
      this.route.queryParams.subscribe(params => {
          if (params['t'] === 'user') this.showContent(1);
          else if (params['t'] === 'store') this.showContent(2);
          else if (params['t'] === 'terminal') this.showContent(3);
          else if (params['t'] === 'customer') this.showContent(4);
          else if (params['t'] === 'country') this.showContent(5);
          else if (params['t'] === 'oobaorder') this.showContent(6);
          else if (params['t'] === 'resource') this.showContent(7);
          else if (params['t'] === 'activity') this.showContent(8);
          else this.showContent(1);
     });
 }

  setUpFilter() {
    let that = this;
    let allHeaders = $('.table th');
    let allFilterPopups = $('.table .input-filter');
    let allFilterCancelButtons = $('.filter-cancel-btn');
    for (let i = 0; i < allHeaders.length; i++) {
        allHeaders.eq(i).css('z-index', allHeaders.length - i);
   }
    $('.table').on('click', '.fa-filter', (event: any) => {
        event.stopPropagation();
        var element = $(event.currentTarget);
        if ($(element).hasClass('active')) {
            $(element).next().toggle();
            setTimeout(() => {
                $(element).next().focus();
           }, 1);
            return;
       }
        setTimeout(() => {
            $(element).next().focus();
       }, 1);
        allFilterPopups.hide();
        $(element).addClass('active');
        $(element).next().show();
   });

    allFilterPopups.keypress((event: any) => {
        if (event.keyCode == 13) {
            var element = $(event.currentTarget);
            $(element).hide();
            if (that.isUserTableSet) that.getPage(1);
            else if (that.isStoreTableSet) that.getStoreWithPage(1);
            else if (that.isTerminalTableSet) that.getTerminalsWithPage(1);
            else if (that.isAuTableSet) that.getPageAu(1);
            else if (that.isBOTableSet) that.getPageBO(undefined, 1, true);
       }
   });

    allFilterCancelButtons.click((event: any) => {
        var element = $(event.currentTarget);
        $(element).prevAll('.input-filter').hide();
        $(element).prevAll('.fa-filter').removeClass('active');
        switch ($(element).attr('id')) {
            case 'clearfilter1':
                that.filter1 = '';
                break;
            case 'clearfilter2':
                that.filter2 = '';
                break;
            case 'clearfilter3':
                that.filter3 = '';
                break;
            case 'clearfilter4':
                that.filter4 = '';
                break;
            case 'clearfilter5':
                that.filter5 = '';
                break;
            case 'clearfilter6':
                that.filter6 = '';
                break;
            case 'clearfilter7':
                that.filter7 = '';
                break;
            case 'clearfilter8':
                that.filter8 = '';
                break;
            case 'clearfilter9':
                that.filter9 = '';
                break;
            case 'clearfilter10':
                that.filter10 = '';
                break;
            default:
                break;
       }

        if (that.isUserTableSet) that.getPage(1);
        else if (that.isStoreTableSet) that.getStoreWithPage(1);
        else if (that.isTerminalTableSet) that.getTerminalsWithPage(1);
        else if (that.isAuTableSet) that.getPageAu(1);
        else if (that.isBOTableSet) that.getPageBO(undefined, 1, true);
   });

    $('.table').on('click', '.input-filter', function (event: any) {
        event.stopPropagation();
   });
    $(window).click(function () {
        allFilterPopups.hide();
   });
    this.refreshStatusFilter();
}

  resetPage(target: any) {
      this.onSearch = false;
      if (this.tabSelector == 1)
          this.getPage(1);
      else if (this.tabSelector == 2) {
          this.getStoreWithPage(1);
     } else if (this.tabSelector == 3) {
          this.getTerminalsWithPage(1);
     } else if (this.tabSelector == 4) {
          this.getPageAu(1);
     } else if (this.tabSelector == 5) {
          this.getCurrency();
     } else if (this.tabSelector == 6) {
          this.getPageBO(undefined, 1, true);
     }
 }

  getPage(page: number) {
      let location = this.authService.storeCountry;
      if (location == null || location === '') {
          location = this.authService.location;
     }
      this.isLoading = true;
      let typeBackCus = Number(localStorage.getItem('typeBackCus'));
      if (typeBackCus == 1) {
          this.filter1 = localStorage.getItem('filter1') as string;
          this.filter2 = localStorage.getItem('filter2') as string;
          this.filter3 = localStorage.getItem('filter3') as string;
          this.filter4 = localStorage.getItem('filter4') as string;
          this.filter5 = localStorage.getItem('filter5') as string;
          this.filter5 = localStorage.getItem('filter6') as string;
          this.keywordSearch = localStorage.getItem('keywordSearch') as string;
          if (this.keywordSearch != '') {
              this.onSearch = true;
         }
          page = Number(localStorage.getItem('pageNum'));
     }
      localStorage.removeItem('typeBackCus');

      this.userService.filterUsers(page, this.limit, this.authService.isSystemAdmin, location, this.getCurrentStore(), this.keywordSearch.trim() ? this.keywordSearch.trim() : this.keywordSearch, this.filter2, this.filter1, this.filter3, this.filter4, this.filter5, this.filter6).subscribe(
          res => {
              this.users = res.items;
              this.totalItems = res.total;
              this.currentPage = page;
              this.beginRecord = (page - 1) * this.limit + 1;
              if (res.total == 0) this.beginRecord = 0;
              this.endRecord = this.beginRecord + this.limit - 1;
              if (this.endRecord > this.totalItems) this.endRecord = this.totalItems;
              this.isLoading = false;
              if (!this.isUserTableSet) {
                  this.isUserTableSet = true;
                  this.setUpFilter();
             }
         },
          err => {
              Bugsnag.notify(err);
              this.router.navigate(['/login']);
         }
      );
 }

  getPageAu(page: number) {
      if(!(this.authService.isSystemAdmin || this.authService.isRetailOps)) return;
      var date = new Date();
      var timezoneOffset = date.getTimezoneOffset();
      this.isLoading = true;
      var dateFilter = new Date(this.filter7);
      if(this.validateDateTime(dateFilter)) {
          this.notificationsService.success(
                          CONSTANTS.DATE_FORMAT_INVALID,
                          CONSTANTS.INPUT_DATE,
                          {
                              timeOut: 2000,
                              showProgressBar: true,
                              pauseOnHover: true,
                              clickToClose: true
                         }
                      );
          this.filter7 = '';
          $('#btn-filter7').removeClass('active');
          return;
     } else if (this.filter7 != '') {
          this.filter7 = dateFilter.getFullYear() + '/' + (dateFilter.getMonth()+1) + '/' + dateFilter.getDate();
     } else {
          this.filter7 = '';
     }
      let typeBackCus = Number(localStorage.getItem('typeBackCus'));
      if (typeBackCus == 1) {
          this.filter1 = localStorage.getItem('filter1') as string;
          this.filter2 = localStorage.getItem('filter2') as string;
          this.filter3 = localStorage.getItem('filter3') as string;
          this.filter4 = localStorage.getItem('filter4') as string;
          this.filter5 = localStorage.getItem('filter5') as string;
          this.filter6 = localStorage.getItem('filter6') as string;
          this.filter7 = localStorage.getItem('filter7') as string;
          this.filter8 = localStorage.getItem('filter8') as string;
          this.filter9 = localStorage.getItem('filter9') as string;
          this.filter10 = localStorage.getItem('filter10') as string;
          this.keywordSearch = localStorage.getItem('keywordSearch') as string;
          if (this.keywordSearch != '') {
              this.onSearch = true;
         }
          page = Number(localStorage.getItem('pageNum'));
          this.limit = Number(localStorage.getItem('limitNum'));
     }
      localStorage.removeItem('typeBackCus');
      this.isLoading = true;
      this.auditService.filterAuditTrail(this.authService.isRetailOps ? this.authService.location : '', page, this.limit, this.keywordSearch.trim() ? this.keywordSearch.trim() : this.keywordSearch, this.filter3, this.filter2, this.filter4,
      this.filter5, this.filter6, this.filter7, this.filter8, this.filter9, this.filter10, this.filter1, timezoneOffset).subscribe(res => {
          this.audits = res.items;
          this.totalItems = res.total;
          for (let i = 0; i < this.audits.length; i++) {
              this.audits[i].modifiedOn = this.utilService.formatDate(this.audits[i].modifiedOn);
              this.audits[i].idStr = ('000000000'+ Number(this.audits[i].id)).slice(-10);
              if (!this.audits[i].type) {
                  this.audits[i].type = CODINGCONSTANTS.CUSTOMER_INFO_SIGNATURE;
             }
         }
          this.currentPage = page;
          this.beginRecord = (page - 1) * this.limit + 1;
          if (res.total == 0) this.beginRecord = 0;
          this.endRecord = this.beginRecord + this.limit - 1;
          if (this.endRecord > this.totalItems) this.endRecord = this.totalItems;
          this.isLoading = false;
          if (!this.isAuTableSet) {
              this.isAuTableSet = true;
              this.setUpFilter();
         }
     })
 }

  getCurrency() {
      if(this.authService.isSystemAdmin || this.authService.isRetailOps) {
          this.isLoading = true;
          this.isLoadingCurrency = true;
          this.countryService.getCountries().subscribe(res => {
              this.currencies = res.items;
              this.isLoadingCurrency = false;
              this.isLoading = false;
              if (!this.isCurrencyTableSet) {
                  this.isCurrencyTableSet = true;
             }
         })
     }
 }

  validateDateTime(dateFilter: any) {
      return this.filter7 != '' && (dateFilter.getFullYear().toFixed().localeCompare(NaN+'') == 0 || 
      dateFilter.getDate().toFixed().localeCompare(NaN+'') == 0 || 
      dateFilter.getMonth().toFixed().localeCompare(NaN+'') == 0 ||
      dateFilter.getFullYear() > 2100 || 
      dateFilter.getFullYear() < 2000)
 }

  getCurrentStore(): string {
    if (!this.authorities || !this.authorities.length || !this.authorities[0]) {
        this.router.navigate(['/login']);
        return '';
    } else {
      switch (this.authorities[0].authority) {
          case CODINGCONSTANTS.SM_ROLE: case CODINGCONSTANTS.SMT_ROLE:
              return this.authService.storeName;
          default:
              return '';
     }
    }
 }

  checkAllowEdit(role: string) {
    const currentRole = this.allowEditRoles.find((it: any) => it.name === role);
    return !!currentRole;
 }

  selectRow(user: any) {
      this.selectedId = user.id;
 }

  //Store management
  showContent(index: number) {
      this.resetModel(index);
      if (index == 1) {
        this.location.go('management', 't=user');
        this.getPage(1);
     } else if (index == 2) {
        this.location.go('management', 't=store');
        this.getStoreWithPage(1);
     } else if (index == 3 && (this.authService.isSystemAdmin || this.authService.isRetailOps)) {
        this.location.go('management', 't=terminal');
        this.getTerminalsWithPage(1);
     } else if (index == 4) {
        this.location.go('management', 't=customer');
        this.getPageAu(1);
     } else if (index == 5) {
        this.location.go('management', 't=country');
        this.getCurrency();
     } else if (index == 6) {
        this.location.go('management', 't=oobaorder');
        this.getPageBO(undefined, 1, false);
    } else if (index == 7) {
        this.loadResourceManagementComponent = true;
        this.location.go('management', 't=resource');
    } else if (index == 8) {
        this.loadBookingComponent = true;
        this.location.go('management', 't=activity');
    }
 }

  resetModel(index: number) {
      this.isUserTableSet = false;
      this.isStoreTableSet = false;
      this.isTerminalTableSet = false;
      this.isAuTableSet = false;
      this.isCurrencyTableSet = false;
      this.isBOTableSet = false;
      this.keywordSearch = '';
      this.onSearch = false;
      this.tabSelector = index;
      this.filter1 = '';
      this.filter2 = '';
      this.filter3 = '';
      this.filter4 = '';
      this.filter5 = '';
      this.filter6 = '';
      this.filter7 = '';
      this.filter8 = '';
      this.filter9 = '';
      this.filter10 = '';
      this.loadResourceManagementComponent = false;
      this.loadBookingComponent = false;
 }

  clearErrorIfValidRequire(target: any) {
      let content = target.value;
      if (content && content.trim()) {
        this._clearError(target.name);
     }
 }

  private _clearError(inputName: string) {
      let inputElement = $("[name ='" + inputName + "']");
      inputElement.removeClass('input-error');
      $("div.text-error[for='" + inputName + "']").hide();
 }

  private _showError(inputName: string, message: string) {
      let inputElement = $("[name ='" + inputName + "']");
      inputElement.addClass('input-error');
      $("div.text-error[for='" + inputName + "']")
        .html(message)
        .show();
   }

  refreshStatusFilter() {
      if(this.filter1 != '') $('#btn-filter1').addClass('active');
      if(this.filter2 != '') $('#btn-filter2').addClass('active');
      if(this.filter3 != '') $('#btn-filter3').addClass('active');
      if(this.filter4 != '') $('#btn-filter4').addClass('active');
      if(this.filter5 != '') $('#btn-filter5').addClass('active');
      if(this.filter6 != '') $('#btn-filter6').addClass('active');
      if(this.filter7 != '') $('#btn-filter7').addClass('active');
      if(this.filter8 != '') $('#btn-filter8').addClass('active');
      if(this.filter9 != '') $('#btn-filter9').addClass('active');
      if(this.filter10 != '') $('#btn-filter10').addClass('active');
 }

  deactivateStore(storeCode: string, event: any) {
      let status = 0;
      if (event.target.innerHTML === 'Activate') {
          status = 1;
     }
      this.storeService.setStoreStatus(storeCode, status).subscribe(
          res => {
              this.notificationsService.success(
                  CONSTANTS.SUCCESS,
                  event.target.innerHTML +  ' store ' + storeCode + ' successful',
                  {
                    timeOut: 5000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                 }
                );
              if (status === 1) {
                  event.target.innerHTML = 'Deactivate';
             } else {
                  event.target.innerHTML = 'Activate';
             }
              this.isLoading = false;
         },
          err => {
              this.notificationsService.error(
                  CONSTANTS.ERROR,
                  "Failed to '" + event.target.innerHTML + "' store: " + storeCode,
                  {
                      timeOut: 5000,
                      showProgressBar: true,
                      pauseOnHover: true,
                      clickToClose: true
                 }
                  );
              this.isLoading = false;
              Bugsnag.notify(err);
         }
        );
 }

  selectStore(store: any) {
      this.selectedId = store.storeCode;
 }

  selectTerminal(terminal: any) {
      this.selectedId = terminal.terminalId;
 }

  selectAudit(audit: any) {
      this.selectedId = audit.id;
 }

  selectCurrency(country: any) {
      this.selectedId = country.countryId;
 }

  clickNextPage() {
      localStorage.setItem('typeBackCus', '1');
 }

  onInput() {
      this.onSearch = this.keywordSearch != ''
 }

  clear() {
      this.keywordSearch = '';
      this.resetPage({});
      this.onSearch = false;
 }

  getStoreWithPage(page: number) {
      this.isLoading = true;
      let typeBackCus = Number(localStorage.getItem('typeBackCus'));
      if (typeBackCus == 1) {
          this.filter1 = localStorage.getItem('filter1') as string;
          this.filter2 = localStorage.getItem('filter2') as string;
          this.filter3 = localStorage.getItem('filter3') as string;
          this.filter4 = localStorage.getItem('filter4') as string;
          this.keywordSearch = localStorage.getItem('keywordSearch') as string;
          if (this.keywordSearch != '') {
              this.onSearch = true;
         }
          page = Number(localStorage.getItem('pageNum'));
          this.limit = Number(localStorage.getItem('limitNum'));
     }
      localStorage.removeItem('typeBackCus');
      this.isLoading = true;
      this.storeService.filterStores(page, this.limit, this.keywordSearch.trim() ? this.keywordSearch.trim() : this.keywordSearch, this.filter1, this.filter2, this.filter3, this.filter4).subscribe(res => {
          this.stores = res.items;
          this.totalItems = res.total;
          this.currentPage = page;
          this.beginRecord = (page - 1) * this.limit + 1;
          if (res.total == 0) this.beginRecord = 0;
          this.endRecord = this.beginRecord + this.limit - 1;
          if (this.endRecord > this.totalItems) this.endRecord = this.totalItems;
          this.isLoading = false;
          if (!this.isStoreTableSet) {
              this.isStoreTableSet = true;
              this.setUpFilter();
         }
     },
     err => {
         Bugsnag.notify(err);
    });
 }

  getTerminalsWithPage(page: number) {
      this.isLoading = true;
      let typeBackCus = Number(localStorage.getItem('typeBackCus'));
      if (typeBackCus == 1) {
          this.filter1 = localStorage.getItem('filter1') as string;
          this.filter2 = localStorage.getItem('filter2') as string;
          this.filter3 = localStorage.getItem('filter3') as string;
          this.filter4 = localStorage.getItem('filter4') as string;
          this.keywordSearch = localStorage.getItem('keywordSearch') as string;
          if (this.keywordSearch != '') {
              this.onSearch = true;
         }
          page = Number(localStorage.getItem('pageNum'));
          this.limit = Number(localStorage.getItem('limitNum'));
     }
      localStorage.removeItem('typeBackCus');
      this.isLoading = true;
      this.terminalService.filterTerminals(page, this.limit, this.keywordSearch.trim() ? this.keywordSearch.trim() : this.keywordSearch, this.filter1, this.filter2, this.filter3, this.filter4).subscribe(res => {
          this.terminals = res.items;
          this.totalItems = res.total;
          this.currentPage = page;
          this.beginRecord = (page - 1) * this.limit + 1;
          if (res.total == 0) this.beginRecord = 0;
          this.endRecord = this.beginRecord + this.limit - 1;
          if (this.endRecord > this.totalItems) this.endRecord = this.totalItems;           
          this.isLoading = false;
          if (!this.isTerminalTableSet) {
              this.isTerminalTableSet = true;
              this.setUpFilter()
         }
     })
 }

  getPageBO(event: any, nextPage: number, noRecalAPI: boolean) {
      this.isLoading = true;
      this.storeCountry = this.authService.storeCountry;
      this.storeCode = this.authService.storeCode;
      let page = event? Number((event.target as HTMLInputElement).value) : nextPage;
      this.typeBackCus = Number(localStorage.getItem('typeBackCus'));
      if (this.typeBackCus == 1) {
          this.storeCountry = localStorage.getItem('storeCountry') as string;
          this.storeCode = localStorage.getItem('storeCode') as string;
          this.from = localStorage.getItem('from') as any;
          this.till = localStorage.getItem('till') as any;
          page = Number(localStorage.getItem('currentPage'));
          this.keywordSearch = localStorage.getItem('keywordSearch') as string;
          if (this.keywordSearch != '') {
              this.onSearch = true;
         }
          this.filter1 = localStorage.getItem('filter1') as string;
          this.filter2 = localStorage.getItem('filter2') as string;
          this.filter3 = localStorage.getItem('filter3') as string;
          this.filter4 = localStorage.getItem('filter4') as string;
          this.filter5 = localStorage.getItem('filter5') as string;
     }
      localStorage.removeItem('typeBackCus');
      localStorage.removeItem('storeCountry');
      localStorage.removeItem('storeCode');
      localStorage.removeItem('from');
      localStorage.removeItem('till');
      localStorage.removeItem('currentPage');
      localStorage.removeItem('keywordSearch');
      localStorage.removeItem('filter1');
      localStorage.removeItem('filter2');
      localStorage.removeItem('filter3');
      localStorage.removeItem('filter4');
      localStorage.removeItem('filter5');

      this.totalItems = 0;
      this.beginRecord = 0;
      this.endRecord = 0;

      if (this.typeBackCus != 1) {
          if (this.authService.isSM || this.authService.isSMT) {
              this.storeCountry = this.authService.storeCountry;
              this.storeCode = this.authService.storeCode;
         }
     }

      if (this.from === '' && this.till === '') {
          this.dateFilled = false;
     } else {
          this.dateFilled = true;
     }

      if (this.storeCountry) {
          this.onSelectCountry(undefined, this.storeCountry, noRecalAPI);
     }
      if (this.storeCode) {
          this.onSelectStore(undefined, this.storeCode, page, noRecalAPI);
     } else {
          this.clearPagination();

          this.isLoading = false;
     }
 }

  clearPagination() {
      this.oobaOrders = [];
      this.totalItems = 0;
      this.currentPage = 0;
      this.beginRecord = 0;
      this.endRecord = 0;
 }

  onSelectCountry(event: any, sc: string, noRecalAPI: boolean) {
    const storeCountry = event ? (event.target as HTMLInputElement).value : sc;
    if (storeCountry) {
        if (storeCountry !== localStorage.getItem('storeCountry')) {
            if (this.typeBackCus == 1 || noRecalAPI) {} else {
                if (!this.authService.isSM && !this.authService.isSMT) {
                    this.storeCode = '';
               }
           }
       }
        localStorage.setItem('storeCountry', storeCountry);
        if (this.typeBackCus == 1 || noRecalAPI) {
            this.stores = JSON.parse(localStorage.getItem('stores') as any);
            this.typeBackCus = 0;
       } else {
            if (storeCountry !== localStorage.getItem('storeCountry')) {
                if (!this.authService.isSM && !this.authService.isSMT) {
                    this.storeCode = '';
               }
           }
            this.storeCountry = storeCountry;
            this.clearPagination();
            this.storeService.getStoresByCountry(storeCountry).subscribe(res => {
                this.stores = res;
                localStorage.setItem('stores', JSON.stringify(this.stores));
           });
       }
        this._clearError('storeCountry');
   }else {
        this.storeCode = '';
        this._showError('storeCountry', CONSTANTS.ERROR_REQUIRE_SELECT_DROPDOWN);
   }
 }

  onSelectStore(event: any, sc: string, page: number, noRecalAPI: boolean) {
    const storeCode = event ? (event.target as HTMLInputElement).value : sc;
    if (storeCode) {
        localStorage.setItem('storeCode', storeCode);
        if (this.dateFilled) {
            localStorage.setItem('from', this.from);
            localStorage.setItem('till', this.till);
            if (noRecalAPI) {
                this.filterOrders();
                this.setupPage(page);
                this.isLoading = false;
           } else {
                this.storeCode = storeCode;
                this.oobaAllOrders = [];
                this.oobaFilteredOrders = [];
                this.oobaOrders = [];

                this.isLoading = true;
                this.orderService.queryOrders(storeCode).subscribe(qos => {
                    if (qos['bas'].length > 0) {
                        this.orderService.getEcomToken(qos.ecomUrl, qos['bas'][0]).subscribe(ecomToken => {
                            let ecomOrderUrl = this.buildEcomOrderUrl(qos.ecomUrl, storeCode, 1);
                            this.orderService.getOrders(ecomOrderUrl ? ecomOrderUrl : '', this.storeCountry, ecomToken).subscribe(ecomOrder => {
                                let tp = ecomOrder['meta']['total-pages'];
                                this.processEcomOrder(qos['bas'], ecomOrder, page, 1, tp);
                                for (let j = 2; j <= tp; j++) {
                                    let ecomOrderUrl2 = this.buildEcomOrderUrl(qos.ecomUrl, storeCode, j);
                                    this.orderService.getOrders(ecomOrderUrl2 ? ecomOrderUrl2 : '', this.storeCountry, ecomToken).subscribe(ecomOrder2 => {
                                        this.processEcomOrder(qos['bas'], ecomOrder2, page, j, tp);
                                        if (j === tp) {
                                            this.isLoading = false;
                                            this.endCallOrderServices();
                                       }
                                   }
                                    , err => {
                                        this.isLoading = false;
                                        this.notificationsService.error(
                                            CONSTANTS.ERROR,
                                            err.message,
                                            {
                                              timeOut: 5000,
                                              showProgressBar: true,
                                              pauseOnHover: true,
                                              clickToClose: true
                                           }
                                          );
                                          Bugsnag.notify(err);
                                   });
                               }

                                if (tp <= 1) {
                                    this.isLoading = false;
                                    this.endCallOrderServices();
                               }
                           }
                            , err => {
                                this.isLoading = false;
                                this.notificationsService.error(
                                    CONSTANTS.ERROR,
                                    err.message,
                                    {
                                      timeOut: 5000,
                                      showProgressBar: true,
                                      pauseOnHover: true,
                                      clickToClose: true
                                   }
                                  );
                                  Bugsnag.notify(err);
                           });
                       }, err => {
                            this.isLoading = false;
                            this.notificationsService.error(
                                CONSTANTS.ERROR,
                                err.message,
                                {
                                  timeOut: 5000,
                                  showProgressBar: true,
                                  pauseOnHover: true,
                                  clickToClose: true
                               }
                              );
                            Bugsnag.notify(err);
                       });
                   } else {
                        this.setupPage(page);
                        this.isLoading = false;
                   }
               }
                , err => {
                    this.isLoading = false;
                    this.notificationsService.error(
                        CONSTANTS.ERROR,
                        err.message,
                        {
                          timeOut: 5000,
                          showProgressBar: true,
                          pauseOnHover: true,
                          clickToClose: true
                       }
                      );
                    Bugsnag.notify(err);
               });
           }
            this._clearError('storeCode');
       }
   } else {
        this._showError('storeCode', CONSTANTS.ERROR_REQUIRE_SELECT_DROPDOWN);
   }
 }

  buildEcomOrderUrl(ecomUrl: string, storeCode: string, p: number) {
      return buildUrl(ecomUrl + '/api/v2.5/ba/orders' , {
          queryParams: {
              store_code: storeCode,
              from: moment(Date.parse(this.from)).format('DD/MM/YYYY') + ' 00:00:00',
              till: moment(Date.parse(this.till)).format('DD/MM/YYYY') + ' 23:59:59',
              include: 'line_items',
              'page[number]': p
         }
         });
 }

  processEcomOrder(bas: any, ecomOrder: any, page: number, cp: number, tp: number) {
      let os = ecomOrder['data'];
      if (os.length === 0 && cp >= tp) {
          this.filterOrders();
          this.setupPage(page);
     } else {
          for (let i = 0, len = os.length; i < len; i++) {
              let is : Array<Item> = Array();
              os[i]['relationships']['line-items']['data'].forEach((ej: any) => {
                  ecomOrder['included'].forEach((ei: any) => {
                      if (ej['id'] ===  ei['id']) {
                          let it : Item = {
                              id : ei['id'],
                              brand : ei['attributes']['brand-name'],
                              name : ei['attributes']['product-name'],
                              icon : ei['attributes']['variant-icon'],
                              qty : ei['attributes']['quantity'],
                              price : ei['attributes']['total']
                         };
                          is.push(it);
                     }
  
                 });
             });
              let addr = (os[i]['attributes']['address']['address-1'] != null ? os[i]['attributes']['address']['address-1'] + '\n' : '') 
                              + (os[i]['attributes']['address']['address-2'] != null ? os[i]['attributes']['address']['address-2'] + '\n' : '')
                              + (os[i]['attributes']['address']['city'] != null ? os[i]['attributes']['address']['city'] + '\n' : '')
                              + (os[i]['attributes']['address']['postal-code'] != null ? os[i]['attributes']['address']['postal-code'] : '');
              let baId = os[i]['attributes']['ba-id'];
              let ba = bas.find((it: any) => it.id == baId);
              let loginId = ba ? ba.baLoginId : '';
              let name = ba ? ba.baName : '';
              let o : Order = {
                  id : Number(os[i]['id']),
                  baId : os[i]['attributes']['ba-id'],
                  baLoginId :loginId,
                  baName : name,
                  items : is,
                  orderNo : os[i]['attributes']['order-number'],
                  totalAmount : os[i]['attributes']['cost-summary']['total'],
                  status : os[i]['attributes']['state']['text'],
                  subtotal : os[i]['attributes']['cost-summary']['subtotal'],
                  preGst : os[i]['attributes']['cost-summary']['total-pre-gst'],
                  gstPercent : os[i]['attributes']['cost-summary']['gst-percent'],
                  shippingCost : os[i]['attributes']['cost-summary']['shipping-cost'],
                  pointsEarned : os[i]['attributes']['cost-summary']['points-earned'],
                  deliveryDate : os[i]['attributes']['delivery-date'],
                  gstRegNo : os[i]['attributes']['tax-registration-number'],
                  createdAt : os[i]['attributes']['created-at'],
                  paymentMethod : os[i]['attributes']['payment-method']['name'],
                  address : addr
             };
              this.oobaAllOrders.push(o);
              if (i == len - 1 && cp == tp) {
                  localStorage.setItem('oobaAllOrders', JSON.stringify(this.oobaAllOrders));
                  this.filterOrders();
                  this.setupPage(page);
             }
           }
     }
 }

  filterOrders() {
      localStorage.setItem('keywordSearch', this.keywordSearch + '');
      localStorage.setItem('filter1', this.filter1 + '');
      localStorage.setItem('filter2', this.filter2 + '');
      localStorage.setItem('filter3', this.filter3 + '');
      localStorage.setItem('filter4', this.filter4 + '');
      localStorage.setItem('filter5', this.filter5 + '');
      let filter = (this.keywordSearch !== '' || this.filter1 !== '' || this.filter2 !== '' || this.filter3 !== '' || this.filter4 !== '' || this.filter5 !== '');
      if(!this.oobaAllOrders) {
          this.oobaAllOrders = JSON.parse(localStorage.getItem('oobaAllOrders') as any);
     }
      this.oobaFilteredOrders = filter ? this.oobaAllOrders.filter(
          o => (o.baLoginId.toString().toUpperCase().indexOf(this.keywordSearch.toUpperCase()) >= 0 ||
          o.baName.toUpperCase().indexOf(this.keywordSearch.toUpperCase()) >= 0 ||
          o.orderNo.toUpperCase().indexOf(this.keywordSearch.toUpperCase()) >= 0 ||
          o.totalAmount.toUpperCase().indexOf(this.keywordSearch.toUpperCase()) >= 0 ||
          o.status.toUpperCase().indexOf(this.keywordSearch.toUpperCase()) >= 0
          ) 
          && 
          (o.baLoginId.toString().toUpperCase().indexOf(this.filter1.toUpperCase()) >= 0 && 
          o.baName.toUpperCase().indexOf(this.filter2.toUpperCase()) >= 0 &&
          o.orderNo.toUpperCase().indexOf(this.filter3.toUpperCase()) >= 0 &&
          o.totalAmount.toUpperCase().indexOf(this.filter4.toUpperCase()) >= 0 &&
          o.status.toUpperCase().indexOf(this.filter5.toUpperCase()) >= 0 
          )) : this.oobaAllOrders;
          localStorage.setItem('oobaFilteredOrders', JSON.stringify(this.oobaFilteredOrders));
 }

  onDateChangeFrom() {
      if (this.from && new Date(this.from)) {
          if (this.till) {
              if (new Date(this.till)) {
                  this.dateFilled = this.validateDateRange(new Date(this.from), new Date(this.till));
             }
         }
     } else {
          this._showError('from', CONSTANTS.ERROR_REQUIRE_TEXT);
     }

      this.onSelectStore(undefined, this.storeCode, 1, false);
 }

  onDateChangeTill() {
      if (this.till && new Date(this.till)) {
          if (this.from) {
              if (new Date(this.from)) {
                  this.dateFilled = this.validateDateRange(new Date(this.from), new Date(this.till));
             }
         }
     } else {
          this._showError('till', CONSTANTS.ERROR_REQUIRE_TEXT);
     }

      this.onSelectStore(undefined, this.storeCode, 1, false);
 }

  validateDateRange(from: any, till: any) {
      if (from.getTime() > till.getTime()) {
          this._showError('till', CONSTANTS.ERROR_DATE_RANGE);
     } else if (moment(Date.parse(this.till)).diff(moment(Date.parse(this.from)), 'days') > 31) {
          this._showError('till', CONSTANTS.ERROR_DATE_RANGE_MORE_THAN_31);
     } else {
          this._clearError('till');
          return true;
     }
  return false;
 }

  selectOrder(order: any) {
      this.selectedId = order.id;
 }

  setupPage(page: number){
      this.totalItems = this.oobaFilteredOrders.length;
      this.oobaOrders = this.oobaFilteredOrders.slice((page - 1) * this.limit, (page * this.limit) > this.totalItems ? this.totalItems : (page * this.limit));
      this.currentPage = page;
      this.beginRecord = (page - 1) * this.limit + 1;
      if (this.oobaOrders.length == 0) this.beginRecord = 0;
      this.endRecord = this.beginRecord + this.limit - 1;
      if (this.endRecord > this.totalItems) this.endRecord = this.totalItems;

      localStorage.setItem('oobaOrders', JSON.stringify(this.oobaOrders));
      localStorage.setItem('currentPage', this.currentPage + '');
      localStorage.setItem('beginRecord', this.beginRecord + '');
      localStorage.setItem('endRecord', this.endRecord + '');
 }

  endCallOrderServices() {
      if (!this.isBOTableSet) {
          this.isBOTableSet = true;
          this.setUpFilter();
     }
 }

}