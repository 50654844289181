<div class="user-wrapper">
    <div>
      <div class="table-title">
        {{editCurrency ? ('EDIT_CURRENCY'| translate) : editCurrency}}
      </div>
    </div>
    <div *ngIf="isLoading || isSendingForm" class="loading-spinner">
      <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg" />
    </div>
    <div *ngIf="!isLoading" class="sephora-container">
      <form class="form-horizontal">
  
        <div class="form-group">
          <div class="row">
            <label for="countryId" class="control-label col-md-2">{{countryId  ? ('COUNTRY_ID'| translate) : countryId}}</label>
            <div class="col-md-6">
              <input type="text" id="countryId" name="countryId" [disabled]="true" class="form-control" [(ngModel)]="currency.countryId">
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="countryName" class="control-label col-md-2">{{countryName ? ('COUNTRY_NAME'| translate) : countryName}}</label>
            <div class="col-md-6">
              <input type="text" id="countryName" name="countryName" [disabled]="false" class="form-control" [(ngModel)]="currency.countryName" (focusout)="validateCountryName()">
            </div>
            <span class="underline"></span>
            <div class="text-error" for="countryName"></div>
          </div>
        </div>
  
        <div class="form-group">
          <div class="row">
            <label for="currencyName" class="control-label col-md-2">{{currencyName ? ('CURRENCY_NAME'| translate) : currencyName}}</label>
            <div class="col-md-6">
              <input type="text" id="currencyName" name="currencyName" [disabled]="false" class="form-control" [(ngModel)]="currency.currencyName" (focusout)="validateCurrencyName()">
            </div>
            <span class="underline"></span>
            <div class="text-error" for="currencyName"></div>
          </div>
        </div>
  
        <div class="form-group">
          <div class="row">
            <label for="exchangeRate" class="control-label col-md-2">{{exchangeRate ? ('EXCHANGE_RATE'| translate) : exchangeRate}}</label>
            <div class="col-md-6">
              <input [disabled]="false" id="exchangeRate" name="exchangeRate" type="text" class="form-control" [(ngModel)]="currency.exchangeRate" (focusout)="validateExchangeRate()">
            </div>
            <span class="underline"></span>
            <div class="text-error" for="exchangeRate"></div>
          </div>
          <div class="row">
            <label class="control-label col-md-2"></label>
            <label class="col-md-6"><i><small><font color="red">{{currencyNote ? ('CURRENCY_NOTE'| translate) : currencyNote}}</font></small></i></label>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="lms" class="control-label col-md-2">{{lms ? ('LMS'| translate) : lms}}</label>
            <div class="col-md-6">
              <input type="url" id="lms" name="lms" class="form-control" placeholder="{{enterValidURL ? ('ENTER_VALID_URL'| translate) : enterValidURL}}" [(ngModel)]="currency.lms" (focusout)="validateUrl()">
            </div>
            <div class="col-md-1">
              <a href="{{currency.lms}}"  target="_blank" name="openLink" class="button-center-botton disabled">{{open ? ('OPEN'| translate) : open}}</a>
            </div>
            <div class="col-md-1">
              <a href="javascript: void(0)" name="clearLms" class="link-left-bottom disabled" (click)="clearLms()">{{remove ? ('REMOVE'| translate) : remove}}</a>
            </div>
          </div>
          <div class="row">
            <label class="control-label col-md-2"></label>
            <div class="col-md-6">
              <span class="underline"></span>
              <div class="text-error" for="lms"></div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="somFile" class="control-label col-md-2">{{som ? ('SOM'| translate) : som}}</label>
            <div class="col-md-1">
              <input type="file" #fileControl id="somFile" name="somFile" (change)="fileChange($event)" style="display:none;" accept=".pdf">
              <button type="button" class="btn btn-dark" (click)="uploadSOM()">{{chooseFile ? ('CHOOSE_FILE'| translate) : chooseFile}}</button>
            </div>
            <span class="underline"></span>
            <div class="text-error" for="somFile"></div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="somFileName" class="control-label col-md-2"></label>
            <div class="col-md-6">
              <input type="text" id="somFileName" name="somFileName" [disabled]="true" class="form-control" [(ngModel)]="currency.somFileName">
            </div>
            <div class="col-md-1">
              <a href="{{currency.som}}"  target="_blank" name="download" class="button-center-botton disabled">{{open ? ('OPEN'| translate) : open}}</a>
            </div>
            <div class="col-md-1">
              <a href="javascript: void(0)" name="clearSom" class="link-left-bottom disabled" (click)="clearSomFile()">{{remove ? ('REMOVE'| translate) : remove}}</a>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="control-label col-md-2">{{actionBox ? ('ACTION_BOX'| translate) : actionBox}}</label>
            <div class="col-md-1">
              <input type="file" #actionBoxFileControl id="actionBoxFile" name="actionBoxFile" (change)="actionBoxfileChange($event)" style="display:none;" accept=".pdf">
              <button type="button" class="btn btn-dark" (click)="uploadActionBox()">{{chooseFile  ? ('CHOOSE_FILE'| translate) : chooseFile}}</button>
            </div>
            <span class="underline"></span>
            <div class="text-error" for="actionBoxFile"></div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label for="actionBoxFileName" class="control-label col-md-2"></label>
            <div class="col-md-6">
              <input type="text" id="actionBoxFileName" name="actionBoxFileName" [disabled]="true" class="form-control" [(ngModel)]="currency.actionBoxFileName">
            </div>
            <div class="col-md-1">
              <a href="{{currency.actionBox}}"  target="_blank" name="actionBoxDownload" class="button-center-botton disabled">{{open ? ('OPEN'| translate) : open}}</a>
            </div>
            <div class="col-md-1">
              <a href="javascript: void(0)" name="clearActionBox" class="link-left-bottom disabled" (click)="clearActionBoxFile()">{{remove ? ('REMOVE'| translate) : remove}}</a>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="control-label col-md-2">{{salesDashboard ? ('SALES_DASHBOARD'| translate) : salesDashboard}}</label>
            <div class="col-md-6">
              <form>
                <input type="radio" class="col-md-1" name="salesDashboard"  (click)="radioServiceSelection(1)" [checked]=currency.salesDashboard value="1" >{{enable ? ('ENABLE'| translate) : enable}}<br>
                <input type="radio" class="col-md-1" name="salesDashboard"  (click)="radioServiceSelection(0)" [checked]=!currency.salesDashboard value="0" >{{disable ? ('DISABLE'| translate) : disable}}<br>
              </form>
            </div>
          </div>
        </div>

        <div class="form-close">
          <div class="col-md-8">
            <div class="pull-right">
              <button type="submit" class="btn btn-danger" [disabled]="!isFormValid" (click)="submitForm()">{{save ? ('SAVE'| translate) : save}}</button>
              <button type="button" class="btn btn-dark" (click)="cancelForm()">{{back ? ('BACK'| translate) : back}}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  