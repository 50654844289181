<div class="modal fade" id="expiredPasswordModal" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" style="position: absolute; top: 50%;left: 50%;transform: translate(-50%,-50%);">
        <div class="modal-content">
            <div class="modal-body message-body" style="padding: 20px 20px 0px 20px;">
                <div style="text-align: center; font-size: 20px" class="message-header"></div>
                <div style="text-align: center; margin-top: 40px" class="message-content"></div>
            </div>
            <div class="modal-footer">
                <button id="modalOKButton" type="button" class="btn btn-danger btn-oke">{{ok ?  ('OK' | translate) : ok}}</button>
            </div>
        </div>
    </div>
</div>